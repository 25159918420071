import { createContext, useState } from "react";

// This context is to allow quick and easy access to information like isUserPaidSubscriber across the app,
// which is being used for putting features behind a paywall and more.
export const CurrentMetadataForUser = createContext();

const initialState = {
    isUserPaidSubscriber: false,
    isOnboardingCompleted: false,
    stripeCustomerId: undefined
};

export const CurrentMetadataForUserProvider = ({ children }) => {
    const [currentMetadataForUser, updateUserMetadata] = useState(initialState);

    const setCurrentMetadataForUser = (updatedValue) => {
        updateUserMetadata((prev) => ({
            ...prev,
            ...updatedValue,
        }));
    };

    return (
        <CurrentMetadataForUser.Provider
            value={{ ...currentMetadataForUser, setCurrentMetadataForUser }}
        >
            {children}
        </CurrentMetadataForUser.Provider>
    );
};

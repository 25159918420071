import React, { useEffect, useState } from 'react';
import { getQuestionByCategory, getDateTimeFromId,
  getFlowentryCardSummary,
  calculateEntryColor} from '../../helpers/EntriesHelper';
import AnswerTags from './AnswerTags';
import AnswerBlurb from './AnswerBlurb';
import {LOC_Options_IsWorkPlayingARole,
  LOC_Options_HowIsWork,
  LOC_Options_WhatIsAffectingYou,
  AnswerFormat } from '../../Constants';
import '../css/flowentry_detail.css';

function FlowentryDetailView(props) {
  const [flowEntryId, setFlowEntryId] = useState();
  const [flowEntry, setFlowEntry] = useState();
  const [flowEntryState, setFlowEntryState] = useState();
  const [flowEntryColors, setFlowEntryColors] = useState();
  const [displayInfo, setDisplayInfo] = useState();
  const [flowColor, setFlowColor] = useState();
  const [questionTextColor, setQuestionTextColor] = useState();


	useEffect(() => {
		if (props.flowEntry) {
			setFlowEntry(props.flowEntry);
			setFlowEntryId(props.flowEntry._id);
			let flowEntryState = getFlowentryCardSummary(props.flowEntry);
			setFlowEntryState(flowEntryState);
		}
	}, [props.flowEntry])

	useEffect(() => {
		if (flowEntryState) {
			let flowEntryColors = calculateEntryColor(flowEntryState);
			setFlowEntryColors(flowEntryColors);

			let displayInfo = {
				date: getDateTimeFromId(flowEntryId),
				feeling: flowEntryState.feeling ?? null,
				energy: flowEntryState.energy ?? null,
				cause: getCauseLocation(flowEntry) ?? null
			};
	
			let answeredQuestions = flowEntry.answeredQuestions.map(obj => ({ ...obj }));
			displayInfo.questions = getQuestionsForDisplay(displayInfo, answeredQuestions);
	
			setDisplayInfo(displayInfo);
		}
	}, [flowEntryState])

	useEffect(() => {
		if (flowEntryColors) {
			setFlowColor({
				background: flowEntryColors.BG
			})
			let questionTextColor = flowEntryColors.text;
			if (props.colorScheme === 'DARK') {
				questionTextColor = flowEntryColors.lightText ?? questionTextColor;
			}
			setQuestionTextColor({
				color: questionTextColor
			})
		}
	}, [flowEntryColors])

  const getCauseLocation = (entry) => {
    const question = getQuestionByCategory(entry, "location_of_cause");
    if (!question || !question.answer) {
      return null;
    }
    let heading = "";
    if (LOC_Options_IsWorkPlayingARole.includes(question.answer)) {
      heading = "Is work playing a role?";
    }
    else if (LOC_Options_WhatIsAffectingYou.includes(question.answer)) {
      heading = "What's affecting you?";
      if (question.answer.toLowerCase().includes('both') || question.answer.toLowerCase().includes('Work and beyond')) {
        question.answer = "Work and beyond"
      }
    }
    else if (LOC_Options_HowIsWork.includes(question.answer)){
      heading = "How's work?";
    }
    else {
      console.error(`Not sure what heading to use for ${question.answer}, ${question.question}, entry : ${entry._id}`)
    }

    return {
      "heading": heading,
      "answer": question.answer
    };
  }

  const getQuestionsForDisplay = (entry, questions) => {
    if (entry.feeling) {
      // remove this from the list as it will already be displayed in the top summary section
      questions.shift();
    }
    if (entry.energy) {
      questions.shift();
    }
    if (entry.cause) {
      questions.shift();
    }
    return questions;
  }

  const getDetailDivStyle = (extraStyle) => {
	if (!extraStyle || extraStyle === undefined) {
		return flowColor;
	}
	else {
		return extraStyle;
	}
  }

  return (
	<div>
		{ displayInfo && flowEntryColors &&
			<div className='entry-detail-div' style={getDetailDivStyle(props.style)}>
				<div className='entry-datetime'>
				<h3>{displayInfo.date}</h3>
				</div>
				<div className='entry-summary'>
				{ 
					displayInfo.feeling &&
					<div className='entry-summary-subdiv'>
					<h4 className='question-heading' style={questionTextColor}>Feeling</h4>
					<AnswerTags answer={displayInfo.feeling} colors={flowEntryColors} colorScheme={props.colorScheme}/>
					</div>
				}
				{ 
					displayInfo.energy &&
					<div className='entry-summary-subdiv'>
					<h4 className='question-heading' style={questionTextColor}>Energy</h4>
					<AnswerTags answer={displayInfo.energy} colors={flowEntryColors} colorScheme={props.colorScheme}/>
					</div>
				}
				{
					displayInfo.cause &&
					<div className='entry-summary-subdiv'>
					<h4 className='question-heading' style={questionTextColor}>{displayInfo.cause.heading}</h4>
					<AnswerTags answer={displayInfo.cause.answer} colors={flowEntryColors} colorScheme={props.colorScheme}/>
					</div>
				}
				</div>
				{
				displayInfo.questions.map((question) =>
					<div className='question-section' key={question.stepId}>
					<h4 className='question-heading' style={questionTextColor}>{question.question}</h4>
					{
						(question.answerFormat && (question.answerFormat === AnswerFormat.Textarea || question.answerFormat === AnswerFormat.TextInputDetails))
						? <AnswerBlurb answer={question.answer} colorScheme={props.colorScheme} colors={flowEntryColors}/> 
						: <AnswerTags answer={question.answer} colors={flowEntryColors}
							colorScheme={props.colorScheme} highlightTag={props.highlightTag}/>
					}
					</div>
				)}
			</div>
		}
	</div>
  )
}

export default FlowentryDetailView;
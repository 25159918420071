import { useContext } from "react";
import { getGreetingColors } from "../Colors";
import { AccountDropdownContext } from "./AccountCircle";

export const AppBackground = ({ timeOfDay, children }) => {
    const { showDropdown, setShowDropdown } = useContext(AccountDropdownContext);

    return (
        <div
            className="App"
            style={getGreetingColors(timeOfDay)}
            onClick={() => {
                if (showDropdown) {
                    setShowDropdown(false);
                }
            }}
        >
            {children}
        </div>
    )
}
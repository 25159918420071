import React, { useState } from 'react';
import './css/radiobuttongroup.css';

function RadioButtonGroup(props) {

    const onClick = (e) => {
        props.handleChange(e.target.id);
    }

    const getElementStyle = (value, i) => {
        let style = { backgroundColor: "transparent" } //default;

        // set border width & radius
        const middleElementBorderWidth = '0.1rem 0.05rem 0.1rem 0.05rem';
        const firstElementBorderWidth = '0.1rem 0.05rem 0.1rem 0.1rem';
        const lastElementBorderWidth = '0.1rem 0.1rem 0.1rem 0.05rem';

        if (i === 0) {
            style['borderWidth'] = firstElementBorderWidth;
            style['borderRadius'] = '1rem 0rem 0rem 1rem';
        }
        else if (i === props.values.length - 1) {
            style['borderWidth'] = lastElementBorderWidth;
            style['borderRadius'] = '0rem 1rem 1rem 0rem';
        }
        else {
            style['borderWidth'] = middleElementBorderWidth
        }

        // set bg color based if button is checked
        let checkedStyle = {};
        if (value === props.selection) {
            checkedStyle = { backgroundColor: "rgb(73, 197, 210)" }
        }

        return props.customStyles ? { ...style, ...props.customStyles, ...checkedStyle }
            : { ...style, ...checkedStyle };
    }

    return (
        <div className='radio-btn-group'>
            {props.values.map((value, i) =>
                <label key={value} style={{ cursor: "pointer" }}>
                    <div className='radio-group-btn' style={getElementStyle(value, i)}>
                        <input type="radio"
                            id={value}
                            name="options"
                            onChange={onClick}
                            checked={value === props.selection} />
                        {value}
                    </div>
                </label>
            )}
        </div>
    )
}

export default RadioButtonGroup;
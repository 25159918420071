import React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../css/donut-chart.css';
import { BigPictureCutoffs } from '../../Constants';

// This is being used to show big picture counter
function DonutChart(props) {
    const svgRef = useRef();
    const [data, setData] = useState(null);

    useEffect(() => {
        if(props.numFlowsRemaining === ' ') {
            return;
        }
        const numFlowsRemaining = props.numFlowsRemaining;
        const flowsDone = BigPictureCutoffs.Month - numFlowsRemaining;
        const data = [(flowsDone/BigPictureCutoffs.Month) * 100, 100 * numFlowsRemaining/BigPictureCutoffs.Month]
        setData(data);
        
    }, [props.numFlowsRemaining])

    useEffect(() => {
        if (!data) {
            return;
        }

        // Step 1: Set up svg container
        const width = 800;
        const height = 800;
        const radius = Math.min(80 * window.innerWidth, 80 * window.innerHeight, 400);
        const svg = d3.select(svgRef.current)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .style('overflow', 'visible')

        const svgWidth = svg.node().getBoundingClientRect().width;

        // Step 2: Set up the charts       
        const color = ["white", "none"]; // green "#34bf7c"

        const pie = d3.pie()
            .sort(null) // to disable drawing from largest to smallest arc and stick to the order provided in data
            .value(d => d);

        const arc = d3.arc()
            .innerRadius(radius - 40)
            .outerRadius(radius)
            .cornerRadius(4);

        const path = svg.selectAll('path')
            .data(pie(data))
            .enter()
            .append('path')
            .attr('d', arc)
            .attr("stroke", (d, i) => i === 1 && props.numFlowsRemaining > 0 ? "white" : "none") // Apply stroke only to the second pie slice
            .attr("stroke-width", 5)
            .attr("stroke-dasharray", "20,20")
            .style('fill', (d,i) => props.numFlowsRemaining > 0 ? color[i] : "#ecca79");

        path.transition()
            .duration(2000)
            // .delay((d, i) => i === 1 ? 50 : 0)
            .attrTween('d', d => {
                const interpolate = d3.interpolate(d.startAngle, d.endAngle);
                return t => {
                    d.endAngle = interpolate(t);
                    return arc(d);
                };
            });

    }, [data]);

    return (
        <div className='donutDiv'>
            <svg ref={svgRef} />
        </div>
    )
}

export default DonutChart;
import styles from "./css/list-with-icon.module.css"

export const ListWithIcon = (
    {
        iconPath,
        iconAltText,
        list,
        externalCSS = {
            listContainer: {},
            listItem: {},
            icon: {},
            text: {},
        },
        moreSpacing = false,
    }
) => {
    return (
        <div className={styles.listContainer} style={externalCSS.listContainer}>
            {
                list.map((listItem, index) => {
                    return (
                        <div
                            className={moreSpacing ? styles.listItemMoreSpacing : styles.listItem}
                            key={index}
                            style={externalCSS.listItem}
                        >
                            {iconPath ?
                                <img src={`./${iconPath}`}
                                    height={20}
                                    width={20}
                                    alt={iconAltText}
                                    className={styles.icon}
                                    style={externalCSS.icon}></img>
                                : <p style={{ margin: 0 }}>{iconAltText}</p>
                            }
                            <p
                                className={styles.text}
                                style={externalCSS.text}
                            >
                                {listItem}
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

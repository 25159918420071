import React from 'react';
import { CalendarViewTypes } from '../../Constants';

function CheckinsHeading(props) {
    const today = new Date();

    const getTimeTense = (date) => {
        let endDate;
        if (props.view === CalendarViewTypes.Week.Name) {
            endDate = new Date(props.startDate.getFullYear(), props.startDate.getMonth(), props.startDate.getDate() + 5);
        }
        else if (props.view === CalendarViewTypes.Month.Name) {
            endDate = new Date(props.startDate.getFullYear(), props.startDate.getMonth() + 1, 0);
        }
        else {
            endDate = new Date(props.startDate.getFullYear() + 1, 0, 1, 0, 0, 0); // Jan 1 midnight of next year
        }

        if (props.startDate <= date && date < endDate) {
            return 'present';
        }
        if (props.startDate > date) {
            return 'future';
        }
        else {
            return 'past';
        }
    }

    const getCheckinsHeadingForWeekView = () => {
        let tense = getTimeTense(today);

        // note: we can't just do a !props.numFlows here, because it'll return true for 0 as well
        if (props.numFlows === undefined || props.numFlows === null) {
            return `...`
        }

        if (props.numFlows > 2) {
            if (tense === 'past') {
                return `I have ${props.numFlows} flows this week!`
            }
            else {
                return `I have ${props.numFlows} flows this week!`
            }
        }
        else if (props.numFlows === 2) {
            if (tense === 'past') {
                return `I have 2 flows this week.`
            }
            else {
                return `I have 2 flows this week.`
            }
        }
        else if (props.numFlows === 1) {
            if (tense === 'past') {
                return `I have 1 flow this week.`
            }
            else {
                return `I have 1 flow this week.`
            }
        }
        else {
            if (tense === 'past'){
                return `Welp no flows here.`;
            }
            else if (tense === 'future') {
                return `This is the future!`;
            }
            else {
                return `No flows yet this week.`
            }
        }
    }

    const getCheckinsHeadingForMonthView = () => {
        let tense = getTimeTense(today);

        if (props.numFlows === undefined || props.numFlows === null) {
            return `...`
        }
        else {
            let checkinWord = props.numFlows === 1 ? 'flow' : 'flows';
            let message = tense === 'past'
                ? `I have ${props.numFlows} ${checkinWord} this month`
                : `I have ${props.numFlows} ${checkinWord} this month so far`
            message = props.numFlows > 10 ? `${message}!` : `${message}.`;
            return message;
        }
    }

    const getCheckinsHeadingForYearView = () => {
        let tense = getTimeTense(today);

        if (props.numFlows === undefined || props.numFlows === null) {
            return `...`
        }
        else {
            let checkinWord = props.numFlows === 1 ? 'flow' : 'flows';
            let message = tense === 'past'
                ? `I have ${props.numFlows} ${checkinWord} this year.`
                : `I have ${props.numFlows} ${checkinWord} this year so far.`
            return message;
        }
    }

    const getHeadingByViewType = () => {
        if (props.view === CalendarViewTypes.Week.Name) {
            return getCheckinsHeadingForWeekView();
        }
        else if (props.view === CalendarViewTypes.Month.Name) {
            return getCheckinsHeadingForMonthView();
        }
        else if (props.view === CalendarViewTypes.Year.Name) {
            return getCheckinsHeadingForYearView();
        }
        else {
            return <></>;
        }
    }

    let heading = getHeadingByViewType();
    return (
        <div className='week-entries-heading' style={props.style ?? {}}>
            {heading}
        </div>
    )
}

export default CheckinsHeading;
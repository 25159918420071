import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
    getAuth,
    verifyPasswordResetCode,
    confirmPasswordReset,
} from "firebase/auth";
import { useEffect, useState } from "react";
import styles from "../css/auth/forgot-password-reset.module.css";
import { FloatingLabelInput } from "./FloatingLabelInput";
import { STRONG_PASSWORD_REGEX } from "../../Constants";

export const ResetPassword = () => {
    const auth = getAuth();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const actionCode = params.get("oobCode");
    // An oobCode is a term that is used in some web development contexts, such as Firebase Authentication or OAuth.
    // It stands for out of band code, and it is a one-time code that is used to identify and verify a request
    // https://firebase.google.com/docs/auth/custom-email-handler#create_the_email_action_handler_page

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [error, setError] = useState(false);
    const [resultText, setResultText] = useState("");

    useEffect(() => {
        if (actionCode === null) {
            setError(true);
            setResultText("Password reset error.");
        }
        else {
            verifyPasswordResetCode(auth, actionCode).catch(() => {
                setError(true);
                setResultText("The link in your email has expired.");
            });
        }
    }, []);

    const handlePasswordReset = (e) => {
        e.preventDefault();

        if (!STRONG_PASSWORD_REGEX.test(password)) {
            setPasswordError(true);
            return;
        }

        verifyPasswordResetCode(auth, actionCode)
            .then(() => {
                confirmPasswordReset(auth, actionCode, password)
                    .then(() => {
                        setResultText("Your password has been changed.");
                    })
                    .catch(() => {
                        setError(true);
                        setResultText("Password Reset Failed");
                    });
            })
            .catch(() => {
                setError(true);
                setResultText("The link in your email has expired.");
            });
    };

    return (
        resultText ? (
            <div className={styles.formContainer}>
                <h1 className={styles.title}>
                    {error ? "Reset Password" : "Success!"}
                </h1>

                <p className={styles.primaryText}>{resultText}</p>

                {error ? (
                    <Link className={styles.button} to="/forgot-password">
                        Send a new link
                    </Link>
                ) : (
                    <Link to="/" className={styles.button}>
                        Sign In
                    </Link>
                )}
            </div>
        ) : (
            <form
                onSubmit={(e) => {
                    handlePasswordReset(e);
                }}
                className={styles.formContainer}
            >
                <h1 className={styles.title}>Reset Password</h1>
                <div>
                    <FloatingLabelInput
                        id="reset-password-input"
                        type="password"
                        value={password}
                        required={true}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                    />
                    <p className={styles.passwordGuidelinesText}>
                        8 characters with upper & lowercase letters and numbers
                    </p>
                    {passwordError ? (
                        <p className={styles.errorText}>
                            Not a strong enough password
                        </p>
                    ) : null}
                </div>
                <input
                    type="submit"
                    value="Reset"
                    onSubmit={(e) => {
                        handlePasswordReset(e);
                    }}
                    className={styles.button}
                />
            </form>
        )
    );
};
import { AnswerFormat } from "../Constants";

export class FlowManager {
    
    //#region : Initialization
    constructor(component) {
        this.component = component;
        this.flowsteps = this.component.props.flowsteps;
        // Flows always start with the energy question and there is only 1 of that (as of 7/24)
        this.currentStep = this.flowsteps.find(step => step.category === "energy");
        this.answeredQuestions = {[this.currentStep._id] : []};
    }

    getInitialState = () => {
        const question = this.getCustomizedQuestion(this.currentStep);
        this.displayOrder = [question];
        return {
            visibleQuestion : question,
            answeredQuestions : this.answeredQuestions,
        }
    }
    //#endregion

    //#region : Answer related operations
    updateAnswer = (value) => {
        const key = this.component.state.visibleQuestion.StepId;
        this.answeredQuestions[key] = [value]; // initializing a new array with one value
        this.saveAnswersToState();
    }

    appendToAnswer = (value) => {
        const key = this.component.state.visibleQuestion.StepId;
        let currentValues = this.answeredQuestions[key] ?? [];
        if (!currentValues.includes(value)) {
            currentValues.push(value);
        }
        this.answeredQuestions[key] = currentValues;
        this.saveAnswersToState();
    }

    removeFromAnswer = (value) => {
        const key = this.component.state.visibleQuestion.StepId;
        let currentValues = this.answeredQuestions[key] ?? [];
        const location = currentValues.indexOf(value); //ok to use indexOf here because value is of primitive type string
        currentValues.splice(location, 1);
        this.answeredQuestions[key] = currentValues;
        this.saveAnswersToState();
    }

    appendToCheckboxSelections = (value) => {
        const key = this.component.state.visibleQuestion.StepId;
        let currentValues = (this.answeredQuestions[key] && this.answeredQuestions[key].checkboxSelections)
            ? this.answeredQuestions[key].checkboxSelections : [];
        if (!currentValues.includes(value)) {
            currentValues.push(value);
        }
        this.answeredQuestions[key].checkboxSelections = currentValues;

        this.saveAnswersToState();
    }

    removeFromCheckboxSelections = (value) => {
        const key = this.component.state.visibleQuestion.StepId;
        let currentValues = (this.answeredQuestions[key] && this.answeredQuestions[key].checkboxSelections)
            ? this.answeredQuestions[key].checkboxSelections : [];
        const location = currentValues.indexOf(value); //ok to use indexOf here because value is of primitive type string
        currentValues.splice(location, 1);
        this.answeredQuestions[key].checkboxSelections = currentValues;
        
        // special check for 'something else' option: If it was unchecked, then make sure the text details are erased as well.
        if (!currentValues.some(answer => {
            return answer.toLowerCase().includes('something else');
        })) {
            this.answeredQuestions[key].textDetails = '';
        }

        this.saveAnswersToState();
    }

    updateCheckboxTextAnswer = (value) => {
        const key = this.component.state.visibleQuestion.StepId;
        this.answeredQuestions[key].textDetails = value;
        this.saveAnswersToState();
    }

    saveAnswersToState = () => {
        this.component.setState({
            answeredQuestions : this.answeredQuestions
        });
    }
    //#endregion

    //#region : Display related operations
    appendToDisplayOrder = (question) => {
        // p.s. a question can occur in the displayOrder only once
        if (!this.displayOrder.some(e => e.StepId === question.StepId)) {
            this.displayOrder.push(question);
        }
        else {
            console.error(`Display order can not have duplicate entries. id : ${question.StepId}`);
        }
    }

    removeLastFromDisplayOrder = () => {
        // By design, display order should never be empty.
        if (this.displayOrder.length > 1) {
            this.displayOrder.pop();
        }
        else {
            console.log(`Can't remove flow root from display order`);
        }
    }

    updateVisibleQuestionInState = (question) => {
        this.component.setState({
            visibleQuestion : question,
        });
    }
    //#endregion

    //#region : Next navigation logic
    loadNextQuestion = () => {
        try {
            const nextStep = this.getNextFlowstep();
            if (!nextStep) {
                throw `no step found after ${this.currentStep._id}`;
            }
            const question = this.getCustomizedQuestion(nextStep);

            this.currentStep = nextStep;
            // if no answers present, then initialize with empty value.
            if (!this.answeredQuestions[nextStep._id]) {
                this.answeredQuestions[nextStep._id] = (question.AnswerFormat === AnswerFormat.CheckboxAndText)
                ? { 
                    checkboxSelections: [], 
                    textDetails: ''
                } 
                : []
            }
            this.appendToDisplayOrder(question);
            this.updateVisibleQuestionInState(question);

            return question.StepId;
        }
        catch (e) {
            console.error(e);
        }
    }

    getNextFlowstep = () => {
        try {
            const nextIds = this.currentStep.children;
            if (!nextIds || nextIds.length === 0) {
                throw `No children present for step : ${this.currentStep._id}`;
            }

            // check if children match conditions
            for(let i = 0; i < nextIds.length; i++) {
                const flowstep = this.flowsteps.find(step => step._id === nextIds[i]);
                if (!flowstep) {
                    console.error(`No flowstep found matching id ${nextIds[i]}`)
                }     
                if (this.evaluateConditions(flowstep)) {
                    // return the first child that meets conditions. (Also by design, only 1 child should be true at a time)    
                    return flowstep;
                }
            }
            throw `Conditions not satisfied for any children of step: ${this.currentStep._id}`;
        }
        catch (e) {
            console.error(e);
        }
    }

    evaluateConditionsArray = (flowstep) => {
        try {
            if (!flowstep.conditions) {
                console.log(`no conditions present for step : ${flowstep._id}`)
                // if no conditions present, it implies no conditions need to be met and this step can be shown.
                return true;
            }
            let conditionEvaluations = [];
            
            flowstep.conditions.forEach(element => {
                const result = this.evaluateCondition(element.stepId, element.values);
                conditionEvaluations.push(result);
            });  
            if (conditionEvaluations.length === 1) {
                // if there was just one condition, return the result of that
                return conditionEvaluations[0];
            }

            if (!flowstep.conditionsEvalOrder) {
                throw (`No condition eval order found for step: ${flowstep._id}`);
            }
            // c is the named argument. It is named such so that the condition eval order expression can be executed in the function.
            let aggregateResult = new Function('c', flowstep.conditionsEvalOrder);
            const areConditionsSatisfied = aggregateResult.call(null, conditionEvaluations);
            return areConditionsSatisfied;
        }
        catch (e) {
            console.error(e);
        }
    }

    evaluateConditions = (flowstep) => {
        try {
            if (!flowstep.conditions) {
                console.log(`no conditions present for step : ${flowstep._id}`)
                // if no conditions present, it implies no conditions need to be met and this step can be shown.
                return true;
            }
            if (Array.isArray(flowstep.conditions)){
                return this.evaluateConditionsArray(flowstep);
            }
            let conditionEvaluations = [];
            for (const [stepId, expectedResponses] of Object.entries(flowstep.conditions)) {
                const result = this.evaluateCondition(stepId, expectedResponses);
                conditionEvaluations.push(result)
            }   
            if (conditionEvaluations.length === 1) {
                // if there was just one condition, return the result of that
                return conditionEvaluations[0];
            }

            if (!flowstep.conditionsEvalOrder) {
                throw (`No condition eval order found for step: ${flowstep._id}`);
            }
            // c is the named argument. It is named such so that the condition eval order expression can be executed in the function.
            let aggregateResult = new Function('c', flowstep.conditionsEvalOrder);
            const areConditionsSatisfied = aggregateResult.call(null, conditionEvaluations);
            return areConditionsSatisfied;
        }
        catch (e) {
            console.error(e);
        }
    }

    evaluateCondition = (stepId, expectedResponses) => {
        let answers = null;
        const flowstep = this.flowsteps.find(flowstep => flowstep._id === stepId);
        // check user answers for the step
        if (this.answeredQuestions[stepId]) {
            // since answers are stored differently for checkbox_&_text answerFormat...
            answers = flowstep.question.answerFormat !== AnswerFormat.CheckboxAndText
            ? this.answeredQuestions[stepId] : this.answeredQuestions[stepId].checkboxSelections;
        }

        if (answers) {
            if (typeof expectedResponses === 'string') {
                if (expectedResponses.toLowerCase() === 'any'){
                    // this means any answer is valid.
                    return true;
                } 
                else {
                    console.error(`Found condition value to be a string - ${expectedResponses}. Check if this is intended.`)
                }
            }
            else if (answers.some(answer => expectedResponses.includes(answer))) {
                return true;
            }
        }
        return false;
    }

    getCustomizedQuestion = (flowstep) => {
        const question = flowstep.question;
        
        // select a random element from each array
        const primaryText = question.inquiries ? question.inquiries[Math.floor(Math.random() * question.inquiries.length)] : "";
        const prefix = question.empathyResponses && question.empathyResponses[Math.floor(Math.random() * question.empathyResponses.length)]
        const displayText = this.getJoinedText(prefix, primaryText) ?? primaryText ?? prefix;
        const placeholder = this.getPlaceholderText(flowstep);

        // create a new object
        let customizedQuestion = {
            StepId : flowstep._id, // since we use flow step id as key everywhere.
            Category : flowstep.category,
            MainInquiry : primaryText ?? displayText,
            DisplayText : displayText,
            AnswerFormat : question.answerFormat,
            AnswerPrompt : question.answerPrompt,
            Placeholder : placeholder,
            AnswerOptions : question.answerOptions,
            FallbackOption: question.fallbackOption
        }
        return customizedQuestion;
    }

    getJoinedText = (prefix, text) => {
        const punctuation = ["!", ",", "."];
        if (!prefix) {
            return text;
        }
        prefix = prefix.trim();
        text = text.trim();
        const lastChar = prefix[prefix.length - 1];
        if (punctuation.includes(lastChar)) {
            if (lastChar === ',') {
                // make the first letter to occur after comma lowercase
                text = text.toLowerCase();
            }
            return (prefix + ' ' + text);
        } 
        else {
            return (prefix + '. ' + text);
        }
    }

    getPlaceholderText = (flowstep) => {
        const question = flowstep.question;

        if (typeof question.placeholder === 'object' && question.placeholder !== null) {
            const dependentStepId = question.placeholder["dependentStepId"];
            const dependentAnswers = this.answeredQuestions[dependentStepId];
            if (!dependentAnswers) {
                console.error(`No answers found for placeholder's dependent step ${dependentStepId}`);
            }
            // join the strings provided in the placeholder object based on dependent question's answers
            let customizedPlaceholder = "";
            dependentAnswers.forEach(answer => {
                customizedPlaceholder += (question.placeholder[answer] + ' ');
            });
            return customizedPlaceholder;
        }
        
        return question.placeholder;
    }

    /* TODO: This is not fully correct right now. 
    It will allow navigation even if no child meets the conditions based on current answers.
    */
    isNextNavigationAllowed = () => {
        if (this.isCurrentStepAnswered() && !this.isCurrentStepLast()) {
            return true;
        } 
        else {
            return false;
        }
    }

    isCurrentStepLast = () => {
        if (this.currentStep.children && (this.currentStep.children.length > 0)) {
            return false;
        }
        return true;
    }

    isCurrentStepAnswered = () => {
        const answers = this.answeredQuestions[this.currentStep._id];
        if (Array.isArray(answers)) {
            if (answers.length > 0) {
                return true;
            }
            return false;
        }
        else { // when answer format is checkbox_&_text
            if(answers.checkboxSelections.length > 0){
                return true;
            }
            return false;
        }
    }

    //#endregion

    //#region : Previous navigation logic
    loadPreviousQuestion = () => {
        this.removeLastFromDisplayOrder();
        const prevQuestion = this.displayOrder[this.displayOrder.length-1];
        this.currentStep = this.flowsteps.find(step => step._id === prevQuestion.StepId);
        this.updateVisibleQuestionInState(prevQuestion);
    }

    isGoingBackAllowed = () => {
        const isAllowed = this.displayOrder.length > 1 ? true : false;
        return isAllowed;
    }
    //#endregion

    interpretBrowserNavigation = (id) => {
        if (this.displayOrder.find(question => question.StepId === id)) {
            /* If the id is accounted for in the display order, but does not match the currently displayed item,
            that means the back button was clicked */
            if (id !== this.displayOrder[this.displayOrder.length - 1]) {
                return 'back';
            }
            else {
                console.log(`refresh`);
            }
        } else {
            return 'next';
        }
    }

    //#region : Finishing Flow
    isFinishingFlowAllowed = () => {
        // If any question in the current display order has been answered, then allow users to finish checking in.
        for (let i = 0; i < this.displayOrder.length; i++) {
            let stepId = this.displayOrder[i].StepId;
            if (this.answeredQuestions[stepId].length > 0) {
                return true;
            }
        }
        return false;
    }

    getDataForSubmission = () => {
        this.syncAnswersBeforeSubmission();

        let answeredQuestions = [];
        this.displayOrder.forEach(question => {
            let answer = this.answeredQuestions[question.StepId];
            if (answer === null || answer === undefined || (answer.length && answer.length === 0) || (answer.checkboxSelections && (answer.checkboxSelections.length === 0))) {
                console.warn(`answer not found for a displayed question. step id : ${question.StepId}`);
                // Don't save questions that were not answered, skip to next element
                return;
            }

            if (question.AnswerFormat === AnswerFormat.CheckboxAndText) {
                const index = answer.checkboxSelections.findIndex((element) => element.toLowerCase().includes('something else'));
                // if user had selected the 'something else' option
                if (index > -1) {
                    // remove it from the array
                    let something_else_option = answer.checkboxSelections.splice(index, 1).toString();
                    // if the user had elaborated on what that something else was, append those details
                    something_else_option = answer.textDetails ? `${something_else_option} : ${answer.textDetails}` : something_else_option;
                    // push the updated string to the back of the array
                    answer.checkboxSelections.push(something_else_option);
                }
                answer = answer.checkboxSelections.toString();
            }
            else {
                answer = answer.toString();
            }
            const answeredQuestion = {
                stepId : question.StepId,
                category: question.Category,
                question: question.MainInquiry,
                answerFormat: question.AnswerFormat,
                answer: answer
            }
            answeredQuestions.push(answeredQuestion);
        });
        const entry = {
            answeredQuestions: answeredQuestions
        };
        return entry;
    }

    syncAnswersBeforeSubmission = () => {
        // Drop those entries from answered questions that are no longer in the displayed questions
        const keys = Object.keys(this.answeredQuestions);
        keys.forEach((id, answers) => {
            if (!this.displayOrder.some(question => question.StepId === id)) {
                console.log(`Dropped answers for ${id} before submission`);
                delete this.answeredQuestions[id];
            }
        });

        this.saveAnswersToState();
    }

    //#endregion
}
import { useState } from "react"
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min"
import { ResetPassword } from "../components/authentication/ResetPassword"
import { ForgotPassword } from "../components/authentication/ForgotPassword"
import { SignUp } from "../components/authentication/SignUp"
import { SignIn } from "../components/authentication/SignIn"

export const RoutesForSignedOutUser = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    return (
        <Switch>
            <Route path="/reset-password">
                <ResetPassword />
            </Route>
            <Route path="/forgot-password">
                <ForgotPassword />
            </Route>
            <Route path="/signup">
                <SignUp
                    password={password}
                    setPassword={setPassword}
                    email={email}
                    setEmail={setEmail}
                />
            </Route>
            <Route>
                <SignIn
                    password={password}
                    setPassword={setPassword}
                    email={email}
                    setEmail={setEmail}
                />
            </Route>
        </Switch>
    )
}
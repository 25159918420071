import React from 'react';
import '../css/insights.css';

function InsightHeading(props) {
    let headingStyle = {
        backgroundColor: props.headingColor
    };
    if (props.headingSize) {
        headingStyle['fontSize'] = props.headingSize;
    }

    return (
        <p className='insights-heading' style={props.pStyle ? props.pStyle : {}}>
            <span className='insights-heading-span' style={headingStyle}>
                {props.heading}
            </span>
        </p>
    )
}

export default InsightHeading;
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import "../css/auth/signin-signup.css";
import { GoogleAuthButton } from "./GoogleAuthButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LineBreak } from "./Linebreak";
import { FloatingLabelInput } from "./FloatingLabelInput";
import { useEffect, useState } from "react";
import { STRONG_PASSWORD_REGEX } from "../../Constants";

export const SignUp = ({
    email,
    setEmail,
    password,
    setPassword,
}) => {
    const auth = getAuth();
    const [createUserWithEmailAndPassword, user, loading, error] =
        useCreateUserWithEmailAndPassword(auth);

    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!STRONG_PASSWORD_REGEX.test(password)) {
            setErrorMessage("Password must match criteria");
        }
        else {
            createUserWithEmailAndPassword(email, password);
        }
    };

    useEffect(() => {
        // Handle Signup Errors
        if (error) {
            setErrorMessage("Invalid Credentials");
        }
    }, [error]);

    return (
        <div className="auth-container">
            <h1 className="title">Sign Up</h1>
            <GoogleAuthButton />
            <LineBreak />
            <form
                onSubmit={(e) => {
                    handleSubmit(e);
                }}
                className="firebase-auth-form-container"
            >
                <div className="email-password-container">
                    <FloatingLabelInput
                        id="signup-email"
                        type="email"
                        value={email}
                        required={true}
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                    />
                    <FloatingLabelInput
                        id="signup-password"
                        type="password"
                        value={password}
                        required={true}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                    />
                    <p className="password-helper-text">
                        8 characters with upper & lowercase letters and numbers
                    </p>
                </div>
                {errorMessage && (
                    <p className="auth-error">Error: {errorMessage}</p>
                )}
                <input
                    type="submit"
                    value="Sign Up"
                    onSubmit={(e) => handleSubmit(e)}
                    className="signin-signup-button"
                />
                <div className="switch-button-container">
                    <span>Have an account? </span>
                    <Link to="/" className="switch-button">Sign In</Link>
                </div>
            </form>
        </div>
    );
};

import React from 'react';
import Tag from './Tag';

function AnswerTags(props) {

    const getAnswerValues = (answerString) => {
        let answers = answerString?.split(',') ?? [];

        // copied from backend; to keep the something else and details together
        const somethingElseIndex = answers.findIndex(answer => (answer.includes('Something else :') 
        || answer.includes('Something else... :')
        || answer.includes('something else :') 
        || answer.includes('something else... :')));

        if (somethingElseIndex > -1 && somethingElseIndex < answers.length - 1) {
            const somethingElseArray = answers.splice(somethingElseIndex);
            const somethingElseAnswer = somethingElseArray.join().trim();
            answers.push(somethingElseAnswer);
        }

        return answers;
    }

    const getTagAndTextColor = (tagText) => {
         // defaults
        let tagColor = props.colors.tag.LIGHT;
        let tagTextColor = "white";

        if (!props.colorScheme || (props.colorScheme === 'LIGHT')) {
            return {
                tagColor: tagColor,
                tagTextColor: tagTextColor
            };
        }
        // for dark color scheme
        if (props.highlightTag && (tagText === props.highlightTag)) {
            // colors are inverted for highlighted tag
            tagTextColor = "black";
            tagColor = props.colors.tag.MEDIUM;
        }
        else {
            tagColor = props.colors.tag.DARK;
            // text color stays white for non highlighted tags
        }
        
        return {
            tagColor: tagColor,
            tagTextColor: tagTextColor
        };
    }

    return (
        <div className='answer-tags-container'>
            {
                getAnswerValues(props.answer).map((answer) => {
                    const colors = getTagAndTextColor(answer);
                    return <Tag key={answer} text={answer} className='card-answer-tag'
                        tagColor={colors.tagColor}
                        tagTextColor={colors.tagTextColor}/>
                })
            }
        </div>
    )
}

export default AnswerTags;
const server = process.env.REACT_APP_SERVER;

export async function getHealthStatus() {
    try {
        var response = await fetch(`${server}/healthcheck`);
        return response.json();
    }
    catch (e) {
        console.error(e, 'health check');
    }
}
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { spread } from 'largest-remainder-round';
import '../css/piechart.css';
import InsightHeading from './InsightHeading';

function FeelingsPieChart(props) {
    const svgRef = useRef();
    const [isChartReady, setChartReady] = useState(false);

    const pleasantFeelingKeys = ['Awesome!', 'Good', 'Alright pleasant'];
    const unpleasantFeelingKeys = ['Alright unpleasant', 'Meh', 'Not good', 'Awful'];
    const baseDistributionKeys = ['Pleasant', 'Unpleasant'];

    // These must be sorted, otherwise the pie animation is drawn weirdly
    const [pleasantUnpleasantDistribution, setBaseDistribution] = useState();
    const [pleasantDistribution, setPleasantDistributionDetails] = useState();
    const [unpleasantDistribution, setUnpleasantDistributionDetails] = useState();

    //#region helper functions
    const getColorSet = (data) => {
        let colorSet = [];
        data.forEach(element => {
            const color = props.colorSet[element._id];
            colorSet.push(color);
        });
        return colorSet ?? d3.schemeSet2;
    }

    const getLabelPosition = (sliceValue, radius, minValue, data) => {
        const middlePosition = radius;
        const twoThirdPosition = radius * 2 - radius * 2 / 3;

        const outerRadius = sliceValue > minValue ? middlePosition : twoThirdPosition;
        const arc1 = d3.arc().innerRadius(0).outerRadius(outerRadius);
        return arc1.centroid(data);
    }

    //#endregion

    useEffect(() => {
        if(!props.data) {
            return;
        }

        let baseDist = [];
        let totalBaseCount = 0;
        let pleasantDist = [];
        let unpleasantDist = [];
        props.data.forEach((obj) => {
            if (pleasantFeelingKeys.includes(obj._id)) {
                pleasantDist.push(obj);
            }
            else if (unpleasantFeelingKeys.includes(obj._id)) {
                unpleasantDist.push(obj);
            }
            else if (baseDistributionKeys.includes(obj._id)) {
                baseDist.push(obj);
                totalBaseCount += obj.count;
            }
            else {
                console.warn('Unrecognized _id', obj._id);
                return;
            }
        })

        // These must be sorted high to low, otherwise the pie animation is drawn weirdly
        baseDist.sort((a, b) => b.count - a.count);
        pleasantDist.sort((a, b) => b.count - a.count);
        unpleasantDist.sort((a, b) => b.count - a.count);

        setBaseDistribution(baseDist.spread({prop: 'count', spreadTo: 'percentage'}));
        setPleasantDistributionDetails(
            pleasantDist.map(obj => ({...obj, percentage: Math.round((obj.count/totalBaseCount) * 100)}))
        )
        setUnpleasantDistributionDetails(
            unpleasantDist.map(obj => ({...obj, percentage: Math.round((obj.count/totalBaseCount) * 100)}))
        )
       
    }, [props.data])

    useEffect(() => {

        if (pleasantUnpleasantDistribution?.length < 1 || !pleasantDistribution || !unpleasantDistribution) {
            return;
        }

        // Step 1: Set up svg container
        const width = 400;
        const height = 400;
        const radius = width / 2.5;
        const svg = d3.select(svgRef.current)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('class', 'pie-svg')
            .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .style('overflow', 'visible')

        const svgWidth = svg.node().getBoundingClientRect().width;

        // Step 2: Set up the charts
        const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

        const borderArc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius + 2);

        
        // #region Draw base pie with the overall distribution - % pleasant and unpleasant

        const pie = svg.append('g')
            .attr('class', 'pie')
            .attr('transform', `translate(${[0, 25]})`); // move the pies a little bit down to create more space for legends

        const pleasantUnpleasantPieData = d3.pie().value(d => d.count)(pleasantUnpleasantDistribution);

        const pieSlices = pie.selectAll(".subgroup")
            .data(pleasantUnpleasantPieData)
            .enter().append("g")
            .attr("class", "slice")
            .style('cursor', 'pointer');
        
        pieSlices.data(pleasantUnpleasantPieData)
            .append('title')
            .text(d => d.data._id);

        const baseColors = d3.scaleOrdinal().range(getColorSet(pleasantUnpleasantDistribution));

        pieSlices.data(pleasantUnpleasantPieData)        
            .append('path')
            .attr('d', arc)
            .attr('fill', (v, i) => baseColors(i))
            .attr('class', 'segment');
        
        pieSlices.data(pleasantUnpleasantPieData)
            .append('path')
            .attr('class', (d, i) => `border-${i}`)
            .attr('d', borderArc)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 5);

        pieSlices.data(pleasantUnpleasantPieData)
            .append('text')
                .text(d => d.data.percentage > 14 ? d.data._id : '')
                .attr('transform', (d, i) => {
                    const position = getLabelPosition(d.data.percentage, radius, 20, d);
                    return "translate(" + position + ")";
                })
                .attr('font-size', '1rem')
                .attr('font-weight', 600)
                .style(`text-anchor`, 'middle')
                .style('fill', 'white')
            .append('tspan')
                .text(d => d.data.percentage > 14
                    ? `${d.data.percentage}%` : '')
                .attr('dy', '1.5em')
                .attr('x', 0)
                .attr('font-size', '0.85em')
                .attr('font-weight', 600);


        pieSlices.on('mouseover', function(d){
            const key = d3.select(this).select('title').text();
            let color = '#dd6b62';
            if (key === 'Pleasant') {
                color = '#45ddad'
            }
            d3.select(this).select('.segment')
                .transition()
                .duration(500)
                .style("fill", color);
        })
        .on('mouseout', function(d){
            d3.select(this).select('.segment')
                .transition()
                .duration(500)
                .style("fill", null);
        })

        // Add on click event handler to all pie slices
        pieSlices.on("click", function (d) {
            const sliceName = d3.select(this).select('title').text();
            if (sliceName === 'Pleasant') {
                unpleasantPie.style('display', 'none');
                unpleasantPieLegend.style('display', 'none');
                unpleasantPieSlices.selectAll('text')
                    .style('display', 'none');
                
                unpleasantPieSlices.on('mouseover', null)
                    .on('mouseout', null)
                    .on('click', null);

                makePieAvailable(pleasantPie, pleasantPieSlices, pleasantPieLegend, arc, pleasantPieData);
            }

            if (sliceName === 'Unpleasant') {
                pleasantPie.style('display', 'none');
                pleasantPieLegend.style('display', 'none');
                pleasantPieSlices.selectAll('text')
                    .style('display', 'none');

                pleasantPieSlices.on('mouseover', null)
                    .on('mouseout', null)
                    .on('click', null);

                makePieAvailable(unpleasantPie, unpleasantPieSlices, unpleasantPieLegend, arc, unpleasantPieData);
            }
        });

        //#endregion
    
        // #region create pie segment for Pleasant distribution that becomes visible on click
        const pleasantPie = svg.append('g')
            .attr('class', 'pleasantpie')
            .attr('transform', `translate(${[0, 25]})`)
            .style('display', 'none');

        const pleasantIndex = pleasantUnpleasantDistribution.findIndex(obj => obj._id === 'Pleasant');
        const pleasantPieGenerator = d3.pie()
            .value(d => d.count)
            .startAngle(pleasantUnpleasantPieData[pleasantIndex]?.startAngle ?? 0)
            .endAngle(pleasantUnpleasantPieData[pleasantIndex]?.endAngle ?? 0); // these are in radians fyi

        // Generate pie data based on the input values
        const pleasantPieData = pleasantPieGenerator(pleasantDistribution);

        // Create a group for each slice. Each group will contain 3 elements of a slice - pie segment, border and text
        const pleasantPieSlices = pleasantPie.selectAll(".subgroup")
            .data(pleasantPieData)
            .enter().append("g")
            .attr("class", "slice")
            .style('cursor', 'pointer');

        pleasantPieSlices.data(pleasantPieData)
            .append('title').text(d => d.data._id)
        
        // Add segments to each slice
        const pleasantColors = d3.scaleOrdinal().range(getColorSet(pleasantDistribution));

        pleasantPieSlices.data(pleasantPieData)
            .append("path")
                .attr('fill', (v, i) => pleasantColors(i))
                .attr("d", arc);

        // Add border to each slice
        pleasantPieSlices.data(pleasantPieData)
            .append('path')
            .attr('class', (d, i) => `border-${i}`)
            .attr('d', borderArc)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 3);
        
        // Add text labels to each slice
        pleasantPieSlices.data(pleasantPieData)
            .append('text')
                .text(d => d.data.percentage > 14 
                    ? (d.data._id.includes('Alright') ? 'Alright' : d.data._id)
                    : ''
                )
                .attr('transform', (d, i) => {
                    const position = getLabelPosition(d.data.percentage, radius, 40, d);
                    return "translate(" + position + ")";
                })
                .attr('font-size', '1rem')
                .attr('font-weight', 600)
                .style(`text-anchor`, 'middle')
                .style('fill', 'white')
                .style('display', 'none')
            .append('tspan')
                .text(d => d.data.percentage > 14
                    ? `${d.data.percentage}%` : '')
                .attr('dy', '1.5em') // moves the % number to a line-and-a-half below
                .attr('x', 0) // centers the % w.r.t the label's position
                .attr('font-size', '0.85em')
                .attr('font-weight', 600);

        //#endregion

        // #region create pie segment for Unpleasant distribution that becomes visible on click
        const unpleasantPie = svg.append('g')
            .attr('class', 'unpleasantpie')
            .attr('transform', `translate(${[0, 25]})`)
            .style('display', 'none');

        const unpleasantIndex = pleasantUnpleasantDistribution.findIndex(obj => obj._id === 'Unpleasant');
        const unpleasantPieGenerator = d3.pie()
            .value(d => d.count)
            .startAngle(pleasantUnpleasantPieData[unpleasantIndex]?.startAngle ?? 0)
            .endAngle(pleasantUnpleasantPieData[unpleasantIndex]?.endAngle ?? 0); // these are in radians fyi

        // Generate pie data based on the input values
        const unpleasantPieData = unpleasantPieGenerator(unpleasantDistribution);

        const unpleasantPieSlices = unpleasantPie.selectAll(".subgroup")
            .data(unpleasantPieData)
            .enter().append("g")
            .attr("class", "slice")
            .style('cursor', 'pointer');

        unpleasantPieSlices.data(unpleasantPieData)
            .append('title').text(d => d.data._id)
    
        // Add segments to each slice
        const unpleasantColors = d3.scaleOrdinal().range(getColorSet(unpleasantDistribution));
        
        unpleasantPieSlices.data(unpleasantPieData)
            .append("path")
            .attr('fill', (v, i) => unpleasantColors(i))
            .attr("d", arc);

        // Add border to each slice
        unpleasantPieSlices.data(unpleasantPieData)
            .append('path')
            .attr('class', (d, i) => `border-${i}`)
            .attr('d', borderArc)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 3);
        
        // Add text labels to each slice
        unpleasantPieSlices.data(unpleasantPieData)
            .append('text')
                .text(d => d.data.percentage > 14
                    ? (d.data._id.includes('Alright') ? 'Alright' : d.data._id)
                    : '')
                .attr('transform', (d, i) => {
                    const position = getLabelPosition(d.data.percentage, radius, 40, d);
                    return "translate(" + position + ")";
                })
                .attr('font-size', '1rem')
                .attr('font-weight', 600)
                .style(`text-anchor`, 'middle')
                .style('fill', 'white')
                .style('display', 'none')
            .append('tspan')
                .text(d => d.data.percentage > 14
                    ? `${d.data.percentage}%` : '')
                .attr('dy', '1.5em') // moves the % number to a line-and-a-half below
                .attr('x', 0) // centers the % w.r.t the label's position
                .attr('font-size', '0.85em')
                .attr('font-weight', 600);

        //#endregion
    

        // #region create legends

        // #region legend for base pie
        const baseLegend = svg.append('g')
            .attr('class', 'base-legend')
            .attr('x', 0)
            .attr('y', 0)

        const lg = baseLegend.selectAll('g')
            .data(pleasantUnpleasantDistribution)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(${i * 100 - radius/2}, ${-radius - 55})`);

        lg.append('rect')
            .style('fill', (d,i) => baseColors(i))
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 20)
            .attr('height', 20)

        lg.append('text')
            .text(d => d._id)
            .attr('class', 'label')
            .attr('dx', '2em')
            .attr('dy', '0.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7');

        lg.append('text')
            .text(d => `${d.percentage}%`)
            .attr('class', 'percentage')
            .attr('dx', '2em')
            .attr('dy', '1.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7')

        //#endregion

        // #region legend for pleasant pie
        const totalPleasantLegendWidth = pleasantDistribution.length * 100;
        const startX = -totalPleasantLegendWidth/2 + 20;
        const pleasantPieLegend = svg.append('g')
            .attr('class', 'pleasant-legend')
            .attr('x', 0)
            .attr('y', 0)
            .style('display', 'none')

        const pleasantLegendGroups = pleasantPieLegend.selectAll('g')
            .data(pleasantDistribution)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(${(startX + i * 100)}, ${-radius - 20})`);

        pleasantLegendGroups.append('rect')
            .style('fill', (d,i) => pleasantColors(i))
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 15)
            .attr('height', 15)

        pleasantLegendGroups.append('text')
            .text(d => (d._id.includes('Alright') ? 'Alright' : d._id))
            .attr('class', 'label')
            .attr('dx', '2em')
            .attr('dy', '0.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7');

        pleasantLegendGroups.append('text')
            .text(d => `${d.percentage}%`)
            .attr('class', 'percentage')
            .attr('dx', '2em')
            .attr('dy', '1.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7')

        //#endregion

        // #region legend for unpleasant pie
        const totalUnpleasantLegendWidth = unpleasantDistribution.length * 100;
        const startXPos = -totalUnpleasantLegendWidth/2 + 20;
        const unpleasantPieLegend = svg.append('g')
            .attr('class', 'unpleasant-legend')
            .attr('x', 0)
            .attr('y', 0)
            .style('display', 'none')

        const unpleasantLegendGroups = unpleasantPieLegend.selectAll('g')
            .data(unpleasantDistribution)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(${(startXPos + i * 100)}, ${-radius - 20})`);

        unpleasantLegendGroups.append('rect')
            .style('fill', (d,i) => unpleasantColors(i))
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 15)
            .attr('height', 15)

        unpleasantLegendGroups.append('text')
            .text(d => (d._id.includes('Alright') ? 'Alright' : d._id))
            .attr('class', 'label')
            .attr('dx', '2em')
            .attr('dy', '0.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7');

        unpleasantLegendGroups.append('text')
            .text(d => `${d.percentage}%`)
            .attr('class', 'percentage')
            .attr('dx', '2em')
            .attr('dy', '1.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7')

        //#endregion
        
        //#endregion

        if (props.pieToDisplay === 'Pleasant') {
            makePieAvailable(pleasantPie, pleasantPieSlices, pleasantPieLegend, arc, pleasantPieData);
        }
        if (props.pieToDisplay === 'Unpleasant') {
            makePieAvailable(unpleasantPie, unpleasantPieSlices, unpleasantPieLegend, arc, unpleasantPieData);
        }

        setChartReady(true);

    }, [pleasantUnpleasantDistribution, pleasantDistribution, unpleasantDistribution, props.pieToDisplay]);

    // This function is useful to make the pleasant or unpleasant distribution pies visible on click or as per props
    const makePieAvailable = (pie, slices, legend, arc, pieData) => {
        pie.style('display', 'inline');
        legend.style('display', 'inline');

        slices.selectAll("path")
            .transition()
            .duration(1000) // in milliseconds
            .attrTween("d", function (d, ) {
                const initialState = { startAngle: pieData[0].startAngle, endAngle: pieData[0].startAngle }
                const interpolate = d3.interpolate(initialState, d);
                return function (t) {
                    // Generate the path string based on the interpolated data
                    return arc(interpolate(t));
                };
            })
            .on("end", function () {
                // show the text labels when transition is completed
                slices.selectAll('text')
                    .style('display', 'inline');

                slices.on("mouseover", function (d, slice) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr("transform", "scale(1.1)");
                })
                .on("mouseout", function (d, slice) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr("transform", "scale(1)");
                });

                slices.on('click', function(d){
                    const feeling = d3.select(this).select('title').text();
                    if (props.onClick) {
                        props.onClick(feeling);
                    }
                })
            });
    }

    const getHeadingStyle = () => {
        if (window.innerWidth < 700) {
            return { 'marginBottom': '1.5rem' };
        }
        else {
            return { 'marginBottom': '3rem' }
        }
    }

    return (
        <div id='chartDiv'>
            <InsightHeading heading={props.heading} headingColor={props.headingColor}
                pStyle={getHeadingStyle()} />
            <svg ref={svgRef} />
            {!isChartReady ? <p className='error'>compute error x_x</p> : <></>}
        </div>
    )
}

export default FeelingsPieChart;
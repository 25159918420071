import React, { useState } from 'react';

function SomethingElseText(props) {
    const [value, setValue] = useState(props.value);
    const onChange = (event) => {
        setValue(event.target.value);
        props.onAnswered(event);
    }
    return (
        <textarea id="checkbox_&_text"
            key={props.questionId} className='journal-input something-else-details'
            spellCheck="false" placeholder="what's that something else..?"
            onChange={onChange}
            value={value}
        />
    )
}

export default SomethingElseText;
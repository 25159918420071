import React from 'react'
import './css/formnavbuttons_mobile.css'

function NextMobile(props) {

    let style = props.highlighted ? {"borderColor":"white", "fontWeight":"bold"} : {}

    return (
        !props.disabled &&
        <button className="nav-btn-mobile next-mobile" style={style}
        disabled={props.disabled} onClick={props.onClick}>
            Next
        </button>
    )
}

export default NextMobile
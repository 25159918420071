const server = process.env.REACT_APP_SERVER;

export async function getReminderSchedule(userId) {
    try {
        const requestParams = {
            Name: `rule${userId}`
        };
        const response = await fetch(`${server}/schedule?${new URLSearchParams(requestParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getReminderSchedule');
    }
}

export async function updateReminderSchedule(userId, scheduleExpression = undefined, scheduleTimezone = undefined, state = undefined, input = undefined) {
    try {
        const url = new URL(`${server}/schedule`)
        url.searchParams.append('Name', `rule${userId}`);
        if (scheduleExpression) {
            url.searchParams.append('ScheduleExpression', scheduleExpression);
        }
        if (scheduleTimezone) {
            url.searchParams.append('ScheduleExpressionTimezone', scheduleTimezone);
        }
        if (state) {
            url.searchParams.append('State', state);
        }
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
        };
        if (input) {
            const jsonBody = JSON.stringify(input);
            requestOptions.body = jsonBody;
        }
        const response = await fetch(url, requestOptions);
        return response;
    }
    catch (e) {
        console.error(e, 'updateReminderSchedule');
    }
}

export async function createReminderSchedule(schedule) {
    try {
        const url = new URL(`${server}/schedule`)
        const jsonBody = JSON.stringify(schedule);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonBody
        };
        const response = await fetch(url, requestOptions);
        return response;
    }
    catch (e) {
        console.error(e, 'createReminderSchedule');
    }
}
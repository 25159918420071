import StripeLogo from "../../images/stripe-logo.svg";
import styles from "../css/payments/powered-by-stripe-text.module.css";

/* This component is absolutely positioned over the checkout form because we cannot add info to the stripe checkout directly */

export const PoweredByStripeText = () => {
    return (
        <span className={styles.poweredByStripeText}>
            Powered by&nbsp;
            <img src={StripeLogo} alt="Stripe" height="15px"></img>
        </span>
    )
}

import React from "react";
import "./css/account-circle.css";
import { firebaseSignOut } from "../auth/firebaseSignOut";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LogoutButton = ({ style = {} }) => {
  const history = useHistory();

  return (
    <button
      className="account-circle-dropdown-button"
      style={style}
      onClick={() => {
        firebaseSignOut();
        history.push("/");
        window.location.reload();
      }}
    >
      Log Out
    </button>
  );
};

export default LogoutButton;

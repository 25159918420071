const server = process.env.REACT_APP_SERVER;

export async function insertFlowentry(entry) {
    try {
        const jsonBody = JSON.stringify(entry);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: jsonBody
        };
        const response = await fetch(`${server}/flowentry`, requestOptions);
        return response;
    }
    catch (e) {
        console.error(e, 'insertFlowentry');
    }
}

export async function getEntries(reqParams) {
    try {
        const response = await fetch(`${server}/flowentries?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getFlowentries');
    }
}

export async function getWorkEntries(reqParams) {
    try {
        const response = await fetch(`${server}/workentries?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getWorkEntries');
    }
}
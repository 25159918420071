const server = process.env.REACT_APP_SERVER;

export async function createOrUpdateProfile(profile) {
    try {
        const jsonBody = JSON.stringify(profile);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: jsonBody
        };
        const response = await fetch(`${server}/profile`, requestOptions);
        return response;
    }
    catch (e) {
        console.error(e, 'createOrUpdateProfile');
    }
}

export async function getProfile(userId) {
    try {
        const requestParams = {
            collectionName: userId
        };
        const response = await fetch(`${server}/profile?${new URLSearchParams(requestParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getProfile');
    }
}
import React from "react";
const loadingImg =
    "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

class Loading extends React.Component {

    constructor(props) {
        super(props)

        this.style = {
            "display": "flex",
            "justifyContent": "center"
        };
    }

    render() {
        return (
            <div style={this.style}>
                <img src={loadingImg} alt="Loading..." />
            </div>
        );
    }
}

export default Loading;
import { IS_STRIPE_IN_LIVEMODE } from "../Constants";

/**
 * Retrieves environment appropriate custom claims from a JSON string
 *
 * @param {string} customClaimsString - A JSON string containing custom claims data.
 * @returns {{ onboarded: boolean, isUserPaidSubscriber: boolean, stripeCustomerId?: string | undefined }}
 */
export const getCustomClaims = (customClaimsString) => {
    let onboarded = false;
    let isUserPaidSubscriber = false;
    let stripeCustomerId;
    let customClaims = {}

    try { customClaims = JSON.parse(customClaimsString) }
    catch { console.error(`Invalid customClaimsString, ${customClaimsString}`) }

    if (IS_STRIPE_IN_LIVEMODE) {
        onboarded = customClaims.onboarded || false;
        isUserPaidSubscriber = customClaims.isUserPaidSubscriber || false;
        stripeCustomerId = customClaims.stripeCustomerId;
    }
    else {
        onboarded = customClaims.test?.onboarded || false;
        isUserPaidSubscriber = customClaims.test?.isUserPaidSubscriber || false;
        stripeCustomerId = customClaims.test?.stripeCustomerId;
    }
    return { onboarded, isUserPaidSubscriber, stripeCustomerId };
};
import React, { useRef } from 'react';
import styles from './css/PhoneNumberInput.module.css';

export const PhoneNumberInput = ({ phoneNumber, setPhoneNumber }) => {
    const inputRef = useRef(null);
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    const handleInputChange = (event) => {
        setPhoneNumber(unformatPhoneNumber(event.target.value));
    };

    // Select the input if user clicks anywhere on this component
    const handleDivClick = () => {
        inputRef.current.focus();
    };

    return (
        <div onClick={handleDivClick} className={styles.phoneNumberInput}>
            <div className={styles.phoneNumberInputPrefix}>+1</div>
            <input
                ref={inputRef}
                type="tel"
                value={formattedPhoneNumber}
                onChange={handleInputChange}
                className={styles.phoneNumberInputField}
                placeholder="(---) --- ----"
            />
        </div>
    );
};

const unformatPhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/\D/g, ''); // remove non-numbers
    if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.substring(0, 10);
    }
    return phoneNumber;
};

const formatPhoneNumber = (phoneNumber) => {
    phoneNumber = unformatPhoneNumber(phoneNumber);

    let match = phoneNumber.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);
    if (match) {
        return '(' + match[1] + (match[2] ? ') ' + match[2] : '') + (match[3] ? '-' + match[3] : '');
    }
    return phoneNumber;
};

export const isPhoneNumberValid = (phoneNumber) => {
    // Check if phoneNumber is null or undefined. Allow empty string.
    if (phoneNumber === null || phoneNumber === undefined) {
        return false;
    }

    // Check if every character in phoneNumber is a digit
    for (let number of phoneNumber) {
        if (isNaN(Number(number))) {
            return false;
        }
    }

    // Check if the phone number is valid length. 
    // Must be either a full length phone number with area code or no phone number.
    const isValidLength = phoneNumber.length === 10 || phoneNumber.length === 0;

    return isValidLength;
};

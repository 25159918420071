import { ReminderActions } from "../Constants";
import { getReminderSchedule, updateReminderSchedule } from "../adapters/reminders-adapter";
import { getEntriesInDay } from "./CalendarHelper";
import businessDays from 'business-days-js';

const businessDaysUS = businessDays({ excludeHolidays: ["columbus day", "veterans day"] });

export async function updateReminderIfApplicable(entries, userId) {
    try {
        // Use the last 10 entries only coz it's unlikely that a user did more than 10 flows in a day.
        const mostRecentEntries = entries.slice(-10);
        const today = new Date();
        const flowsInDay = getEntriesInDay(mostRecentEntries, today);

        if (!flowsInDay || flowsInDay.length === 0) {
            return false;
        }

        const reminderAction = determineReminderUpdateAction(flowsInDay);

        if (reminderAction === ReminderActions["Move to Next Day Flow Time"]) {
            const newScheduleExpression = getScheduleBasedOnFlowTime(flowsInDay[flowsInDay.length - 1]);
            updateReminderSchedule(userId, newScheduleExpression).then((response) => {
                if (response.ok) {
                    return true;
                }
            })
        }
        else if (reminderAction === ReminderActions["Move to Next Day"]) {
            // Get current schedule api
            const currentSchedule = await getReminderSchedule(userId);
            const currentScheduledTime = currentSchedule.ScheduleExpression;
            const newScheduleExpression = getScheduleBasedOnCurrentReminder(currentScheduledTime, today);
            updateReminderSchedule(userId, newScheduleExpression).then((response) => {
                if (response.ok) {
                    return true;
                }
            })
        }
        else {
            // No OP
            return false;
        }
    }
    catch (e) {
        // TODO: This should be logged somewhere
        console.error("An error occurred while checking for reminder update.", e);
    }
}

const determineReminderUpdateAction = (flowsInDay) => {
    // If user just did their first flow
    if (flowsInDay.length === 1) {
        const flowDate = flowsInDay[0].date;
        const flowTimeWindow = getFlowRelationshipToWorkHours(flowDate);
        if (flowTimeWindow === "before") {
            //The reminder should just be moved to next day without time change
            return ReminderActions["Move to Next Day"];
        }
        else if (flowTimeWindow === "during") {
            //Move reminder to this time next day.
            return ReminderActions["Move to Next Day Flow Time"];
        }
        else {
            // do nothing for after hours flow.
            return ReminderActions["No Update"];
        }
    }

    // If user has done multiple flows
    if (flowsInDay.length > 1) {
        // get the index of the first flow that happened during work hours
        const indexOfFirstFlowDuringWorkhours = flowsInDay.findIndex(
            flow => getFlowRelationshipToWorkHours(flow.date) === "during"
        )
        // if the last flow is in fact the first flow done during work hours
        if (indexOfFirstFlowDuringWorkhours === flowsInDay.length - 1) {
            return ReminderActions["Move to Next Day Flow Time"];
        }
        else {
            return ReminderActions["No Update"];
        }
    }

    return ReminderActions["No Update"];
}

const getFlowRelationshipToWorkHours = (flowDate) => {
    const sevenAM = new Date(flowDate);
    sevenAM.setHours(7, 0, 0, 0); // 7:00 AM

    const nineAM = new Date(flowDate);
    nineAM.setHours(9, 0, 0, 0); // 9:00 AM

    const sixPM = new Date(flowDate);
    sixPM.setHours(18, 0, 0, 0); // 6:00 PM

    if (flowDate >= sevenAM && flowDate < nineAM) {
        return 'before';
    }

    if (flowDate >= nineAM && flowDate <= sixPM) {
        return 'during';
    }

    if (flowDate > sixPM || flowDate < sevenAM) {
        return 'after';
    }

    return 'not understood';
}

const getScheduleBasedOnCurrentReminder = (currentScheduleExpression, today) => {
    const currentScheduleTimeString = currentScheduleExpression.split('T')[1].slice(0, -1);
    let [hours, minutes, seconds] = currentScheduleTimeString.split(':').map(Number);
    let newDateTime = businessDaysUS.addDays(today, 1).toDate();
    newDateTime.setHours(hours);
    newDateTime.setMinutes(minutes);
    newDateTime.setSeconds(seconds);
    return constructScheduleExpression(newDateTime);
}

const getScheduleBasedOnFlowTime = (flow) => {
    let newDateTime = businessDaysUS.addDays(flow.date, 1).toDate(); // next business day
    newDateTime.setMinutes(flow.date.getMinutes() - 3); // 3 minutes before whatever the time is now.
    return constructScheduleExpression(newDateTime);
}

export const getScheduleExpForNextBizDay = (today, time=null) => {
    const nextBizDay = businessDaysUS.addDays(today, 1).toDate();
    // i.e. if user has provided a time where they want to be doing a flow
    if (time) {
        const [hour, minutes] = time.split(':'); // time will be in the HH:MM format
        nextBizDay.setHours(hour, minutes, 0, 0);
        return constructScheduleExpression(nextBizDay);
    }
    const nineAM = new Date(nextBizDay);
    nineAM.setHours(9, 0, 0, 0); // 9:00 AM
    const sixPM = new Date(nextBizDay);
    sixPM.setHours(18, 0, 0, 0); // 6:00 PM
    // If within business hours
    if (nineAM <= nextBizDay && nextBizDay <= sixPM) {
        // use the same time as right now if within business hours
        return constructScheduleExpression(nextBizDay);
    }
    else {
        nextBizDay.setHours(14, 0, 0, 0); // 2pm is the most likely time
        return constructScheduleExpression(nextBizDay);
    }
}

/**
 * The schedule expression given to AWS scheduler needs to follow ISO format: "at(2024-06-07T18:50:00)"
 * But we can't use Date.toISOString because that will convert the local dateTime input to UTC.
 * In order to get the local time in ISO format, I'm using a hack from SO https://stackoverflow.com/a/60368477/4357770
 * which utilizes swedish language "sv" because they format their date time in ISO format.
 */
const constructScheduleExpression = (dateTime) => {
    const ISODate = dateTime.toLocaleDateString("sv");
    const ISOTime = dateTime.toLocaleTimeString("sv");
    return `at(${ISODate}T${ISOTime})`;
}

export const isUserNew = (onboardedDate, today) => {
    // for older users
    if (!onboardedDate) {
        return false;
    }
    const numberOfDaysPostOnboarding = businessDaysUS.countDays(onboardedDate, today).businessDays;
    if (numberOfDaysPostOnboarding <= 10) {
        return true;
    }
    return false;
}

export const didUserOnboardNearMonthEnd = (onboardedDate) => {
    // for older users
    if (!onboardedDate) {
        return false;
    }
    const newMonthStart = new Date(onboardedDate.getFullYear(), onboardedDate.getMonth() + 1, 1);
    // calculate number of business days between date of onboarding and new month start
    const businessDaysDelta = businessDaysUS.countDays(onboardedDate, newMonthStart).businessDays;
    if (businessDaysDelta <= 5) {
        return true;
    }
    return false;
}

// public function available to the rest of the app
let exports = { updateReminderIfApplicable, isUserNew, didUserOnboardNearMonthEnd };

// private functions available only to the test module for testing
if (process.env.NODE_ENV === 'test') {
    exports.determineReminderUpdateAction = determineReminderUpdateAction;
    exports.getFlowRelationshipToWorkHours = getFlowRelationshipToWorkHours;
    exports.getScheduleBasedOnCurrentReminder = getScheduleBasedOnCurrentReminder;
    exports.getScheduleBasedOnFlowTime = getScheduleBasedOnFlowTime;
}

export default exports;
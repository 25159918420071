import { useState, createContext, useContext } from "react";
import UserIcon from "../images/user-icon.svg";
import ExclamationMark from "../images/exclamation-point.svg"
import "./css/account-circle.css";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LogoutButton from "./LogoutButton";
import { getAccountManagementPortalUrl } from "../adapters/payments-adapter";
import { CurrentMetadataForUser } from "../context/CurrentMetadataForUser";
import { MailtoLink } from "./MailtoLink";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";

// Using context for the Account Dropdown so we can use it at app level...
// Used solely for the behavior to close the dropdown when anywhere else on the app is clicked
export const AccountDropdownContext = createContext();

export const AccountDropdownContextProvider = ({ children }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    return (
        <AccountDropdownContext.Provider value={{ showDropdown, setShowDropdown }}>
            {children}
        </AccountDropdownContext.Provider>
    );
};

export const AccountCircle = () => {
    const { showDropdown, setShowDropdown } = useContext(AccountDropdownContext);
    const { isUserPaidSubscriber, stripeCustomerId, isOnboardingCompleted } = useContext(CurrentMetadataForUser);
    const history = useHistory();
    const auth = getAuth()
    const [user] = useAuthState(auth);
    const location = useLocation();

    const [accountManagementPortalUrl, setAccountManagementPortalUrl] = useState("#")

    const renewSubscription = async () => {
        history.push("/renew-subscription");
    };

    useEffect(async () => {
        if (showDropdown) {
            const url = await getAccountManagementPortalUrl(stripeCustomerId);
            setAccountManagementPortalUrl(url);
        }
    }, [showDropdown])

    return (
        <div
            className="account-circle-container"
            onClick={(event) => {
                event.stopPropagation();
                // Stop the click anywhere else on the screen from triggering.
                // In App.js the top level div contains an onClick that closes the dropdown if it is open.
                setShowDropdown(!showDropdown);
            }}
        >
            <button className="button-transparent">
                <img src={UserIcon} className="account-circle" alt="👤" />
                {/* Show exclamation mark for past paid users who are not currently paid OR for users that have never paid but they've onboarded
                    AND never when the checkout form is visible (/get-subscription and /renew-subscription screens)*/}
                {
                    ((stripeCustomerId && !isUserPaidSubscriber) || (isOnboardingCompleted && !stripeCustomerId && !isUserPaidSubscriber))
                    &&
                    location.pathname !== "/get-subscription" && location.pathname !== "/renew-subscription"
                    &&
                    <img src={ExclamationMark} className="unsubscribed-alert-icon" />
                }
            </button>
            {showDropdown && (
                <div className="account-settings-dropdown-container">
                    <div className="arrow-up"></div>
                    <div className="dropdown-options-container">
                        <div className="dropdown-options">
                            {isOnboardingCompleted &&
                                <>
                                    <button
                                        className="account-circle-dropdown-button"
                                        onClick={() => history.push("/profile")}
                                    >
                                        Profile
                                    </button>
                                    <hr className="dropdown-linebreak"></hr>
                                </>}
                            {stripeCustomerId && (
                                <>
                                    {isUserPaidSubscriber ?
                                        <a
                                            href={accountManagementPortalUrl}
                                            target="_blank"
                                            rel='noreferrer'
                                            className="account-circle-dropdown-button"
                                        >
                                            Manage Subscription
                                        </a>
                                        :
                                        <button className="account-circle-dropdown-button-unsubscribed" onClick={renewSubscription}>
                                            Renew Subscription
                                        </button>}
                                    <hr className="dropdown-linebreak"></hr>
                                </>
                            )}
                            {// Show subscribe button for users that have never paid but they've onboarded
                                isOnboardingCompleted && !stripeCustomerId && !isUserPaidSubscriber &&
                                <>
                                    <button className="account-circle-dropdown-button-unsubscribed" onClick={() => history.push("/get-subscription")}>
                                        Subscribe&nbsp;Now!
                                    </button>
                                    <hr className="dropdown-linebreak"></hr>
                                </>
                            }

                            <MailtoLink text="Having Issues?" styles="account-circle-dropdown-button" userEmail={user ? user.email : ""} />
                            <hr className="dropdown-linebreak"></hr>
                            <LogoutButton />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

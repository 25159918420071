import React from 'react';
import { useState, useEffect } from 'react';
import { getEntries } from '../../adapters/flowentry/flowentry-adapter';
import { CheckinType, CalendarViewTypes, BigPictureCutoffs, JSNumberToMonthFullNameMapping } from '../../Constants';
import { getBackgroundColorByTimeOfDay } from '../../helpers/EntriesHelper';
import { getDateFromQueryParams, getNumWorkFlowsInMonth, getViewTypeFromQueryParams } from '../../helpers/CalendarHelper';
import { CSSTransition } from 'react-transition-group';
import WeekView from './WeekView';
import '../css/mycheckins.css';
import MonthView from './MonthView';
import { useLocation } from 'react-router-dom';
import YearView from './YearView';
import RadioButtonGroup from '../RadioButtonGroup';
import BigPictureCounter from '../BigPictureCounter';
import { updateReminderIfApplicable, isUserNew, didUserOnboardNearMonthEnd } from '../../helpers/RemindersHelper';

function MyCheckins(props) {
    let location = useLocation();

    const [entries, setEntries] = useState(null);
    const [numFlowsToBigPicture, setNumFlowsToBigPicture] = useState(null);
    const [showBigPictureCounter, setShowBigPictureCounter] = useState(false);
    const [currentMonth] = useState(JSNumberToMonthFullNameMapping[new Date().getMonth()]);

    const getInitialAnchorDate = () => {
        // if there are query params passed
        if (location.search) {
            return getDateFromQueryParams(location.search);
        }
        return null;
    }
    const [anchorDate, setAnchorDate] = useState(getInitialAnchorDate());

    const getInitialView = () => {
        // if there are query params passed
        if (location.search) {
            let viewFromQuery = getViewTypeFromQueryParams(location.search)
            return viewFromQuery;
        }
        return CalendarViewTypes.Week.Abbrv;
    }
    const [view, setView] = useState(getInitialView());

    useEffect(() => {
        const userId = props.user.uid;
        const requestParams = {
            collectionName: userId,
            entryType: CheckinType.AnyCheckin
        };
        getEntries(requestParams).then((result) => {
            if (result) {
                setEntries(result.sort((a, b) => a.date - b.date)); // sorted in ascending order;
                const numWorkFlowsInMonth = getNumWorkFlowsInMonth(result, new Date());
                setNumFlowsToBigPicture(BigPictureCutoffs.Month - numWorkFlowsInMonth);
            }
        });
    }, [props.user])

    const isUserPostFlow = () => {
        // as of now, this state is only be present when a user is post flow.
        if (location && location.state) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (!entries) {
            return;
        }
        if (isUserPostFlow() && props.remindersOn) {
            updateReminderIfApplicable(entries, props.user.uid);
        }
    }, [entries])

    useEffect(() => {
        let viewFromQuery = getViewTypeFromQueryParams(location.search);
        let date = getDateFromQueryParams(location.search);
        if (date && viewFromQuery) {
            if (viewFromQuery !== view) {
                setView(viewFromQuery);
                setAnchorDate(date);
            }
        }
    }, [location])

    /** Accommodating users who might join Normili near the end of the month. 
     * In that case for a less frustrating experience, I want them to see the big picture counter 
     * when the new month begins.
    */
    const shouldNewUserSeeBigPictureCounter = (onboardedDate) => {
        const today = new Date();
        if (!isUserNew(onboardedDate, today)) {
            // console.log('user is not new')
            return true;
        }
        if (!didUserOnboardNearMonthEnd(onboardedDate)) {
            // console.log('not near month end');
            return true;
        }
        // Else when user was onboarded near month end
        const newMonthPostOnboarding = new Date(onboardedDate.getFullYear(), onboardedDate.getMonth() + 1, 1);
        if (today < newMonthPostOnboarding) {
            // console.log('in no show window')
            return false;
        }
        else {
            // console.log('show');
            return true;
        }
    }

    useEffect(() => {
        if (location.state?.isUserPostWorkFlow) {
            if (!shouldNewUserSeeBigPictureCounter(props.onboardedDate)) {
                return;
            }
            if (numFlowsToBigPicture !== null && numFlowsToBigPicture > -1) {
                setShowBigPictureCounter(true);
            }
        }
    }, [location, numFlowsToBigPicture])

    const updateView = (view) => {
        setView(view);
    }

    const updateAnchorDate = (date) => {
        setAnchorDate(date);
    }

    const background = getBackgroundColorByTimeOfDay(props.timeOfDay);
    const style = {
        background: background
    };

    const exitBigPictureCounter = () => {
        setShowBigPictureCounter(false);

        if (location.state?.isUserPostWorkFlow) {
            // reset location's post flow flag
            location.state.isUserPostWorkFlow = null;
        }
        if (numFlowsToBigPicture < 6 && numFlowsToBigPicture > -3) {
            // As they get closer to unlocking the big picture and a few flows after they have, show them month view.
            updateView(CalendarViewTypes.Month.Abbrv)
        }
    }

    let viewComponent = <></>;
    if (view === CalendarViewTypes.Week.Abbrv) {
        viewComponent = <WeekView entries={entries} anchorDate={anchorDate} updateAnchor={updateAnchorDate} />
    }
    else if (view === CalendarViewTypes.Month.Abbrv) {
        viewComponent = <MonthView entries={entries} anchorDate={anchorDate} updateAnchor={updateAnchorDate} timeOfDay={props.timeOfDay} />
    }
    else {
        viewComponent = <YearView entries={entries} anchorDate={anchorDate} updateAnchor={updateAnchorDate} timeOfDay={props.timeOfDay} />
    }

    return (
        <CSSTransition
            in={true}
            timeout={500}
            classNames={'my-checkins-page'}
            appear={true}
        >
            <div className='my-checkins-page' style={style}>
                {showBigPictureCounter ?
                    <BigPictureCounter
                        numFlowsRemaining={numFlowsToBigPicture}
                        month={currentMonth}
                        exit={exitBigPictureCounter}
                    />
                    : entries &&
                    <>
                        <RadioButtonGroup values={Object.values(CalendarViewTypes).map(type => type.Abbrv)}
                            selection={view}
                            handleChange={updateView}
                        />
                        {viewComponent}
                    </>
                }
            </div>
        </CSSTransition>
    )
}

export default MyCheckins
const server = process.env.REACT_APP_SERVER;

export async function getWorkEnergyDistribution(userId, startTime=null, endTime=null) {
    try {
        let reqParams = {
            collectionName: userId,
        };
        if (startTime && endTime) {
            reqParams['from'] = startTime;
            reqParams['to'] = endTime;
        }
        const response = await fetch(`${server}/workEnergyDistribution?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getEnergyDistribution');
    }
}

export async function getFeelingDistribution(userId, startTime=null, endTime=null) {
    try {
        let reqParams = {
            collectionName: userId,
        };
        if (startTime && endTime) {
            reqParams['from'] = startTime;
            reqParams['to'] = endTime;
        }
        const response = await fetch(`${server}/feelingDistribution?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getFeelingDistribution');
    }
}

export async function getWorkFeelingDistribution(userId, startTime=null, endTime=null) {
    try {
        let reqParams = {
            collectionName: userId,
        };
        if (startTime && endTime) {
            reqParams['from'] = startTime;
            reqParams['to'] = endTime;
        }
        const response = await fetch(`${server}/workFeelingDistribution?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getWorkFeelingDistribution');
    }
}

export async function getCauseDistribution(userId, startTime=null, endTime=null) {
    try {
        let reqParams = {
            collectionName: userId,
        };
        if (startTime && endTime) {
            reqParams['from'] = startTime;
            reqParams['to'] = endTime;
        }
        const response = await fetch(`${server}/causeDistribution?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, 'getCauseDistribution');
    }
}

export async function getUpliftingCausesForCategory(userId, category, startTime=null, endTime=null) {
    try {
        let reqParams = {
            collectionName: userId,
            category: category
        };
        if (startTime && endTime) {
            reqParams['from'] = startTime;
            reqParams['to'] = endTime;
        }
        const response = await fetch(`${server}/upliftingCausesForCategory?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, `getUpliftingCausesByCategory : ${category}`);
    }
}

export async function getDepletingCausesForCategory(userId, category, startTime=null, endTime=null) {
    try {
        let reqParams = {
            collectionName: userId,
            category: category
        };
        if (startTime && endTime) {
            reqParams['from'] = startTime;
            reqParams['to'] = endTime;
        }
        const response = await fetch(`${server}/depletingCausesForCategory?${new URLSearchParams(reqParams)}`);
        const result = await response.json();
        return result;
    }
    catch (e) {
        console.error(e, `getDepletingCausesByCategory : ${category}`);
    }
}
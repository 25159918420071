import React from 'react';
import './css/toggle.css';

function Toggle(props) {
    return (
        <div className='toggle'>
            <label id={props.name} className="switch">
                <input id={props.name} type="checkbox"
                    checked={props.checked}
                    onChange={e => props.onChange(e.target.checked)}
                    disabled={!props.allowToggling} />
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default Toggle;
import styles from "../css/auth/auth-landing-screen.module.css";
import background from "../../images/signup-page-background.svg";
import Underline from "../../images/underline.svg";
import logo from "../../images/logo_red.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { AccountCircle } from "../AccountCircle";

export const AuthLandingPage = ({ children }) => {
    const auth = getAuth();
    const [user] = useAuthState(auth);

    return (
        <div className={styles.page}>
            <header className={styles.header}>
                <Link to="/" className={styles.brandContainer}>
                    <img src={logo} className={styles.logo} alt="Normili Logo"></img>
                    <div className={styles.brandTitle}>Normili</div>
                </Link>
                {user &&
                    <div style={{ marginLeft: "auto" }}>
                        <AccountCircle />
                    </div>
                }
            </header>
            <img src={background} className={styles.background} alt=""></img>
            <div className={styles.hideRoad}></div>
            <div className={styles.contentContainer}>
                <div className={styles.heading}>
                    <div className={styles.primaryHeadingContainer}>
                        <h1 className={styles.headingText}>Not today, Burnout.</h1>
                        <h1 className={styles.headingText}>Never again.</h1>
                    </div>
                    <div className={styles.subheadingContainer}>
                        <h2 className={styles.subheadingText}>
                            Stay on top of your mental health at work.
                        </h2>
                    </div>
                    <div className={styles.subheadingContainer}>
                        <h2 className={styles.subheadingText}>
                            Leverage data to make the right choices for you.
                        </h2>
                        <img
                            src={Underline}
                            className={`${styles.underline} ${styles.underlineSubheadingTwo}`}
                        />
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <div className={styles.authCard}>
                        {children}
                        {/* children will either be an auth or payment component */}
                    </div>
                </div>
            </div>
        </div>
    );
};

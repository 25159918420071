import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getMonth, getMonthHeading, 
    getEntriesForMonth, getEntriesForWeek, getDateFromQueryParams, getViewTypeFromQueryParams} from '../../helpers/CalendarHelper';
import { getDateFromMongoId } from '../../helpers/EntriesHelper';
import Weekdates from './Weekdates';
import Weekdays from './Weekdays';
import Prev from '../Prev';
import Next from '../Next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CheckinsHeading from './CheckinsHeading';
import CheckinsList from './CheckinsList';
import BigPictureButton from '../bigPicture/BigPictureButton';
import { CalendarViewTypes, BigPictureCutoffs } from '../../Constants';

function MonthView(props) {
    let history = useHistory();
    let route = useRouteMatch();
    let location = useLocation();

    const today = new Date();
    const date = props.anchorDate ?? today;
    const dateOfFirstCheckinEver = props.entries && props.entries.length > 0 ? getDateFromMongoId(props.entries[0]._id) : new Date(2050);
    const [monthStart, setMonthStart] = useState(() => new Date(date.getFullYear(), date.getMonth(), 1));
    const [currentMonth, setCurrentMonth] = useState(getMonth(date));
    const [clickState, setClickState] = useState(null);
    const [monthEntries, setMonthEntries] = useState(null);
    const [showBigPictureButton, setBigPictureButtonDisplay] = useState(false);

    const updateQueryParams = (date, replace=false) => {
        const dateStr = date.toDateString().replace(/\s+/g, '-').toLowerCase();
        const urlWithQueryParams = `${route.url}?view=M&date=${dateStr}`;
        if (replace) {
          history.replace(urlWithQueryParams)
        }
        else {
          history.push(urlWithQueryParams);
        }
    }

    const getFirstSundayInMonth = (dateInMonth) => {
        let date = dateInMonth ?? monthStart;
        const weekdayNum = date.getDay();
        if (weekdayNum === 0) {
            return date;
        }
        const deltaFromSunday = 7 - weekdayNum;
        const firstSundayInMonth = new Date(date.getFullYear(), 
            date.getMonth(), date.getDate() + deltaFromSunday);
        return firstSundayInMonth;
    }

    useEffect(() => {
        let view = getViewTypeFromQueryParams(location.search);
        let firstSundayInMonth = getFirstSundayInMonth();
        let anchorDate = today.getMonth() === monthStart.getMonth() ? today : firstSundayInMonth;
        if (view === CalendarViewTypes.Week.Abbrv || view === CalendarViewTypes.Year.Abbrv) {
            updateQueryParams(anchorDate);
        }
        else {
            updateQueryParams(anchorDate, true);
        }
    }, []);

    useEffect(() => {
        let firstSundayInMonth = getFirstSundayInMonth();
        let anchorDate = today.getMonth() === monthStart.getMonth() ? today : firstSundayInMonth;
        props.updateAnchor(anchorDate);
        if (props.entries !== null) 
        {
            let entriesForMonth = getEntriesForMonth(props.entries, monthStart);
            setMonthEntries(entriesForMonth);
        }
    }, [monthStart, props.entries])

    useEffect(() => {
        //find how many entries are work related.
        if (monthEntries && monthEntries.length > 0) {
            const workEntries = monthEntries.filter(entry => (entry.isDirectlyWorkRelated === true));
            if (workEntries.length >= BigPictureCutoffs.Month) {
                setBigPictureButtonDisplay(true);
            }
            else {
                setBigPictureButtonDisplay(false)
            }
        }
    }, [monthEntries])

    // To make sure the month displayed matches the query params.
    useEffect(() => {
        let query = location.search;
        let date = getDateFromQueryParams(query);
        if (date) {
            let monthFromQuery = date.getMonth();
            let newMonthStart = new Date(date.getFullYear(), monthFromQuery, 1);
            let newMonthToDisplay = getMonth(newMonthStart);
            if (monthFromQuery < monthStart.getMonth()) {
                setClickState('prev');
                setCurrentMonth(newMonthToDisplay);
                setMonthStart(newMonthStart);
            }
            else if (monthFromQuery > monthStart.getMonth()) {
                setClickState('next');
                setCurrentMonth(newMonthToDisplay);
                setMonthStart(newMonthStart);
            }
            else {
                // do nothing when month is same as the date in query params
            }
        }
    },[location])

    const handlePrevClick = () => {
        let currentYear = monthStart.getFullYear();
        let prevMonthNum = monthStart.getMonth() - 1;
        let prevMonthStartDate = new Date(currentYear, prevMonthNum, 1);
        let newAnchorDate = getFirstSundayInMonth(prevMonthStartDate);
        setCurrentMonth(getMonth(prevMonthStartDate));
        setMonthStart(prevMonthStartDate);
        updateQueryParams(newAnchorDate);
    }

    const handleNextClick = () => {
        let currentYear = monthStart.getFullYear();
        let nextMonthNum = monthStart.getMonth() + 1;
        let nextMonthStartDate = new Date(currentYear, nextMonthNum, 1);
        let newAnchorDate = getFirstSundayInMonth(nextMonthStartDate);
        setCurrentMonth(getMonth(nextMonthStartDate));
        setMonthStart(new Date(currentYear, nextMonthNum, 1));
        updateQueryParams(newAnchorDate);
    }

    const isPrevVisible = () => {
        // Setting default to not go before July 2022. TODO: Otherwise it can be the user's registration week.
        let lastDateToBeShown = new Date(2022, 6, 3);
        if (dateOfFirstCheckinEver < lastDateToBeShown) {
            lastDateToBeShown = dateOfFirstCheckinEver;
        }
        if (monthStart <= lastDateToBeShown) {
            return false;
        }
        return true;
    }

    const isNextVisible = () => {
        // don't go beyond current month
        let monthToday = today.getMonth();
        if (monthToday === monthStart.getMonth()) {
            return false;
        }
        return true;
    }

    const getQueryParamsForEagleViz = () => {
        const startTime = monthStart.getTime() / 1000 | 0; //convert to seconds
        const endDate = new Date(monthStart.getFullYear(), monthStart.getMonth() + 1, 1); // 12:00 am first day of next month
        let endTime = endDate.getTime() / 1000 | 0;
        return `view=${CalendarViewTypes.Month.Abbrv}&start=${startTime}&end=${endTime}`;
    }

    return (
        // p.s. reusing css classes from week view
        <div className='weekview'> 
            <div className='week-nav-div'>
                <Prev visible={isPrevVisible()}
                    viewBox='40 20 80 80' height='45' width='45' strokeWidth='4' 
                    onClick={() => { setClickState('prev'); handlePrevClick(); }}
                />
                <TransitionGroup>
                  <CSSTransition
                  key={currentMonth}
                  exit={false} //Stops any exit animation classes from being applied
                  timeout={500}
                  classNames={clickState === 'next' ? 'slide-next' : 'slide-prev'}>
                    <p className='week-heading'>{getMonthHeading(currentMonth[1][0])}</p>
                  </CSSTransition>
                </TransitionGroup>
                <Next visible={isNextVisible()} 
                    viewBox='-20 20 80 80' height='45' width='45' strokeWidth='4'
                    onClick={() => { setClickState('next'); handleNextClick(); }}
                />         
            </div>
            <div className='week-div'>
                <Weekdays/>
                {currentMonth.map((week, i) => {
                    let startDate = typeof(week[0]) !== 'string' ? week[0] : monthStart;
                    let weekEntries = props.entries && getEntriesForWeek(props.entries, startDate);
                    return <Weekdates key={i}
                        currentWeek={week} entries={weekEntries} clickState={clickState}/>
                }
                )}
            </div>
            <CheckinsHeading numFlows={monthEntries?.length} startDate={monthStart} view={CalendarViewTypes.Month.Name}/>
            { showBigPictureButton &&
                <BigPictureButton queryParams={getQueryParamsForEagleViz()} flows={monthEntries}/>
            }
            <CheckinsList entries={monthEntries} clickState={clickState}/>
        </div>
    )
}

export default MonthView;
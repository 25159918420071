import { IS_STRIPE_IN_LIVEMODE } from "../Constants";

const server = process.env.REACT_APP_SERVER;

export async function saveUserMetadataToFirebase(userMetadata, accessToken) {
    try {
        const jsonBody = JSON.stringify({
            userMetadata: userMetadata,
            idToken: accessToken,
            isUserInTestEnvironment: !IS_STRIPE_IN_LIVEMODE, // Optional value, defaults to use test environment
        });

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: jsonBody,
        };

        const response = await fetch(`${server}/usermetadata`, requestOptions);
        return response;
    }
    catch (e) {
        console.error(e, "saveUserMetadataToFirebase");
    }
}

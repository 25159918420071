import React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { spread } from 'largest-remainder-round';
import handClick from '../../images/handClickOnce.gif';
import '../css/piechart.css';
import InsightHeading from './InsightHeading';

function PieChart(props) {
    const svgRef = useRef();
    const [data] = useState(props.data.spread({prop: 'count', spreadTo: 'percentage'}));
    const [showClickHint, setClickHintDisplay] = useState(false);
    
    //#region helper functions
    const getColorSet = () => {
        let colorSet = [];
        props.data.forEach(element => {
            const color = props.colorSet[element._id];
            colorSet.push(color);
        });
        return colorSet ?? d3.schemeSet2;
    }

    const calculateLegendSpacing = (nodes, maxElementsInRow, totalSpace) => {
        let spaceOccupied = 0;
        nodes.slice(0, maxElementsInRow).forEach(node => {
            spaceOccupied = spaceOccupied + node.getBBox().width;
        });
        const availableSpace = totalSpace - spaceOccupied;
        // note: subtracting 1 because say there are 4 elements, then only 3 of them are spaced as the first element isn't
        const elementOffset = availableSpace/Math.min(nodes.length-1, maxElementsInRow-1);
        return elementOffset;
    }

    const calculateLegendSecondRowStartPosition = (nodes, 
        maxElementsInRow,
        maxLegendWidth,
        defaultStartPos,
        spacing) => 
        {
        let extraSpace = maxLegendWidth;
        nodes.splice(maxElementsInRow).forEach((node, i) => {
            extraSpace = extraSpace - (node.getBBox().width + spacing);
        })
        return defaultStartPos + (extraSpace + spacing)/2; // why add spacing? because in the loop above we subtracted spacing after the last element also
    }
    //#endregion

    useEffect(() => {

        // Step 1: Set up svg container
        const width = 400;
        const height = 400;
        const radius = width/2.5;
        const svg = d3.select(svgRef.current)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('class', 'pie-svg')
            .attr('viewBox', `${-width/2} ${-height/2} ${width} ${height}`)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .style('overflow', 'visible')
        
        const svgWidth = svg.node().getBoundingClientRect().width;
        const offsetBaseline = Math.min(width, svgWidth);

        // Step 2: Set up the chart
        const formattedData = d3.pie().value(d => d.count)(data);
        const arcGenerator = d3.arc().innerRadius(0).outerRadius(radius);
        const color = d3.scaleOrdinal().range(getColorSet());

        // #region create legends
        const legend = svg.append('g')
            .attr('class', 'legend')
            .attr('x', 0)
            .attr('y', 0)

        const yPosLegend = -(radius + 45);
        const startXPosLegend = 0
        let xPositions = [0, 0, 0, 0, 0, 0];
        const lg = legend.selectAll('g')
            .data(formattedData)
            .enter()
            .append('g')

        lg.append('rect')
            .style('fill', (d,i) => color(i))
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 20)
            .attr('height', 20)
        
        lg.append('text')
            .text(d => d.data._id)
            .attr('dx', '2em')
            .attr('dy', '0.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7')
        
        const nodes = lg.nodes();
        const maxElementsInRow = 4;
        const spacing = calculateLegendSpacing(nodes, maxElementsInRow, offsetBaseline);
        // note: max 2 rows by design.
        const numLegendSecondRow = nodes.length - maxElementsInRow;
        lg.attr('transform', (d,i) => {
            let x = xPositions[i];
            let y = i < maxElementsInRow ? yPosLegend : yPosLegend + 35;
            if (i > 0){
                if (i !== maxElementsInRow) {
                    x = xPositions[i-1] + nodes[i-1].getBBox().width + spacing;
                    xPositions[i] = x;
                }
                else {
                    x =  calculateLegendSecondRowStartPosition([...nodes],maxElementsInRow,
                        offsetBaseline, startXPosLegend, spacing);
                    xPositions[i] = x;
                }
            }
            return `translate(${x},${y})`;
        });

        lg.append('text')
            .text(d => `${d.data.percentage}%`)
            .attr('dx', '2em')
            .attr('dy', '1.75em')
            .style('font-size', '13px')
            .style('fill', '#b9b7b7')
        
        //#endregion

        // #region Step 3: Draw the pie
        let pie = svg.append('g')
            .attr('class', 'pie')
            .style('cursor', 'pointer');
        
        pie.selectAll().data(formattedData)
            .join('path')
            .attr('d', arcGenerator)
            .attr('fill', (v,i) => color(i))
            .append('title')
                .text(d => d.data._id);

        //add lines to act as borders for slices.
        var lines = pie.selectAll(null)
            .data(formattedData)
            .enter()
            .append("line")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("y2", function(d) {
              return Math.sin(d.startAngle - Math.PI / 2) * (radius)
            })
            .attr("x2", function(d) {
              return Math.cos(d.startAngle - Math.PI / 2) * (radius)
            })
            .attr("stroke", "white")
            .attr("stroke-width", 1)
        
        //#endregion
        
        // #region Step 4: Set up in pie text labels
        const middlePosition = radius;
        const twoThirdPosition = radius*2 - radius*2/3;

        pie.selectAll()
            .data(formattedData)
            .join('text')
                .text(d => d.data.percentage > 14 ? d.data._id : '')
                .attr('transform', (d,i) => {
                    const outerRadius = d.data.percentage > 49 ? middlePosition : twoThirdPosition; 
                    const arc1 = d3.arc().innerRadius(0).outerRadius(outerRadius);
                    return "translate(" + arc1.centroid(d) + ")";
                })
                .attr('font-size', '1rem')
                .attr('font-weight', 600)
                .style(`text-anchor`, 'middle')
                .style('fill', 'white')
            .append('tspan')
                .text(d => d.data.percentage > 14 
                    ? `${d.data.percentage}%` : '')
                .attr('dy', '1.5em') // moves the % number to a line-and-a-half below
                .attr('x', 0) // centers the % w.r.t the label's position
                .attr('font-size', '0.85em')
                .attr('font-weight', 600)

        // Add on click event handler to all pie slices
        pie.selectAll("path").on("click", function(d) {
            const name = d3.select(this).select('title').text()
            if (props.onClick) {
                props.onClick(name);
            }
        });

        //#endregion
        
        // move pie down if there's an extra row in legend
        svg.select('.pie')
            .attr('transform', (d) => {
                const yOffset = numLegendSecondRow > 0 ? 30 : 0
                return `translate(${[0, yOffset]})`;
            })
        
        svg.select('.legend')
            .attr('transform', `translate(${[-offsetBaseline/2, 0]})`)

        if (data && props.showClickHint) {
            // Show gif after a delay
            const timer = setTimeout(() => {
                setClickHintDisplay(true);
            }, 1900); // Delay in milliseconds

            // Clean up the timer on component unmount
            return () => clearTimeout(timer);
        }
        
    }, [data]);

    useEffect(() => {
        if (showClickHint) {
            const timer = setTimeout(() => {
                setClickHintDisplay(false);
                props.dontShowClickHintAgain();
            }, 3000); // Disappear gif after 3 seconds.

            // Clean up the timer on component unmount
            return () => clearTimeout(timer);
        }
    }, [showClickHint])

    return (
        <div id='chartDiv' style={{position: "relative"}}>
            {showClickHint && <img src={handClick} className="click-hint" alt="Click for details 👆"/>}
            <InsightHeading heading={props.heading} headingColor={props.headingColor} 
                pStyle={{'marginBottom': '1.5rem'}}/>
            <svg ref={svgRef}/>
        </div>
    )
}

export default PieChart;
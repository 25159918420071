import React, { Component } from 'react'
import './css/radio.css'

class Radio extends Component {
    constructor(props) {
        super(props)
        
        this.checkedStyle = {
            "backgroundColor": "rgb(73, 197, 210)"
        }
        let isChecked = props.selectedAnswer && props.selectedAnswer.includes(props.value)
        let initialStyle = isChecked ? this.checkedStyle : {}
        this.state = {
            "checked": isChecked,
            "style": initialStyle
        }
    }
    
    render() {
        return (
            <label>
                <div className={'radio-tile ' + (this.props.defaultSize ? 'default-radio-size' : 'small-radio-size')} 
                style={this.state.style}>
                    <input type='radio'
                        id='radio'
                        name={this.props.questionId}
                        value={this.props.value}
                        onChange={this.props.onAnswered}
                        checked={this.state.checked}
                    />
                    {this.props.value}
                </div>
            </label>
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedAnswer !== this.props.selectedAnswer) {
            let isChecked = this.props.selectedAnswer && this.props.selectedAnswer.includes(this.props.value)
            this.setState({
                "checked": isChecked
            })
            this.updateStyle(isChecked)
        }
    }

    updateStyle = (isChecked) => {
        let style = isChecked ? this.checkedStyle : {}
        this.setState({
            "style": style
        })
    }
}

export default Radio



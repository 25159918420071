import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EntryFlow from "./EntryFlow";
import EbbAndFlow from "./EbbAndFlow";
import { CheckinType } from "../Constants";
import { CSSTransition } from "react-transition-group";
import "./css/anytimecheckin.css";
import { getFlowScreenBGColor } from "../Colors";

function AnytimeCheckin(props) {
  const history = useHistory();
  const [flowCompleted, setFlowCompletion] = useState(false);
  const [isFlowAboutWork, setIsFlowAboutWork] = useState(false);

  const navigateToHomeScreenAfterFlowCompletion = () => {
    history.push("/flows", { isUserPostWorkFlow : isFlowAboutWork });
  };

  const handleEntryFlowCompletion = (isFlowAboutWork=false) => {
    props.updateFlowType(CheckinType.EbbFlow);
    setIsFlowAboutWork(isFlowAboutWork);
    setFlowCompletion(true);
  };

  const handleEbbFlowCompletion = (isFlowAboutWork=false) => {
    setIsFlowAboutWork(isFlowAboutWork);
    setFlowCompletion(true);
  };

  return (
    <CSSTransition
      in={!flowCompleted}
      timeout={250}
      classNames={"checkin"}
      appear={true}
      onExited={navigateToHomeScreenAfterFlowCompletion}
    >
      <div
        className="checkin"
        style={{ background: getFlowScreenBGColor(props.timeOfDay) }}
      >
        {props.flowType === CheckinType.EntryFlow ? (
          <EntryFlow
            timeOfDay={props.timeOfDay}
            finishEntryFlow={handleEntryFlowCompletion}
            isFlowCompleted={flowCompleted}
            user={props.user}
          />
        ) : (
          <EbbAndFlow
            timeOfDay={props.timeOfDay}
            finishEbbFlow={handleEbbFlowCompletion}
            isFlowCompleted={flowCompleted}
            user={props.user}
          />
        )}
      </div>
    </CSSTransition>
  );
}

export default AnytimeCheckin;

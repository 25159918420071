import React from 'react';
import schedule from '../../images/schedule.svg';
import people from '../../images/people.svg';
import process from '../../images/process.svg';
import work from '../../images/work.svg';

function WorkTags(props) {
    const tagColor = {
        backgroundColor: props.color
    }
    const tagText = props.tagText ?? "Work related";

    return (
        <span className='card-tag' style={tagColor}>
            <span className='work-tag'>{tagText}</span>
            {
                props.workCauses.includes('People') &&
                <span className='work-tag-icon-container'><img src={people} className='work-cause-icon'/></span>
            }
            {
                props.workCauses.includes('My schedule') &&
                <span className='work-tag-icon-container'><img src={schedule} className='work-cause-icon'/></span>
            }
            {
                props.workCauses.includes('The work itself') &&
                <span className='work-tag-icon-container'><img src={work} className='work-cause-icon'/></span>
            }
            {
                props.workCauses.includes('Processes / Policies') &&
                <span className='work-tag-icon-container'><img src={process} className='work-cause-icon'/></span>
            }
        </span>
    )
}

export default WorkTags
import React, { useEffect, useRef, useState } from 'react';
import styles from './css/timezone-input.module.css';

const TimezoneRadioButtons = ({ timezone, setTimezone }) => {
    const timezones = [
        'PST', // Pacific Standard Time
        'EST', // Eastern Standard Time
        'CST', // Central Standard Time
        'MST', // Mountain Standard Time
        'HST', // Hawaii-Aleutian Standard Time
        'AKST', // Alaska Standard Time
    ];
    // #region Dynamic Rounded Corners
    /**
     * This code is to use a rounded corner (border radius) for first and last element on each row when it is resized for smaller screens.
     * This effect was generated by GPT and makes this component appear more complex than it is functionally.
     */

    const buttonRefs = useRef([]);
    const [rowIndices, setRowIndices] = useState([]);

    useEffect(() => {
        const calculateRowIndices = () => {
            const indices = [];
            let currentRowTop = null;
            buttonRefs.current.forEach((btn, index) => {
                const top = btn.getBoundingClientRect().top;
                if (top !== currentRowTop) {
                    indices.push(index);
                    currentRowTop = top;
                }
            });
            setRowIndices(indices);
        };

        calculateRowIndices();
        window.addEventListener('resize', calculateRowIndices);
        return () => window.removeEventListener('resize', calculateRowIndices);
    }, [buttonRefs.current]);

    const getBorderRadius = (index) => {
        const isFirstInRow = rowIndices.includes(index);
        const isLastInRow = rowIndices.includes(index + 1) || index === timezones.length - 1;
        return {
            borderTopLeftRadius: isFirstInRow ? '1rem' : '0rem',
            borderBottomLeftRadius: isFirstInRow ? '1rem' : '0rem',
            borderTopRightRadius: isLastInRow ? '1rem' : '0rem',
            borderBottomRightRadius: isLastInRow ? '1rem' : '0rem',
        };
    };

    const getBorderWidth = (index) => `0.1rem ${rowIndices.includes(index + 1) || index === timezones.length - 1 ? '0.1rem' : '0.05rem'} 0.1rem ${rowIndices.includes(index) ? '0.1rem' : '0.05rem'}`;
    // #endregion

    return (
        <div className={styles.radioBtnGroup}>
            {timezones.map((tz, index) => (
                <label
                    key={index}
                    ref={(el) => (buttonRefs.current[index] = el)}
                    className={styles.radioGroupBtn}
                    style={{
                        borderColor: 'rgba(255, 255, 255, 0.8)',
                        borderWidth: getBorderWidth(index),
                        borderRadius: `${getBorderRadius(index).borderTopLeftRadius} ${getBorderRadius(index).borderTopRightRadius} ${getBorderRadius(index).borderBottomRightRadius} ${getBorderRadius(index).borderBottomLeftRadius}`,
                        backgroundColor: timezone === tz ? 'rgb(73, 197, 210)' : 'transparent',
                    }}
                >
                    <input
                        type="radio"
                        id={tz}
                        name="timezones"
                        value={tz}
                        checked={timezone === tz}
                        onChange={() => setTimezone(tz)}
                        className={styles.hiddenInput}
                    />
                    {tz}
                </label>
            ))}
        </div>
    );
};

export default TimezoneRadioButtons;

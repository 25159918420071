import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import styles from "../css/auth/forgot-password-reset.module.css";
import { FloatingLabelInput } from "./FloatingLabelInput";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MailtoLink } from "../MailtoLink";

export const ForgotPassword = () => {
    const auth = getAuth();

    const [email, setEmail] = useState("");
    const [resetRequestSubmitted, setResetRequestSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email);
        setResetRequestSubmitted(true);
    };

    return (
        <form
            onSubmit={(e) => {
                handleSubmit(e);
            }}
            className={styles.formContainer}
        >
            <h1 className={styles.title}>Forgot Password?</h1>
            <div>
                <p className={styles.primaryText}>
                    {resetRequestSubmitted
                        ? "Password reset instructions have been sent"
                        : "Enter the email for your account"}
                </p>

                {!resetRequestSubmitted && (
                    <div>
                        <FloatingLabelInput
                            id="forgot-email-input"
                            type="email"
                            value={email}
                            required={true}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                        />
                    </div>
                )}
            </div>

            {resetRequestSubmitted ? (
                <Link to="/" className={styles.button}>
                    Ok
                </Link>
            ) : (
                <div>
                    <input
                        type="submit"
                        value="Reset Password"
                        onSubmit={(e) => handleSubmit(e)}
                        className={styles.button}
                    />
                    <div className={styles.havingIssuesContainer}>
                        <span>Having Issues? </span>
                        <MailtoLink
                            to="hello@normili.com"
                            userEmail={email}
                            emailSubject={"Help Resetting Password"}
                            emailBody={`I'm having trouble resetting my password for ${email}`}
                            text="Contact support"
                        />
                    </div>
                </div>
            )}
        </form>
    );
};
import './css/onboarding.css';
import React, { useState, useContext } from 'react';
import { CurrentMetadataForUser } from "../context/CurrentMetadataForUser";
import { AppBGColors, GreetingColors, AppColorTheme } from '../Colors';
import PrevMobile from './PrevMobile';
import RadioButtonGroup from './RadioButtonGroup';
import { createOrUpdateProfile } from '../adapters/profile/profile-adapter';
import { getScheduleExpForNextBizDay } from '../helpers/RemindersHelper';
import { createReminderSchedule } from '../adapters/reminders-adapter';
import { ListWithIcon } from './ListWithIcon';
import { PhoneNumberInput, isPhoneNumberValid } from './PhoneNumberInput';
import { TimezoneMap } from '../Constants';

function Onboarding(props) {
    const backgroundColor = AppBGColors[props.timeOfDay];
    const windowColor = GreetingColors[props.timeOfDay].background;
    const buttonTextColor = AppColorTheme[props.timeOfDay].DARK;

    const [display, setDisplay] = useState(1);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Client side timezone
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [role, setRole] = useState('');
    const [event, setEvent] = useState('');
    const [eventTime, setEventTime] = useState("11:00");
    const [eventAnchor, setEventAnchor] = useState('');
    const [pastHurdle, setPastHurdle] = useState('');
    const [futureMessage, setFutureMessage] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isProfileSaved, setProfileSaveStatus] = useState(false);
    const [isReminderScheduleCreated, setScheduleCreationStatus] = useState(false);
    const [phoneNumberSaveError, setPhoneNumberSaveError] = useState(false);
    const { setCurrentMetadataForUser } = useContext(CurrentMetadataForUser);

    const finishOnboarding = () => {
        if (!isProfileSaved) {
            saveProfile();
        }
        if (phoneNumber && !isReminderScheduleCreated) {
            scheduleRemindersForUser();
        }
        props.finishOnboarding();
    }

    const saveProfile = () => {
        const profile = {
            userId: props.userId,
            type: 'profile',
            name: name,
            companyName: company,
            role: role,
            phone: phoneNumber,
            timezone: TimezoneMap[timezone], // In profile we use abbreviations (PST, EST, etc.)
            habitEvent: event,
            eventAnchor: eventAnchor,
            eventTime: eventTime,
            pastHurdle: pastHurdle,
            futureMessage: futureMessage,
        };

        //Make API call to save profile
        createOrUpdateProfile(profile).then(async response => {
            if (response.ok) {
                // const message = await response.text();
                // console.log(message);
                setProfileSaveStatus(true);
            }
            else {
                // TODO: Show error message on UI
                const message = await response.text();
                console.error(message);
            }
        });
    }

    const scheduleRemindersForUser = () => {
        if (!phoneNumber || !isPhoneNumberValid(phoneNumber)) {
            setCurrentMetadataForUser({ remindersOn: false });
            return;
        }

        const scheduleExpression = getScheduleExpForNextBizDay(new Date(), eventTime);
        const schedule = {
            "Name": `rule${props.userId}`,
            "Description": `For ${name}`,
            "ScheduleExpression": scheduleExpression,
            "ScheduleExpressionTimezone": timezone,
            "State": 'ENABLED',
            "Input": {
                "userId": props.userId,
                "phone": `+1${phoneNumber}`,
                "message": `Hi ${name}, Feel to find balance 🌻`,
                "reminderIndex": -1
            },
            "FlexibleTimeWindow": {
                "Mode": "OFF"
            }
        }
        createReminderSchedule(schedule).then(async response => {
            if (response.ok) {
                // const message = await response.text();
                // console.log(message);
                setScheduleCreationStatus(true);
                setCurrentMetadataForUser({ remindersOn: true });
            }
        });
    }

    return (
        <div className="main-content" style={{ background: backgroundColor }}>
            <div className='onboarding-window' style={{ background: windowColor }}>
                {
                    display === 1 &&
                    <NameInput
                        next={() => (setDisplay(display + 1))}
                        back={() => setDisplay(display - 1)}
                        name={name}
                        setName={setName}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 2 &&
                    <CompanyAndRoleInfo
                        company={company}
                        setCompany={setCompany}
                        role={role}
                        setRole={setRole}
                        name={name}
                        next={() => setDisplay(display + 1)}
                        back={() => setDisplay(display - 1)}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 3 &&
                    <FlowsInfo
                        name={name}
                        next={() => setDisplay(display + 1)}
                        back={() => setDisplay(display - 1)}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 4 &&
                    <HabitEvent
                        next={() => setDisplay(display + 1)}
                        back={() => setDisplay(display - 1)}
                        event={event}
                        eventAnchor={eventAnchor}
                        setEvent={setEvent}
                        setEventAnchor={setEventAnchor}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 5 &&
                    <HabitTime
                        next={() => setDisplay(display + 1)}
                        back={() => setDisplay(display - 1)}
                        eventTime={eventTime}
                        setEventTime={setEventTime}
                        event={event}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 6 &&
                    <ActionPlan
                        next={() => setDisplay(display + 1)}
                        back={() => setDisplay(display - 1)}
                        event={event}
                        eventAnchor={eventAnchor}
                        eventTime={eventTime}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 7 &&
                    <PastWisdom
                        next={() => setDisplay(display + 1)}
                        back={() => setDisplay(display - 1)}
                        pastHurdle={pastHurdle}
                        setPastHurdle={setPastHurdle}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 8 &&
                    <MessageForFuture
                        next={() => setDisplay(display + 1)}
                        back={() => setDisplay(display - 1)}
                        name={name}
                        futureMessage={futureMessage}
                        setFutureMessage={setFutureMessage}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 9 &&
                    <VIPReminders
                        next={() => {
                            setDisplay(display + 1);
                            saveProfile();
                            scheduleRemindersForUser();
                        }}
                        back={() => setDisplay(display - 1)}
                        name={name}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setPhoneNumberSaveError={setPhoneNumberSaveError}
                        phoneNumberSaveError={phoneNumberSaveError}
                        buttonTextColor={buttonTextColor}
                    />
                }
                {
                    display === 10 &&
                    <OnboardingWrapUp
                        back={() => setDisplay(display - 1)}
                        name={name}
                        onClick={finishOnboarding}
                        buttonTextColor={buttonTextColor}
                    />
                }
            </div>
        </div>
    )
}

export default Onboarding;

const backButtonStyles = {
    color: "#ffffffeb",
    borderColor: "#ffffff6e",
    margin: "0 0 1rem 0"
};

function NameInput(props) {
    return (
        <>
            <div></div>  {/* Empty div used for centering page content */}
            <div>
                <h1 className='onboarding-greeting'>Welcome to Normili!</h1>
                <h2 className='onboarding-subgreeting' style={{ margin: "1rem 2rem" }}>What do your friends call you?</h2>
                <input type='text' value={props.name} className='text-input skinny-input' onChange={e => props.setName(e.target.value)} />
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }}
                disabled={!props.name || (props.name.trim() === '')} onClick={props.next}>
                NEXT
            </button>
        </>
    )
}

function CompanyAndRoleInfo(props) {
    let isButtonInactive = !props.company || !props.role || (props.company.trim() === '') || (props.role.trim() === '');
    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h1 className='onboarding-subgreeting waking-hours-text'>
                    We spend 50% of our waking hours at work, {props.name}</h1>
                <h2 className='onboarding-subgreeting' style={{ margin: '0.5rem', maxWidth: '17rem' }}>
                    What is that place for you?
                </h2>
                <div className='inputs-container'>
                    <input type='text' placeholder='I work at...' value={props.company} className='text-input skinny-input'
                        onChange={e => props.setCompany(e.target.value)} />
                    <input type='text' placeholder='My role is...' value={props.role} className='text-input skinny-input'
                        onChange={e => props.setRole(e.target.value)} />
                </div>
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }}
                disabled={isButtonInactive} onClick={props.next}>
                CONTINUE
            </button>
        </>
    )
}

function FlowsInfo(props) {
    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 0.2rem" }}>
                <p className='onboarding-subgreeting' style={{ margin: "0.5rem 0.5rem 1.5rem", fontWeight: "bold", maxWidth: "21rem" }}>
                    Flows are a compassionate way to keep your mental health front and center at work.
                </p>
                <p style={{ fontSize: '1.2rem', marginBottom: '1.5rem', maxWidth: "20rem" }}>
                    With a quick series of prompts:
                </p>
                <div style={{ maxWidth: "17rem" }}>
                    <ListWithIcon iconPath=""
                        iconAltText="⚡️"
                        list={[
                            "Identify how you're feeling",
                            "Pinpoint work's impact",
                            "Recognize what you need to get back to balance."
                        ]}
                        externalCSS={{
                            text: { fontSize: "1.2rem" }
                        }}
                    />
                </div>
            </div>
            <button className='onboarding-btn' onClick={props.next} style={{ color: props.buttonTextColor }}>
                HARNESS INNER WISDOM
            </button>
        </>
    )
}

function HabitEvent(props) {
    let isButtonInactive = !props.eventAnchor || !props.event || props.event.trim() === '';

    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 0.2rem" }}>
                <h1 className='onboarding-subgreeting' style={{ fontWeight: "bold", margin: "0 0 1.5rem", maxWidth: '18rem' }}>
                    Think about your workday. When would it make sense for you to Flow?
                </h1>
                <p style={{ maxWidth: '18rem', marginBottom: "2rem" }}>
                    Pick an event that happens <b>every workday</b>. Tie this new habit to that.
                </p>
                <p style={{ color: "rgba(255, 255, 255, 0.7)", marginBottom: 0 }}>
                    I will take a flow...
                </p>
                <RadioButtonGroup values={['Before', 'During', 'After']}
                    selection={props.eventAnchor}
                    handleChange={updatedValue => props.setEventAnchor(updatedValue)}
                    customStyles={{ color: "white", fontSize: "1rem", padding: "0.1rem 0.75rem", marginBottom: "0.5rem" }}
                />
                <input type='text' placeholder='A specific event' value={props.event} className='text-input skinny-input'
                    onChange={e => props.setEvent(e.target.value)}
                    style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }} onClick={props.next}
                disabled={isButtonInactive}>
                VISUALIZING IT
            </button>
        </>
    )
}

function HabitTime(props) {

    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 0.2rem 2rem" }}>
                <h1 className='onboarding-subgreeting' style={{ margin: "0 0 2.5rem", maxWidth: '18rem' }}>
                    Around what time does <span style={{ fontWeight: "bold" }}>
                        {props.event.toLowerCase() ?? "that event"}</span> happen each day?
                </h1>
                <input required type="time" className='time-dropdown'
                    defaultValue={props.eventTime} onChange={e => props.setEventTime(e.target.value)} />
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }} onClick={props.next}
                disabled={!props.eventTime}>
                BREW ACTION PLAN
            </button>
        </>
    )
}

function ActionPlan(props) {
    const convertTimeTo12HourFormat = (time) => {
        let [hours, minutes] = time.split(':');
        let period = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12; // Convert hour to 12-hour format, and replace '0' with '12'
        return `${hours}:${minutes}${period}`;
    }

    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 0.3rem 2rem" }}>
                <h1 className='onboarding-subgreeting' style={{ fontWeight: "bold", margin: "0 0 2.5rem", maxWidth: '20rem' }}>
                    Here's your action plan for success! 👇
                </h1>
                <p className='onboarding-subgreeting' style={{ maxWidth: "21rem", margin: 0 }}>
                    I will take a{" "}<u><i>
                        {`flow ${props.eventAnchor.toLowerCase()} ${props.event.toLowerCase()} `}
                        around {convertTimeTo12HourFormat(props.eventTime)}</i></u> every workday.
                </p>
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }} onClick={props.next}>
                I CAN DO THAT!
            </button>
        </>
    )
}

function PastWisdom(props) {
    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 0.5rem" }}>
                <h1 className='onboarding-subgreeting' style={{ fontWeight: "bold", margin: "0 0 2rem", maxWidth: '18rem' }}>
                    Let's fortify this plan with your past wisdom
                </h1>
                <p className='guider-text'>
                    Think of a mental health habit you've attempted before. What got in your way of making it stick?
                </p>
                <textarea className='journal-input custom-placeholder' style={{ "height": "8rem", width: "95%" }}
                    spellCheck="false" placeholder={"pause and reflect..."}
                    value={props.pastHurdle}
                    onChange={e => props.setPastHurdle(e.target.value)}
                />
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }} onClick={props.next}
                disabled={!props.pastHurdle}>
                LOADING CLARITY
            </button>
        </>
    )
}

function MessageForFuture(props) {
    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 0.5rem" }}>
                 <h1 className='onboarding-subgreeting future-title-text'>
                    What would you advise <b>your future self</b> who has run into those hurdles again?
                </h1>
                <textarea className='journal-input custom-placeholder' style={{ "height": "8rem", width: "95%", }}
                    spellCheck="false" placeholder={"Help them reconnect with why this matters to you..."}
                    value={props.futureMessage}
                    onChange={e => props.setFutureMessage(e.target.value)}
                />
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }} onClick={props.next}
                disabled={!props.futureMessage}>
                I'LL REMEMBER THIS
            </button>
        </>
    )
}

function VIPReminders(props) {
    const savePhoneNumber = () => {
        if (props.phoneNumber && isPhoneNumberValid(props.phoneNumber)) {
            props.setPhoneNumberSaveError(false)
            props.next()
        }
        else {
            props.setPhoneNumberSaveError(true)
        }
    }

    const skipPhoneNumber = () => {
        props.setPhoneNumber('');
        props.setPhoneNumberSaveError(false)
        props.next()
    }
    
    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 0.5rem 1.5rem" }}>
                <h1 className='onboarding-subgreeting' style={{ fontWeight: "bold", margin: "0 0 2rem", maxWidth: '22rem' }}>
                    Nicely done {props.name}! How bout a lil gift from us to future you as well? 🎁
                </h1>
                <p className='guider-text'>
                    When you treat yourself like a VIP, we do too. Enter your digits if you'd like to receive VIP text reminders.
                </p>
                <div style={{ position: 'relative', marginTop: "1rem" }}>
                    <PhoneNumberInput
                        phoneNumber={props.phoneNumber}
                        setPhoneNumber={props.setPhoneNumber}
                    />
                    {props.phoneNumberSaveError &&
                        <p style={{ fontSize: "0.8rem", position: "absolute", bottom: "-2.4rem", left: 0, right: 0 }}>
                            Please enter a valid phone number
                        </p>
                    }
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: '100%' }}>
                <button className='onboarding-btn' style={{ color: props.buttonTextColor }} onClick={savePhoneNumber}>
                    GET EM!
                </button>
                <p
                    onClick={skipPhoneNumber}
                    style={{ color: "#ffffffb5", cursor: "pointer", margin: "0.75rem 0 0", fontSize: "0.9rem", width: "fit-content" }}
                >
                    Skip for now
                </p>
            </div>
        </>
    )
}

function OnboardingWrapUp(props) {
    return (
        <>
            <div className='onboarding-back-div'>
                <PrevMobile visible={true} onClick={props.back} customStyles={backButtonStyles} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h1 className='onboarding-greeting' style={{ fontWeight: "bold", margin: "0 0 2rem" }}>
                        Awesome, you're all set.
                </h1>
                <div>
                    <p style={{ marginBottom: "1.5rem", fontSize: "1rem", maxWidth: '18rem' }}>
                        Now stretch your fingers and have a moment of silly!
                    </p>
                    <p style={{ marginBottom: "1.5rem", fontSize: "1rem", maxWidth: '18rem' }}>
                        When feelings come up at work, you'll have Normili 💛
                    </p>
                    <p style={{ marginBottom: "1.5rem", fontSize: "1rem", maxWidth: '18rem' }}>
                        Trust the process and watch the results unfold with <u>consistency</u>.
                    </p>
                </div>
            </div>
            <button className='onboarding-btn' style={{ color: props.buttonTextColor }} onClick={props.onClick}>
                LET'S DO THIS!
            </button>
        </>
    )
}
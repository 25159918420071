import React, { useState } from 'react'
import AnswerInput from './AnswerInput'
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import './css/question.css'

function Question(props) {
    let question = props.question;
    const [showAnswerInput, setAnswerInputDisplay] = useState(false);

    return (
        <SwitchTransition>
        <CSSTransition
        appear={true}
        key={question.StepId}
        timeout={250}
        classNames={'question'}
        onEntered={() => {setAnswerInputDisplay(true)}}
        >
        <div className="question">
            <div className="question-text-div">
                <p className="question-text">{question.DisplayText}</p>
            </div>
            {question.AnswerFormat && 
            <AnswerInput questionId={question.StepId}
                showAnswerInput={showAnswerInput}
                inputType={question.AnswerFormat}
                answerPrompt={question.AnswerPrompt}
                placeholder={question.Placeholder}
                answerOptions={question.AnswerOptions}
                onAnswered={props.onAnswered}
                selectedAnswer={props.selectedAnswers[question.StepId]}
            />}
        </div>
        </CSSTransition>
        </SwitchTransition>
    )
}

export default Question

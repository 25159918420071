import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../css/weekview.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { calculateDayColor } from '../../helpers/CalendarHelper';

function Weekdates(props) {

    return (
        <TransitionGroup component={null}>
        <CSSTransition
        key={uuidv4()}
        exit={false} //Stops any exit animation classes from being applied
        timeout={500}
        classNames={props.clickState === 'next' ? 'slide-next' : 'slide-prev'}
        >
            <div className='weekdays-container'>
                {              
                props.currentWeek.map((day, i) => {
                    let dayColor = calculateDayColor(day, props.entries);
                    return (
                        <div className='weekday-cell' key={i}>
                            <div className='week-date' style={{background: dayColor, color: "white"}}>
                                {typeof(day) !== 'string' ? day.getDate() : ""}
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </CSSTransition>
        </TransitionGroup>
    )
}

export default Weekdates
import { useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { PaymentResult } from "./PaymentResult";
import { StripeProvider } from "./StripeProvider";

export const PaymentsContainer = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    // When a user's checkout session is completed, it will redirect us back to the app with sessionId, which can then be used
    // to check if the payment successfully went through. So having sessionId means we can now actually get the payment result.
    // And without the sessionId, we will only want to show the checkout form.
    const [showPaymentResult, setShowPaymentResult] = useState(sessionId ? true : false)
    

    return (
        showPaymentResult ?
            <PaymentResult sessionId={sessionId} setShowPaymentResult={setShowPaymentResult} />
            :
            <StripeProvider>
                <CheckoutForm setShowPaymentResult={setShowPaymentResult} />
            </StripeProvider>
    )
}
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import FlowentryDetailView from '../myCheckins/FlowentryDetailView';
import { bindKeyboard } from 'react-swipeable-views-utils';
import '../css/swipeable-flows.css';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function SwipeableFlows(props) {
    const [flows, setFlows] = useState(null);
    const [animateHeight, setAnimateHeight] = useState(false);
    const [swipeHint, setSwipeHint] = useState(props.swipeHint ?? `Swipe or hit → key for next`);

    useEffect(() => {
        setFlows(props.flows);
    }, [props.flows])

    useEffect(() => {
        if (props.swipeHint) {
            setSwipeHint(props.swipeHint)
        }
    }, [props.swipeHint])

    useEffect(() => {
        // This is being done as a workaround to an issue in swipeable views, where if animateHeight true from the get go,
        // the first slide's height gets stuck at 0px
        if (props.animateHeight) {
            setTimeout(() => {
                setAnimateHeight(true); 
            }, 800);
        }
    }, [flows])

    return (
        flows ?
        <div style={{maxWidth: "49rem"}}>
            <BindKeyboardSwipeableViews 
                enableMouseEvents={true}
                animateHeight={animateHeight}
                onChangeIndex={props.handleChangeIndex}
                index={props.index ?? 0}
            >
                {flows.map((flow, i) =>
                <div key={flow.entry._id}>
                    <p className='swipeHint' style={props.swipeHintStyle}>
                        {i < flows.length - 1 ? swipeHint : `End`}
                    </p>
                    <FlowentryDetailView key={flow.entry._id} flowEntry={flow.entry}
                        style={props.flowStyles && props.flowStyles[i]}
                        colorScheme={props.colorScheme}
                        highlightTag={props.highlightTag}/>
                </div>
                )}
            </BindKeyboardSwipeableViews>
        </div>
        : <></>
    )
}

export default SwipeableFlows
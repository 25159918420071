import React from 'react';

function Tag(props) {

  const tagStyle = {
      backgroundColor : props.tagColor,
      color: props.tagTextColor
  }
  const className = props.className ?? 'card-tag';

  return (
    <span className={className} style={tagStyle}>{props.text}</span>
  )
}

export default Tag;
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getBackgroundColorByTimeOfDay } from '../../helpers/EntriesHelper';
import '../css/flowentry_detail.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import SwipeableFlows from '../bigPicture/SwipeableFlows';

function FlowDetailsPage(props) {
    const location = useLocation();
    const history = useHistory();

    const [pageBackground, setPageBackground] = useState();
    const [fromView, setFromView] = useState();
    const [flowIndex, setFlowIndex] = useState(0);

    if (!location.state) {
        throw (`Flow details not found in ${location}`);
    }

    useEffect(() => {
        if (!location.state) {
            console.error(`Flow details not found.`);
            return;
        }

        const initialFlowIndex = location.state.flows?.findIndex(flow => location.state.id === flow.entry._id)
        setFlowIndex(initialFlowIndex > -1 ? initialFlowIndex : 0);
        setFromView(location.state.view);

    }, [location])

    useEffect(() => {
        setPageBackground({
            background: getBackgroundColorByTimeOfDay(props.timeOfDay)
        });
    }, [props.timeOfDay])

    const handleChangeIndex = (index) => {
        setFlowIndex(index);
    }

    const handlePrevClick = () => {
        history.goBack();
    }

    return (
        <div className='checkin-details-page' style={pageBackground}>
            <div className='swipeable-container'>
                <div className='swipeable-container-2'> {/* Don't know why, but this is needed to make the flows fit properly on smaller screens */}
                    <SwipeableFlows
                        flows={location.state.flows}
                        handleChangeIndex={handleChangeIndex}
                        index={flowIndex}
                        animateHeight={true}
                        swipeHintStyle={{color: "white", fontSize: "12px", marginRight: "1rem"}}
                    />
                </div>
            </div>
            <div className='checkin-details-back-btn-div'>
                <button className="back-to-cal-btn" onClick={handlePrevClick}>
                    {fromView === 'W' ? 'Back to week' : 'Back to month'}
                </button>
            </div>   
        </div>
    )
}

export default FlowDetailsPage;
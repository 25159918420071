import React from 'react'
import './css/formnavbuttons.css'

function Prev(props) {
    let visibility = props.visible ? {} : {"opacity" : "0"};
    return (
        <button className="nav-btn triangle-container-previous"
            disabled={!props.visible}
            style={visibility} onClick={props.onClick}>
            <svg className="triangle" 
                viewBox={props.viewBox ?? '0 0 100 100'} 
                height={props.height ?? 100} 
                width={props.width ?? 100}
                style={{"strokeWidth": props.strokeWidth ?? 2}}>
                    <polygon points="90,20,90,80,70,50"/>
                Sorry, your browser does not support inline SVG.
            </svg>
        </button>
    )
}

export default Prev

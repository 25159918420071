import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { CalendarViewTypes, BigPictureCutoffs, JSNumberToMonthMapping } from '../../Constants';
import { getViewTypeFromQueryParams, 
    getDateFromQueryParams,
    getMonth,
    getEntriesForWeek,
    calculateDayColor,
    getEntriesForYear} from '../../helpers/CalendarHelper';
import Prev from '../Prev';
import Next from '../Next';
import CheckinsHeading from './CheckinsHeading';
import BigPictureButton from '../bigPicture/BigPictureButton';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../css/yearview.css';

function YearView(props) {
    let history = useHistory();
    let route = useRouteMatch();
    let location = useLocation();

    const today = new Date();
    const [currentYear, setCurrentYear] = useState(today.getFullYear());
    const [yearFlows, setYearFlows] = useState();
    const [clickState, setClickState] = useState(null);
    const [showBigPictureButton, setBigPictureButtonDisplay] = useState(false);

    // #region routing & initialization
    const updateQueryParams = (date, replace=false) => {
        const dateStr = date.toDateString().replace(/\s+/g, '-').toLowerCase();
        const urlWithQueryParams = `${route.url}?view=${CalendarViewTypes.Year.Abbrv}&date=${dateStr}`;
        if (replace) {
          history.replace(urlWithQueryParams)
        }
        else {
          history.push(urlWithQueryParams);
        }
    }

    const getInitialDateForYearUrl = () => {
        if (!props.anchorDate) {
            return today;
        }
        if (props.anchorDate.getFullYear() === today.getFullYear()) {
            return today;
        } else {
            return props.anchorDate;
        }
    }

    useEffect(() => {
        let view = getViewTypeFromQueryParams(location.search);
        let anchorDate = getInitialDateForYearUrl();
        if (view === CalendarViewTypes.Week.Abbrv) {
            updateQueryParams(anchorDate)
        }
        else if (view === CalendarViewTypes.Month.Abbrv) {
            updateQueryParams(anchorDate);
        }
        else {
            updateQueryParams(anchorDate, true);
        }
    }, []);

    useEffect(() => {
        let query = location.search;
        let date = getDateFromQueryParams(query);
        if (date) {
            let year = date.getFullYear();
            if (year < currentYear) {
                setClickState('prev');
                setCurrentYear(year);
                props.updateAnchor(date);
            }
            else if (year > currentYear) {
                setClickState('next');
                setCurrentYear(year);
                props.updateAnchor(date);
            }
            else {
                // if year is current, update anchor to be today's date
                props.updateAnchor(today);
            }
        }
      },[location])

      useEffect(() => {
        if (props.entries) {
            const yearFlows = getEntriesForYear(props.entries, currentYear);
            setYearFlows(yearFlows);
            const numWorkEntriesInYear = yearFlows?.length;
            if (numWorkEntriesInYear > BigPictureCutoffs.Year) {
                setBigPictureButtonDisplay(true);
            }
            else {
                setBigPictureButtonDisplay(false);
            }
        }
      }, [props.entries, currentYear])

    //#endregion

    // #region next/prev navigation
    const isPrevVisible = () => {
        // Setting default to not go before 2022. TODO: Otherwise it needs to be user's registration year.
        let lastYearToBeShown = 2022;
        if (currentYear > lastYearToBeShown) {
            return true;
        }
        return false;
    }
    const handlePrevClick = () => {
        const prevYear = currentYear - 1;
        const newAnchorDate = new Date(prevYear, 0 ,1);
        setCurrentYear(prevYear);
        updateQueryParams(newAnchorDate);
    }

    const isNextVisible = () => {
        if (today.getFullYear() > currentYear) {
            return true;
        }
        return false;
    }
    const handleNextClick = () => {
        const nextYear = currentYear + 1;
        const newAnchorDate = new Date(nextYear, 0, 1);
        setCurrentYear(nextYear);
        updateQueryParams(newAnchorDate);
    }
    //#endregion

    const getTinyMonth = (monthIndex) => {
        const monthStart = new Date(currentYear, monthIndex, 1);
        const monthName = JSNumberToMonthMapping[monthIndex];
        const monthDays = getMonth(monthStart);

        const jsx = <div className='tiny-month' key={monthName}>
            <div className='tiny-month-title'>{monthName}</div>
            <div className='week-div'>
                {monthDays.map((week, i) => {
                    let startDate = typeof(week[0]) !== 'string' ? week[0] : monthStart;
                    let weekEntries = props.entries && getEntriesForWeek(props.entries, startDate);
                    return getTinyWeek(week, weekEntries);
                }
                )}
            </div>
        </div>
        return jsx;
    }

    const getTinyWeek = (week, weekEntries) => {
       const jsx = <div className='weekdays-container'>
        {              
            week.map((day, i) => {
                let dayColor = calculateDayColor(day, weekEntries);
                return (
                    <div className='tiny-weekday-cell' key={i}>
                        <div className='tiny-week-date' style={{background: dayColor, color: "white"}}>
                            {typeof(day) !== 'string' ? day.getDate() : ""}
                        </div>
                    </div>
                )
            })
        }
        </div>

        return jsx;
    }

    const getQueryParamsForEagleViz = () => {
        let startTime = new Date(currentYear, 0, 1).getTime() / 1000 | 0; //convert to seconds
        let endTime = new Date(currentYear + 1, 0, 1).getTime() / 1000 | 0;
        return `view=${CalendarViewTypes.Year.Abbrv}&start=${startTime}&end=${endTime}`;
    }

    return (
        // p.s. reusing css classes from week view
        <div className='weekview'>
            <div className='week-nav-div'>
                <Prev visible={isPrevVisible()}
                    viewBox='40 20 80 80' height='45' width='45' strokeWidth='4' 
                    onClick={() => { setClickState('prev'); handlePrevClick(); }}
                />
                <TransitionGroup>
                  <CSSTransition
                  key={currentYear}
                  exit={false} //Stops any exit animation classes from being applied
                  timeout={500}
                  classNames={clickState === 'next' ? 'slide-next' : 'slide-prev'}>
                    <p className='week-heading'>Year {currentYear}</p>
                  </CSSTransition>
                </TransitionGroup>
                <Next visible={isNextVisible()} 
                    viewBox='-20 20 80 80' height='45' width='45' strokeWidth='4'
                    onClick={() => { setClickState('next'); handleNextClick(); }}
                />
            </div>
            { showBigPictureButton && 
                <BigPictureButton style={{color: 'black', marginTop: "0.5rem", marginBottom: "1rem"}} 
                queryParams={getQueryParamsForEagleViz()} flows={yearFlows}/>
            }
            <CheckinsHeading 
                numFlows={props.entries ? getEntriesForYear(props.entries, currentYear)?.length : null}
                startDate={new Date(currentYear, 0, 1)}
                view={CalendarViewTypes.Year.Name}
                style={{marginTop: "0.5rem", marginBottom: "1rem"}}
            />
            <div className='tiny-months-container'>
                {Object.entries(JSNumberToMonthMapping).map((entry, i) => {
                    return getTinyMonth(i)
                })}
            </div>
        </div>
    )
}

export default YearView
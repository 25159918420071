import './css/answer.css';
import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { AnswerFormat } from '../Constants';
import Radio from './Radio';
import Checkbox from './Checkbox';
import CheckboxDetailed from './CheckboxDetailed';
import SomethingElseText from './SomethingElseText';
import { CSSTransition } from 'react-transition-group';

function AnswerInput(props) {

    let answerValues = []
    if (props.inputType === AnswerFormat.Radio) {
        answerValues = props.answerOptions.map(option =>
            <Radio key={option}
                name={props.questionId}
                value={option}
                onAnswered={props.onAnswered}
                selectedAnswer={props.selectedAnswer}
                defaultSize={props.answerOptions.length > 6 ? false : true}
            />)
    }
    else if (props.inputType === AnswerFormat.Checkbox) {
        answerValues = props.answerOptions.map(option =>
            <Checkbox id={AnswerFormat.Checkbox}
                key={option}
                name={props.questionId}
                value={option}
                onAnswered={props.onAnswered}
                checked={props.selectedAnswer && props.selectedAnswer.includes(option)}
            />
        )
    }
    else if (props.inputType === AnswerFormat.Textarea) {
        answerValues.push(
            <textarea id={AnswerFormat.Textarea}
            key={props.questionId} className='journal-input'
            spellCheck="false" placeholder={props.placeholder ?? "Journal it out here..."}
            onChange={props.onAnswered} 
            value={props.selectedAnswer}
            />        
        )
    }
    else if (props.inputType === AnswerFormat.TextInputDetails) {
        answerValues.push(
            <textarea id={AnswerFormat.TextInputDetails}
            key={props.questionId} className='journal-input' style={{"height":"7rem"}}
            spellCheck="false" placeholder={props.placeholder ?? "Elaborate here..."}
            onChange={props.onAnswered} 
            value={props.selectedAnswer}
            />
        )
    }
    else if (props.inputType === AnswerFormat.CheckboxDetailed) {
        answerValues = props.answerOptions.map(option =>
            <CheckboxDetailed key={option.title}
            name={props.questionId}
            value={option.title}
            secondaryValue={option.smallText}
            icon={option.icon}
            onAnswered={props.onAnswered}
            checked={props.selectedAnswer && props.selectedAnswer.includes(option.title)}
            />
        )
    }
    else if (props.inputType === AnswerFormat.CheckboxAndText) {
        answerValues = props.answerOptions.map(option =>
            <Checkbox id={AnswerFormat.CheckboxAndText}
                key={option}
                name={props.questionId}
                value={option}
                onAnswered={props.onAnswered}
                checked={(props.selectedAnswer && props.selectedAnswer.checkboxSelections) 
                    && props.selectedAnswer.checkboxSelections.includes(option)}
            />
        )
        var textDetails = (props.selectedAnswer && props.selectedAnswer.textDetails)
            ? props.selectedAnswer.textDetails : '';
    }
    else {
        console.log("input format - " + props.inputType + " is not supported")
    }
    
    const [isSomethingElseSelected, setSomethingElseSelection] = useState(false);
    // Determine if something else checkbox is selected
    useEffect(() => {
        if (props.inputType === AnswerFormat.CheckboxAndText && props.selectedAnswer?.checkboxSelections) {
            let isSelected = props.selectedAnswer.checkboxSelections.some(answer => {
                return answer.toLowerCase().includes('something else');
            })
            setSomethingElseSelection(isSelected);
        } 
        else {
            setSomethingElseSelection(false);
        } 
    },[props])

    //#region styling
    const scrollableStyle = {
        "background" : "rgba(0,0,0,0.04)",
        "borderRadius" : "2rem",
        "maxHeight" : "350px",
        "overflow" : "auto",
        "scrollbarColor" : "rgba(0,0,0,0.05) rgba(0,0,0,0)",
    };

    const getMobileStyle = () => {
        let mobileStyle = {};
        if (props.inputType.includes("text")) {
            // reduced margin on mobile view for textarea
            mobileStyle = { 
                margin:"0 0.3rem" 
            };
        }
        else if (props.answerOptions?.length > 6) {
            mobileStyle = {
                "background" : "rgba(0,0,0,0.04)",
                "borderRadius" : "2rem",
                "maxHeight" : window.innerHeight/2,
                "overflow" : "auto",
                "scrollbarColor" : "rgba(0,0,0,0.05) rgba(0,0,0,0)",
                "margin" : "0 0.3rem"
            };
        }

        return mobileStyle;
    }

    const [answerContainerStyle, setAnswerContainerStyle] = useState({});
    const myRef = useRef();
    useEffect(() => {
        if (window.innerWidth <= 490) {
            let style = getMobileStyle();
            setAnswerContainerStyle(style);
        }
        else {
            if(props.answerOptions?.length > 6 && myRef.current.clientHeight > 390) {
                setAnswerContainerStyle(scrollableStyle);
            }
            else {
                setAnswerContainerStyle({});
            }
        }
    },[myRef, props.questionId])
    //#endregion

    return (
        <CSSTransition
        appear={true}
        in={props.showAnswerInput}
        timeout={250}
        classNames={'answer-input-div'}
        >
        <div className='answer-input-div'>
            {props.answerPrompt ? <div className="answer-prompt">{props.answerPrompt}</div> : null}
            <div ref={myRef} className="answer-container" style={answerContainerStyle}>
            {answerValues}
            {
                isSomethingElseSelected &&
                <SomethingElseText questionId={props.questionId} value={textDetails} onAnswered={props.onAnswered}/>
            }
            </div>
        </div>
        </CSSTransition>
    )
}

export default AnswerInput

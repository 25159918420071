import React from 'react';
import { useState, useEffect } from 'react';
import { getWeek, getWeekHeading, getEntriesForWeek, getDateFromQueryParams, getViewTypeFromQueryParams } from '../../helpers/CalendarHelper';
import { getDateFromMongoId } from '../../helpers/EntriesHelper';
import { v4 as uuidv4 } from 'uuid';
import Prev from '../Prev';
import Next from '../Next';
import Weekdays from './Weekdays';
import Weekdates from './Weekdates';
import CheckinsHeading from './CheckinsHeading';
import CheckinsList from './CheckinsList';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../css/weekview.css';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { CalendarViewTypes } from '../../Constants';

// This will show the week view
function WeekView(props) {
  let history = useHistory();
  let route = useRouteMatch();
  let location = useLocation();

  const today = new Date();
  const dateOfFirstCheckinEver = props.entries && props.entries.length > 0 ? getDateFromMongoId(props.entries[0]._id) : new Date(2050);
  const [currentWeek, setCurrentWeek] = useState(getWeek(props.anchorDate ?? today));
  const [weekEntries, setWeekEntries] = useState(null);
  const [clickState, setClickState] = useState(null);

  const updateQueryParams = (date, replace=false) => {
    const dateStr = date.toDateString().replace(/\s+/g, '-').toLowerCase();
    const urlWithQueryParams = `${route.url}?view=W&date=${dateStr}`;
    if (replace) {
      // preserve location state if passed in. This would tell us if 
      // big picture counter should be shown or not
      history.replace(urlWithQueryParams, location.state);
    }
    else {
      history.push(urlWithQueryParams);
    }
  }

  useEffect(() => {
    let view = getViewTypeFromQueryParams(location.search);
    if (view === CalendarViewTypes.Month.Abbrv) {
      updateQueryParams(currentWeek[0])
    }
    else if(view === CalendarViewTypes.Year.Abbrv) {
      updateQueryParams(today);
    }
    else {
      updateQueryParams(currentWeek[0], true);
    }
  }, []);

  // To make sure the week displayed matches the query params.
  useEffect(() => {
    let query = location.search;
    let date = getDateFromQueryParams(query);
    if (date) {
      var week = getWeek(date);
      if (week[0].toDateString() < currentWeek[0].toDateString()) {
        setClickState('prev');
        setCurrentWeek(week);
        props.updateAnchor(week[0]);
      }
      else if (week[0].toDateString() > currentWeek[0].toDateString()) {
        setClickState('next');
        setCurrentWeek(week);
        props.updateAnchor(week[0]);
      }
      else {
        // do nothing if week is same
      }
    }
  },[location])

  useEffect(() => {
    if (props.entries!== null) 
    {
        let entriesForWeek = getEntriesForWeek(props.entries, currentWeek[0]);
        setWeekEntries(entriesForWeek);
    }
  }, [currentWeek, props.entries])

  const handlePrevClick = () => {
    const startDate = currentWeek[0];
    const prevWeekStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()-7);
    const week = getWeek(prevWeekStartDate);
    setCurrentWeek(week);
    props.updateAnchor(week[0]);
    updateQueryParams(week[0]);
  }

  const handleNextClick = () => {
    const startDate = currentWeek[0];
    const nextWeekStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()+7);
    const week = getWeek(nextWeekStartDate);
    setCurrentWeek(week);
    props.updateAnchor(week[0]);
    updateQueryParams(week[0]);
  }

  const isPrevVisible = () => {
    // Don't go beyond the first week of July for now. Otherwise it can be the user's registration week.
    let lastDateToBeShown = new Date(2022, 6, 3);
    if (dateOfFirstCheckinEver < lastDateToBeShown) {
      lastDateToBeShown = dateOfFirstCheckinEver;
    }
    if (currentWeek[0] <= lastDateToBeShown) {
      return false;
    }
    return true;
  }

  const isNextVisible = () => {
    const endDate = currentWeek[6];
    const lastDateToBeShown = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
    if (endDate >= lastDateToBeShown) {
      return false;
    }
    return true;
  }

  return (
    <div className='weekview'>
        <div className='week-nav-div'>
            <Prev visible={isPrevVisible()}
                viewBox='40 20 80 80' height='45' width='45' strokeWidth='4' 
                onClick={() => { setClickState('prev'); handlePrevClick(); }}
            />
            <TransitionGroup>
              <CSSTransition
                key={uuidv4()}
                exit={false} //Stops any exit animation classes from being applied
                timeout={500}
                classNames={clickState === 'next' ? 'slide-next' : 'slide-prev'}
              >
                <p className='week-heading'>{getWeekHeading(currentWeek)}</p>
              </CSSTransition>
            </TransitionGroup>
            <Next visible={isNextVisible()} 
                viewBox='-20 20 80 80' height='45' width='45' strokeWidth='4'
                onClick={() => { setClickState('next'); handleNextClick(); }}
            />         
        </div>
        <div className='week-div'>
            <Weekdays/>
            <Weekdates currentWeek={currentWeek} entries={weekEntries} clickState={clickState}/>
        </div>
        <CheckinsHeading numFlows={weekEntries?.length} startDate={currentWeek[0]} view={CalendarViewTypes.Week.Name}/>
        <CheckinsList entries={weekEntries} clickState={clickState}/>
    </div>
  )
}

export default WeekView;
import React, { useState, useEffect } from 'react';
import { getWorkEnergyDistribution,
    getWorkFeelingDistribution,
    getUpliftingCausesForCategory,
    getDepletingCausesForCategory } from '../../adapters/insights/insights-adapter';
import PieChart from '../insights/PieChart';
import { EnergyLevelColors, FeelingColors, ImpactColors } from '../../Colors';
import { WorkCategories, AnswerToWorkCategoryMap, UnpleasantFeelings, JSNumberToMonthMapping } from '../../Constants';
import GroupedBarChart from '../insights/GroupedBarChart';
import BubbleChart from '../insights/BubbleChart';
import { AppColorTheme } from '../../Colors';
import InsightHeading from '../insights/InsightHeading';
import '../css/insights.css';
import '../css/big-picture-page.css';
import CauseDetails from './CauseDetails';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getFlowEntriesForCauseByImpact, filterFlowsByCategory } from '../../helpers/EntriesHelper';
import FeelingsPieChart from '../insights/FeelingsPieChart';

function BigPictureMode(props) {
    const location = useLocation();
    const [scrollPosition, setScrollPositon] = useState(0);
    const [timespanHeader, setTimespanHeader] = useState('');

    // data related states
    const [flows, setFlows] = useState([]);
    const [flowsForCause, setFlowsForCause] = useState([]);
    const [energyDistribution, setEnergyDist] = useState([]);
    const [feelingDistribution, setFeelingDist] = useState([]);
    const [upliftingWorkCategories, setUpliftingWorkCategories] = useState(null);
    const [depletingWorkCategories, setDepletingWorkCategories] = useState(null);
    const [workImpactDistribution, setWorkImpactDistribution] = useState([]);
    const [upliftingCauses, setUpliftingCauses] = useState([]);
    const [depletingCauses, setDepletingCauses] = useState([]);


    // visibility related states
    const [displayWorkImpactDistribution, setWorkImpactDistDisplay] = useState(false);
    const [displayUpliftingBubbles, setUpliftingBubblesDisplay] = useState(false);
    const [displayDepletingBubbles, setDepletingBubblesDisplay] = useState(false);
    const [showClickHint, setShowClickHint] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [feelingDistType, setFeelingDistType] = useState('Base'); // aka just show overall pleasant vs unpleasant

    let textColor = AppColorTheme[props.timeOfDay].DARK;

    useEffect(() => {
        if (location.state.flows) {
            setFlows(location.state.flows);
        }

        const searchParams = new URLSearchParams(location.search);
        // Get specific query parameter values
        const view = searchParams.get('view');
        const startDate = new Date(searchParams.get('start') * 1000);
        const endDate = new Date(searchParams.get('end') * 1000);
        if (view === 'M') {
            // Because we usually do 1 month as Aug 1 to Sep 1.
            if (startDate.getMonth() !== endDate.getMonth()) {
                setTimespanHeader(`${JSNumberToMonthMapping[startDate.getMonth()]} ${startDate.getFullYear()}`)
            }
            else {
                // For some reason if we are doing super date specific like Jan 1-15
                const startDay = startDate.getDay();
                const endDay = endDate.getDay();
                setTimespanHeader(
                    `${JSNumberToMonthMapping[startDate.getMonth()]} ${startDay} - ${endDay}, ${startDate.getFullYear()}`
                )
            }
        }
        else if (view === 'Y') {
            // Because it looks at Jan 1, year to Jan 1 next year
            setTimespanHeader(`${startDate.getFullYear()}`)
        }
        else {
            // If I do a random duration like Nov 2023 - April 2024
            const startDay = startDate.getDay();
            const endDay = endDate.getDay();
            const startMonth = JSNumberToMonthMapping[startDate.getMonth()];
            const endMonth = JSNumberToMonthMapping[endDate.getMonth()];
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();
            if (startYear === endYear) {
                setTimespanHeader(`${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`)
            }
            else {
                setTimespanHeader(`${startMonth} ${startDay} ${startYear}  - ${endMonth} ${endDay}, ${endYear}`)
            }
        }

    }, [])

    useEffect(() => {
        if (!showModal) {
            // this will ensure page scroll is preserved once a user exits the modal. 
            // this method works when no new elements are added to the page
            window.scrollTo(0, scrollPosition); 
        }
    }, [showModal])

    //#region Get Data for charts
    useEffect(() => {
        const userId = props.user.uid;
        const startTime = props.startTime ?? null;
        const endTime = props.endTime ?? null;

        // set data for pie charts
        getWorkEnergyDistribution(userId, startTime, endTime).then((result) => {
            if (result) {
                setEnergyDist(result);
            }
        });
        getWorkFeelingDistribution(userId, startTime, endTime).then((result) => {
            if (result) {
                setFeelingDist(result);
            }
        });

        // get data for bar chart
        getUpliftingCausesForCategory(userId, WorkCategories.Exploration, startTime, endTime)
        .then((result) => {
            if (result) {
                setUpliftingWorkCategories(result);
            }
        });
        getDepletingCausesForCategory(userId, WorkCategories.Exploration, startTime, endTime)
        .then((result) => {
            if (result) {
                setDepletingWorkCategories(result);
            }
        });

    }, [props.user])

    // set data for bar chart and bubble charts
    useEffect(() => {
        if(upliftingWorkCategories === null || depletingWorkCategories === null){
            return
        }
        const userId = props.user.uid;
        const startTime = props.startTime ?? null;
        const endTime = props.endTime ?? null;
        let jointImpact = [];

        upliftingWorkCategories.forEach(element => {
            jointImpact.push({_id: element._id, count1: element.count, count2: 0})
        });
        depletingWorkCategories.forEach(element => {
            let obj = jointImpact.find((o, i) => {
                if (o._id === element._id) {
                    jointImpact[i].count2 = element.count;
                    return true;
                }
            })
            if (!obj) {
                jointImpact.push({_id: element._id, count1: 0, count2: element.count});
            }
        });
        // sort data in descending order by uplifting count
        jointImpact.sort((a, b) => b.count1 - a.count1);
        setWorkImpactDistribution(jointImpact);

        // get details for uplifting and depleting causes in each applicable category
        let upliftingFactors = [];
        let depletingFactors = [];
        let promises = [];
        jointImpact.forEach((element) => {
            if (element._id === 'Not specified') {
                return;
            }
            const category = AnswerToWorkCategoryMap[element._id];
            if (!category) {
                console.error(`Didn't find category mapping to ${element._id}`)
                return;
            }

            let key = element._id;
            if (element.count1 > 0) {
                promises.push(
                    getUpliftingCausesForCategory(userId, category, startTime, endTime)
                    .then((result) => {
                        if (result && result.length > 0) {
                            const upliftingImpactForCategory = {
                                Category: key,
                                Values: result
                            }
                            upliftingFactors.push(upliftingImpactForCategory);
                        }
                    })
                    .catch ((error) => {
                        console.log('Error: ', error);
                    })
                )
            }
            if (element.count2 > 0) {
                promises.push(
                    getDepletingCausesForCategory(userId, category, startTime, endTime)
                    .then((result) => {
                        if (result && result.length > 0) {
                            const depletingImpactForCategory = {
                                Category: key,
                                Values: result
                            }
                            depletingFactors.push(depletingImpactForCategory);
                        }
                    })
                    .catch ((error) => {
                        console.log('Error: ', error);
                    })
                )
            }
        });

        Promise.all(promises).then(() => {
            setUpliftingCauses(upliftingFactors);
            setDepletingCauses(depletingFactors);
        });

    }, [upliftingWorkCategories, depletingWorkCategories])

    useEffect(() => {
        if(workImpactDistribution.length > 0) {
            setWorkImpactDistDisplay(true);
        }
    }, [workImpactDistribution])

    useEffect(() => {
        if(upliftingCauses && upliftingCauses.length > 0){
            setUpliftingBubblesDisplay(true);
        }
    }, [upliftingCauses])

    useEffect(() => {
        if(depletingCauses && depletingCauses.length > 0){
            setDepletingBubblesDisplay(true);
        }
    }, [depletingCauses])

    //#endregion

    const getBubbleChartJsx = (impactType, detailsArray, heading) => {
        return <div className='bubble-insight-div' key={heading}>
            <InsightHeading
                heading={heading}
                headingColor={textColor}
                headingSize={window.innerWidth < 500 ? '1rem': '1.5rem'}
            />
            <BubbleChart 
                data={{"children":detailsArray}}
                impactType={impactType}
                onClick={onBubbleClick}
                heading={heading}
            />
        </div>
    }

    const onBubbleClick = (causeName, category, impactType) => {
        // filter flows for this cause in a category by the impact
        const flowsForCause = getFlowEntriesForCauseByImpact(flows, causeName, category, impactType);
        setFlowsForCause({
            flowsForCause: flowsForCause,
            causeName: causeName
        });
        
        displayCauseDetails();
    }

    const onEnergyDistributionClick = (segmentName) => {
        const flowsForCause = filterFlowsByCategory(flows, 'energy', segmentName);
        setFlowsForCause({
            flowsForCause: flowsForCause,
            causeName: segmentName === 'Drained' ? 'Drained' : `${segmentName} Energy`
        });

        displayCauseDetails();
    }

    const onFeelingDistributionClick = (feeling) => {
        let causeName = feeling;
        let flowsForCause = [];
        if (feeling === 'Alright pleasant') {
            const flows1 = filterFlowsByCategory(flows, 'work_inquiry', 'Not too shabby');
            const flows2 = filterFlowsByCategory(flows, 'work_inquiry', "I'm loving it!");
            flowsForCause = flows1.concat(flows2);
            causeName = 'Work alright and pleasant';
        }
        else if (feeling === 'Alright unpleasant') {
            const flows1 = filterFlowsByCategory(flows, 'work_inquiry', 'Could be better');
            const flows2 = filterFlowsByCategory(flows, 'work_inquiry', "It sucks");

            const allAlrightFlows = filterFlowsByCategory(flows, 'feeling', 'Alright');
            // filter out the meh and drained ones that can have this answer, and only keep the feeling Alright ones (from low energy cases)
            const flows3 = filterFlowsByCategory(allAlrightFlows, 'location_of_cause', "Things at work");

            flowsForCause = flows1.concat(flows2, flows3);
            causeName = 'Work not quite pleasant';
        }
        else {
            const feelingMatchedFlows = filterFlowsByCategory(flows, 'feeling', feeling);
            // filter out work facing flows where the answered feeling question wasn't directly work related
            const flows1 = filterFlowsByCategory(feelingMatchedFlows, 'location_of_cause', 'Yes');
            const flows2 = filterFlowsByCategory(feelingMatchedFlows, 'location_of_cause', 'Things at work');
            flowsForCause = flows1.concat(flows2);

            if (feeling === 'Not good') {
                // Also get the drained flows because they are factored into feeling unpleasant in base distribution.
                // Because feeling drained is an unpleasant state
                const flows3 = filterFlowsByCategory(flows, 'energy', 'Drained');
                flowsForCause = flowsForCause.concat(flows3);
            }
            causeName = `Feeling ${feeling}`
        }
        setFlowsForCause({
            flowsForCause: flowsForCause,
            causeName: causeName
        });

        // Remember what pie was displayed so user can resume exploring right where they left off after exiting story details.
        if (UnpleasantFeelings.includes(feeling)) {
            setFeelingDistType('Unpleasant');
        }
        else {
            setFeelingDistType('Pleasant')
        }

       displayCauseDetails();
    }

    const onWorkImpactDistributionClick = (barTitle) => {
        barTitle = barTitle.split(':');
        const causeName = barTitle[0];
        const impactType = barTitle[1];
        const flowsForCause = getFlowEntriesForCauseByImpact(flows, causeName, 'work_cause_exploration', impactType);
        setFlowsForCause({
            flowsForCause: flowsForCause,
            causeName: causeName
        });

        displayCauseDetails();
    }

    const displayCauseDetails = () => {
        // save scroll position for current screen before showing modal
        setScrollPositon(window.scrollY);
        setShowModal(true);
    }

    const exitCauseDetails = () => {
        setShowModal(false);
    }

    return (
        !showModal ?
        <div className='big-picture-page'>
            <div className='page-heading-container'>
                <h3 className='big-picture-heading'>Work Big Picture</h3>
                <h4 className='timespan'>{timespanHeader}</h4>
            </div>
            {
                (energyDistribution.length > 0 && feelingDistribution.length > 0) &&
                <>
                <div className='insights-subcontainer'>
                    <PieChart data={energyDistribution} colorSet={EnergyLevelColors} 
                        headingColor={textColor}
                        heading='My Energy Distribution'
                        showClickHint={showClickHint}
                        dontShowClickHintAgain={() => setShowClickHint(false)}
                        onClick={onEnergyDistributionClick}
                    />
                    <FeelingsPieChart data={feelingDistribution} colorSet={FeelingColors}
                        headingColor={textColor}
                        heading='How I felt at Work'
                        onClick={onFeelingDistributionClick}
                        pieToDisplay={feelingDistType}
                    />
                </div>
                </>
            }
            {
                displayWorkImpactDistribution &&
                <>
                <div className='insights-subcontainer'>
                    <InsightHeading
                        heading={`Work Impact Breakdown`}
                        headingColor={textColor}
                        headingSize={window.innerWidth < 500 ? '1rem': '1.5rem'}
                    />
                    <GroupedBarChart data={workImpactDistribution} onClick={onWorkImpactDistributionClick}/>
                </div>
                </>
            }
            {
                displayUpliftingBubbles &&
                <div className='insights-subcontainer'>
                    <InsightHeading
                        heading={`Here's what uplifted you at work!`}
                        headingColor={ImpactColors.Uplifting}
                    />
                    {upliftingCauses.map((element) => 
                        getBubbleChartJsx('Uplifting', element.Values, element.Category)
                    )
                    }
                </div>
            }
            {
                displayDepletingBubbles &&
                <div className='insights-subcontainer'>
                    <InsightHeading
                        heading={`And here's what depleted you...`}
                        headingColor={ImpactColors.Depleting}
                    />
                    {depletingCauses.map((element) => 
                        getBubbleChartJsx('Depleting', element.Values, element.Category)
                    )
                    }
                </div>
            }
        </div>
        :
        <CauseDetails flows={flowsForCause} closeModal={exitCauseDetails}/>
    )
}

export default BigPictureMode;
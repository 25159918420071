import './css/home.css';
import React, {Component} from 'react'
import TimeContainer from '../TimeContainer'
import TimeSpecificGreeting from './TimeSpecificGreeting'
import { AppBGColors } from '../Colors'
import { getHealthStatus } from '../adapters/healthcheck-adapter.js';
import { getProfile } from '../adapters/profile/profile-adapter';
import { CSSTransition } from 'react-transition-group';

class Home extends Component {
    constructor(props) {
        super(props)

        let backgroundColor = this.getTimeSensitiveBackgroundColor(props.timeOfDay)
        this.state = {
           background : backgroundColor,
           userName : ""
        }

        getHealthStatus().then((result) => {
          console.log(result);
        });
    }

    componentDidMount() {
      const userId = this.props.user.uid;

      getProfile(userId).then((result) => {
        if (result) {
          const profile = result;
          this.setState({
            userName : profile.name.split(" ")[0] // first name only
          });
        }
      });
    }
    
    render() {
        return (
          <CSSTransition
          in={true}
          appear={true}
          timeout={250}
          classNames={'main-content'}
          >
            <div className="main-content" style={{background: this.state.background}}>
                <TimeSpecificGreeting timeOfDay={this.timeOfDay} flowType={this.props.flowType} userName={this.state.userName}/>
            </div>
          </CSSTransition>
        )
    }

    getTimeSensitiveBackgroundColor(defaultTimeOfDay) {
        this.timeOfDay = defaultTimeOfDay ?? new TimeContainer().getTimeOfDay()
        switch(this.timeOfDay) {
          case 'morning':
            return AppBGColors.morning    
          case 'afternoon':
            return AppBGColors.afternoon
          case 'evening':
            return AppBGColors.evening
          default:
            return AppBGColors.night
        }
    }

    updateBackground = (value) => {
        this.setState({
          background : value
        })
    }
}

export default Home;

import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min"
import Home from "../components/Home"
import AnytimeCheckin from "../components/AnytimeCheckin"
import { RenewSubscriptionScreen } from "../components/payments/RenewSubscriptionScreen"
import FlowDetailsPage from "../components/myCheckins/FlowDetailsPage"
import MyCheckins from "../components/myCheckins/MyCheckins"
import TransitionToBigPicture from "../components/bigPicture/TransitionToBigPicture"
import Profile from "../components/Profile"
import { ResetPassword } from "../components/authentication/ResetPassword"
import { ForgotPassword } from "../components/authentication/ForgotPassword"
import { useContext, useState } from "react"
import { CheckinType } from "../Constants"
import { getAuth } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"
import { CurrentMetadataForUser } from "../context/CurrentMetadataForUser"
import { RenewSubscriptionFlow } from "../components/payments/RenewSubscriptionFlow"
import { AuthLandingPage } from "../components/authentication/AuthLandingPage"
import { PaymentsContainer } from "../components/payments/PaymentsContainer"

export const RoutesForOnboardedUser = ({ timeOfDay }) => {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const { isUserPaidSubscriber, remindersOn, onboardedDate } = useContext(CurrentMetadataForUser);

    const [flowType, setFlowType] = useState(CheckinType.EntryFlow);
    const updateFlowType = (flowType) => {
        setFlowType(flowType);
    };

    return (
        <Switch>
            <Route exact path="/">
                <Home timeOfDay={timeOfDay} flowType={flowType} user={user} />
            </Route>
            <Route path="/take-a-flow">
                {isUserPaidSubscriber ? (
                    <AnytimeCheckin
                        timeOfDay={timeOfDay}
                        flowType={flowType}
                        updateFlowType={updateFlowType}
                        user={user}
                    />
                )
                    :
                    <RenewSubscriptionFlow
                        timeOfDay={timeOfDay}
                        headingType="flow"
                    />
                }
            </Route>
            {!isUserPaidSubscriber && (
                <Route path="/renew-subscription">
                    <RenewSubscriptionScreen />
                </Route>
            )}
            {!isUserPaidSubscriber &&
                <Route path="/get-subscription">
                    <AuthLandingPage>
                        <PaymentsContainer />
                    </AuthLandingPage>
                </Route>
            }
            <Route path="/flows/:id">
                <FlowDetailsPage timeOfDay={timeOfDay} />
            </Route>
            <Route path="/flows">
                <MyCheckins timeOfDay={timeOfDay} 
                    user={user}
                    remindersOn={remindersOn}
                    onboardedDate={onboardedDate}
                />
            </Route>
            <Route path="/big-picture">
                {isUserPaidSubscriber ?
                    <TransitionToBigPicture timeOfDay={timeOfDay} user={user} />
                    :
                    <RenewSubscriptionFlow
                        timeOfDay={timeOfDay}
                        headingType="bigPicture"
                    />
                }
            </Route>
            <Route path="/profile">
                <Profile timeOfDay={timeOfDay} user={user} />
            </Route>
            <Route path="/reset-password">
                <ResetPassword />
            </Route>
            <Route path="/forgot-password">
                <ForgotPassword />
            </Route>
            <Route>
                <Home timeOfDay={timeOfDay} flowType={flowType} user={user} />
            </Route>
        </Switch>
    )
}
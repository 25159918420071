import React from 'react';

function AnswerBlurb(props) {

  const getStyle = () => {
    if (props.colorScheme && props.colorScheme === 'DARK') {
      if (!props.answer) {
        // don't highlight if answer is empty
        return {};
      }

      return {
        backgroundColor: props.colors.tag.MEDIUM,
        color: "black"
      };
    }
    else {
      return {
        backgroundColor: props.colors.tag.LIGHT
      };
    }
  }

  return (
    <div className='answer-blurb-container' style={getStyle()}>
      {!props.answer ? " " : props.answer}
    </div>
  )
}

export default AnswerBlurb;
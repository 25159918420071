import { getAuth } from "firebase/auth";
import { MailtoLink } from "../MailtoLink";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "../css/payments/payment-error.module.css"

export const PaymentError = ({
    errorInfoMessage,
    errorActionMessage,
    buttonText,
    buttonTriggersReload = true,
    setShowPaymentResult
}) => {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const history = useHistory();

    const onButtonClick = () => {
        if (buttonTriggersReload) {
            window.location.reload();
        }
        else {
            setShowPaymentResult(false)
            // We want to take the signed in user back to checkout.
            // The reason we're replacing the history is to remove the old session id from the query params.
            history.replace("/");
        }
    };

    return (
        <div className={styles.errorContainer}>
            <h1 className={styles.title}>Oops!</h1>
            <div>
                <p className={styles.errorMessage}>
                    {errorInfoMessage}
                </p>
                <p className={styles.errorMessage}>
                    {errorActionMessage}
                </p>
                <button className={styles.button} onClick={onButtonClick}>
                    {buttonText}
                </button>
            </div>
            <div className={styles.havingIssuesText}>
                <span>Still having issues? </span>
                <MailtoLink
                    to="hello@normili.com"
                    userEmail={user.email}
                    emailSubject={"Help with Payments"}
                    text="Contact&nbsp;support"
                />
            </div>
        </div>
    );
};
import { useEffect, useState } from "react";
import "../css/auth/floating-label-input.css";
import PasswordEyeClosed from "../../images/password-eye-closed.svg";
import PasswordEyeOpen from "../../images/password-eye-open.svg";

// Component reused from normili-landing-page
export const FloatingLabelInput = ({
    id,
    type,
    value,
    placeholder,
    onChange,
    required = false,
    autoComplete = "off",
    useValidation = false,
    validator = null,
    isValid = true,
}) => {
    const [hidePassword, setHidePassword] = useState(true);
    const HIDE_PASSWORD_MAP = { true: "password", false: "text" };

    // Remove eye on Microsoft Edge Browser
    // https://stackoverflow.com/questions/31721250/how-to-target-edge-browser-with-javascript
    const usingEdgeBrowser = window.navigator.userAgent.indexOf("Edg") > -1;
    const usingFirefoxBrowser =
        window.navigator.userAgent.indexOf("Firefox") != -1;

    const [textInputWidth, setTextInputWidth] = useState({});

    useEffect(() => {
        if (usingEdgeBrowser) {
            setTextInputWidth({ width: "-webkit-fill-available" });
        } else if (usingFirefoxBrowser && type != "password") {
            setTextInputWidth({ width: "-moz-available" });
        } else if (type != "password") {
            setTextInputWidth({ width: "-webkit-fill-available" });
        }
    }, []);

    return (
        <div className="floating-label-input-container">
            <div className="floating">
                <input
                    id={id}
                    type={type === "password" ? HIDE_PASSWORD_MAP[hidePassword] : type}
                    value={value}
                    required={required}
                    className="floatingInput"
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={useValidation ? validator : undefined}
                    autoComplete={autoComplete}
                    style={textInputWidth}
                />
                <label
                    htmlFor={id}
                    style={!isValid && useValidation ? { color: "red" } : {}}
                    className="floatingLabel"
                    data-content={placeholder}
                >
                    <span className={"hiddenVisually"}>{placeholder}</span>
                </label>
                {type === "password" && !usingEdgeBrowser && (
                    <img
                        src={hidePassword ? PasswordEyeClosed : PasswordEyeOpen}
                        onClick={() => setHidePassword(!hidePassword)}
                        className="toggle-password-eye"
                        alt={hidePassword ? "closed eye" : "👁️"}
                    />
                )}
            </div>
        </div>
    );
};

import React from 'react'
import './css/formnavbuttons.css'

function Next(props) {

    // choosing to modify opacity instead of conditional rendering so that the alignment of components is unaffected.
    let visibility = props.visible ? {} : {"opacity" : "0"};

    return (
        <button className="nav-btn triangle-container" style={visibility} disabled={props.disabled || !props.visible} onClick={props.onClick}>
            <svg className={!props.highlighted ? 'triangle' : 'triangle-nudge'} 
                viewBox={props.viewBox ?? '0 0 100 100'} 
                height={props.height ?? 100} width={props.width ?? 100}
            >
                <polygon points="10,20 10,80 30,50" className="triangle" />
                Sorry, your browser does not support inline SVG.
            </svg>
        </button>
    )
}

export default Next
export class TimeContainer {
    constructor() {   
        this.hours = new Date().getHours();
    }

    getTimeOfDay() {
        if (this.hours >= 5 && this.hours < 12) {
            return "morning"
        }
        else if (this.hours >= 12 && this.hours < 16) {
           return "afternoon"            
        }
        else if (this.hours >= 16 && this.hours < 20) {
            return "evening"
        }
        else {
            return "night"
        }
    }   
}

export default TimeContainer

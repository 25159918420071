import React from 'react';
import './css/finishbutton.css';
import useAnalyticsEventTracker from '../AnalyticsEventTracker';

function Finish(props) {
    let divStyle = props.highlighted ? {} : {float: "right"}
    let highlightStyle = props.highlighted ? 
    { 
        borderWidth: '0.2rem',
        fontSize: '1.3rem',
    } : {}

    const wrapUpClickEventTracker = useAnalyticsEventTracker('Checkins');
    const handleClick = () => {
        wrapUpClickEventTracker('Finish checkin');
        props.onClick();
    }
    
    return (
        <div className='finish-container' style={divStyle}>
            <button style={highlightStyle} className="nav-btn finish-btn" disabled={props.disabled} onClick={handleClick}>
                Wrap Up
            </button>
        </div>
    )
}

export default Finish

import { IS_STRIPE_IN_LIVEMODE } from "../Constants";

const server = process.env.REACT_APP_SERVER;

export const getAccountManagementPortalUrl = async (stripeCustomerId) => {
    try {
        const requestBody = JSON.stringify({
            stripeCustomerId: stripeCustomerId,
            returnToAppUrl: window.location.origin,
            isStripeInLivemode: IS_STRIPE_IN_LIVEMODE,
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: requestBody
        };
        const response = await fetch(
            `${server}/create-account-management-portal`,
            requestOptions
        );
        const data = await response.json();
        return data.portalUrl;
    }
    catch (e) {
        console.error(e, "getAccountManagementPortalUrl");
    }
};

export const createCheckoutSession = async (email, userAuthId, stripeCustomerId, promoCode) => {
    try {
        const requestBody = {
            priceId: process.env.REACT_APP_STRIPE_MONTHLY_SUBSCRIPTION,
            email: email,
            checkoutSuccessRedirectUrl: window.location.origin + window.location.pathname, // current url without any query params
            userAuthId: userAuthId,
            stripeCustomerId: stripeCustomerId,
            isStripeInLivemode: IS_STRIPE_IN_LIVEMODE, // defaults to false on backend if not provided
            promoCode: promoCode
        };

        const response = await fetch(`${process.env.REACT_APP_SERVER}/create-checkout-session`, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await response.json();
        return { stripeClientSecret: data.clientSecret, promoCodeFailMessage: data.promoCodeFailMessage }
    }
    catch (error) {
        console.error("Error loading Stripe checkout", error);
        return null;
    }
}

export const getSessionStatus = async (sessionId) => {
    try {
        const response = await fetch(`${server}/session-status?session_id=${sessionId}&isStripeInLivemode=${IS_STRIPE_IN_LIVEMODE}`)
        const data = await response.json();
        if (data.status === "complete" && data.paymentStatus === "paid" && data.stripeCustomerId) {
            return { isUserPaidSubscriber: true, stripeCustomerId: data.stripeCustomerId };
        }
        else { //data.status === "expired" or edge case where a user goes to a url with a non-existent session_id
            return { errorUserAction: "reload" };
        }
    }
    catch (error) {
        console.error(error);
        return { errorUserAction: "retryCheckout" }
    }
}

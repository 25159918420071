import React from 'react';
import { JSNumberToDayMapping } from '../../Constants';
import '../css/weekview.css';

function Weekdays() {
    const weekdayIndices = [0, 1, 2, 3, 4, 5, 6];
    return (
        <div className='weekdays-container'>
        {weekdayIndices.map((i) =>
            <div className='weekday-label-cell' key={i}>
                <div className='week-day'>
                    {JSNumberToDayMapping[i].Abbrv}
                </div>
            </div>
        )}
        </div>
    )
}

export default Weekdays;
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import BigPictureMode from './BigPictureMode';
import { getWorkEntries } from '../../adapters/flowentry/flowentry-adapter';
import '../css/bigpicture.css';

function TransitionToBigPicture(props) {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [workEntriesInfo, setWorkEntriesInfo] =  useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [displayLoadingText, setLoadingTextDisplay] = useState(false);
    const [displayComputeText, setComputeTextDisplay] = useState(false);
    const [workEntriesCounter, setWorkEntriesCounter] = useState(1);
    const [computeWordsArr, setWordsRemaining] = useState("Click charts for details...".split(''));
    const [computeText, setComputeText] = useState('');

    const [transitionToBigPicture, setTransitionToBigPicture] = useState(false);
    const [showBigPicture, setBigPictureDisplay] = useState(false);

    const search = useLocation().search;

    useEffect(() => {
        const params = new URLSearchParams(search);
        const start = params.get('start') ?? null;
        const end = params.get('end') ?? null;

        setStartTime(start);
        setEndTime(end);
        
        const userId = props.user.uid;
        const requestParams = {
            collectionName: userId,
            from: start,
            to: end
        };
        getWorkEntries(requestParams).then((result) => {
            if (result) {
                setWorkEntriesInfo(result);
            }
            else {
                setErrorMessage('Something went wrong x_x')
            }
        })
    }, [search])

    useEffect(() => {
        if (workEntriesInfo && workEntriesInfo.count > 0) {
            setLoadingTextDisplay(true);
        }
    }, [workEntriesInfo])

    useEffect(() => {
        if (workEntriesInfo && workEntriesInfo.count > 0) {
            let timeout;
            if (workEntriesCounter < workEntriesInfo.count) {
                timeout = setTimeout(() => {
                    setWorkEntriesCounter(prevCount => prevCount + 1);
                }, 100);
            }
            else {
                setComputeTextDisplay(true);
            }
            return () => clearTimeout(timeout);
        }
    }, [workEntriesInfo?.count, workEntriesCounter])

    useEffect(() => {
        if (displayComputeText) {
            let timeout;
            let words = computeWordsArr;
            if (computeWordsArr.length > 0) {
                timeout = setTimeout(() => {
                    let word = words.shift();
                    setComputeText(computeText + word);
                    setWordsRemaining(words);
                }, 75);
            }
            else {
                setTransitionToBigPicture(true);
            }
            
            return () => clearTimeout(timeout);
        }
    }, [displayComputeText, computeText])

    useEffect(() => {
        if (transitionToBigPicture) {
            setLoadingTextDisplay(false);
            setBigPictureDisplay(true);
        }
    }, [transitionToBigPicture])


    return (
        <CSSTransition
        appear={true}
        in={true}
        timeout={2200}
        classNames={'big-pic-transition-page'}
        >
            <div className='big-pic-transition-page'>
                <CSSTransition
                    in={errorMessage != null}
                    timeout={{enter: 2000}}
                    classNames={'error-container'}
                    >
                    { errorMessage != null ?
                    <div className='error-container'>
                        <p className='info-text'>{errorMessage}</p>
                    </div>
                    : <></>
                    }                   
                </CSSTransition>
                <CSSTransition
                    in={displayLoadingText}
                    timeout={{enter: 1000, exit: 50}}
                    classNames={'loading-text-container'}
                    >
                    <div className='loading-text-container'>
                        <p className='info-text'>Gathering work facing flows {workEntriesCounter}</p>
                        <p className='info-text'>{computeText}</p>
                    </div>
                </CSSTransition>
                <CSSTransition
                in={showBigPicture}
                mountOnEnter
                timeout={2000}
                classNames={'big-picture-page'}
                >
                    <BigPictureMode timeOfDay={props.timeOfDay}
                        startTime={startTime}
                        endTime={endTime}
                        user={props.user}
                    />
                </CSSTransition>
            </div>
        </CSSTransition>
    )
}

export default TransitionToBigPicture;
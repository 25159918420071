import React from 'react'
import './css/formnavbuttons_mobile.css'

function PrevMobile(props) {
    return (
        props.visible &&
        <button className='nav-btn-mobile prev-mobile' onClick={props.onClick} style={props.customStyles}>
            &larr;
        </button>
    )
}

export default PrevMobile
import React from 'react';
import Tag from './Tag';
import WorkTags from './WorkTags';

function FlowentryCardTags(props) {
    return (
        <div className='tags-container'>
            {
                isWorkTagVisible(props.entryState) &&
                <WorkTags tagText={props.entryState.work} color={props.tagColor} workCauses={props.entryState.workCauses ?? ""} />
            }
            {
                props.entryState.feeling &&
                <Tag color={props.tagColor} text={`Feeling ${props.entryState.feeling}`}/>
            }
            {
                props.entryState.energy &&
                <Tag color={props.tagColor} text={`${props.entryState.energy} Energy`}/>
            }
        </div>
    )
}

const isWorkTagVisible = (entryState) => {
    return (entryState.work || entryState.workCauses);
}

export default FlowentryCardTags
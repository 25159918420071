import { useState, useEffect, useContext } from "react";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
    useStripe
} from "@stripe/react-stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import styles from "../css/payments/checkout-form.module.css";
import { PaymentError } from "./PaymentError";
import { CurrentMetadataForUser } from "../../context/CurrentMetadataForUser";
import { createCheckoutSession } from "../../adapters/payments-adapter";
import Loading from "../Loading";
import { PoweredByStripeText } from "./PoweredByStripeText";
import { PROMO_CODE_FAIL_MESSAGE_MAP } from "../../Constants";

export const CheckoutForm = ({ setShowPaymentResult }) => {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const stripe = useStripe();
    const [error, setError] = useState(false);
    const { stripeCustomerId } = useContext(CurrentMetadataForUser);
    const [stripeClientSecret, setStripeClientSecret] = useState(null);

    // URL shape for promo codes: app.normili.com/signup?promo_code="ABCDEF"
    const urlParams = new URLSearchParams(window.location.search);
    const promoCode = urlParams.get('promo_code') || undefined;

    const [promoCodeFailMessage, setPromoCodeFailMessage] = useState();

    useEffect(async () => {
        if (user) {
            const { stripeClientSecret, promoCodeFailMessage } = await createCheckoutSession(user.email, user.uid, stripeCustomerId, promoCode)
            if (stripeClientSecret) {
                setStripeClientSecret(stripeClientSecret);
                setPromoCodeFailMessage(PROMO_CODE_FAIL_MESSAGE_MAP[promoCodeFailMessage]);
            }
            else {
                setError(true); // Setup an alert for this!
            }
        }
    }, [user]);

    // See Stripe docs for details on Embedded Checkout: https://docs.stripe.com/checkout/embedded/quickstart
    return (
        error ?
            <PaymentError
                errorInfoMessage="Looks like our subscription pixies have gone awol chasing fairy dust 🧚"
                errorActionMessage="Try reloading the page to summon them back!"
                buttonText={"Reload"}
                buttonTriggersReload={true}
                setShowPaymentResult={setShowPaymentResult}
            />
            :
            stripeClientSecret ? (
                <div className={styles.paymentsForm} id="checkout">
                    <PoweredByStripeText />

                    {promoCodeFailMessage && <div className={styles.promoCodeError}>{promoCodeFailMessage}</div>}

                    <EmbeddedCheckoutProvider
                        stripe={stripe}
                        options={{ clientSecret: stripeClientSecret }}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                </div>
            )
                : <Loading />
    )
};

export default CheckoutForm;

import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import "../css/auth/google-signin-button.css";
import googleLogo from "../../images/googleLogo.svg";
import { useEffect } from "react";

export const GoogleAuthButton = ({
    setGoogleUser = false,
    showSignup = false,
}) => {
    const auth = getAuth();
    const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);

    useEffect(() => {
        if (setGoogleUser && user) {
            setGoogleUser(user);
        }
    }, [user]);

    return (
        <button className="google-signin-button" onClick={() => signInWithGoogle()}>
            <div className="googleLogoContainer">
                <img src={googleLogo} alt="Google Logo" className="googleLogo" />
            </div>
            <span className="google-text">Continue with Google</span>
        </button>
    );
};

import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import Prev from './Prev'
import PrevMobile from './PrevMobile'
import Next from './Next'
import NextMobile from './NextMobile'
import Question from './Question'
import Finish from './Finish'
import FinishMobile from './FinishMobile'
import Media from 'react-media';
import './css/multistepform.css'
import { Route, withRouter } from 'react-router-dom'
import { FlowManager } from '../helpers/FlowManager'
import FallbackAnswerOption from './FallbackAnswerOption';
import { AnswerFormat } from '../Constants';

class MultiStepForm extends Component {

    constructor(props) {
        super(props)
        
        this.flowManager = new FlowManager(this);        
        this.state = this.flowManager.getInitialState();
        this.highlightNext = false;
        this.isWrapUpClicked = false;

        // route to the url of first flow step.
        const currentPath = this.props.match.url
        let newPath = currentPath.endsWith('checkin/') ? (currentPath + this.state.visibleQuestion.StepId)
            : (currentPath + '/' + this.state.visibleQuestion.StepId);
        this.props.history.replace(newPath, {id : this.state.visibleQuestion.StepId});
    }
    
    componentDidMount() {
        // start listening to navigation via the browser back/fwd buttons.
        this.unlistenBrowserNavigation = this.props.history.listen((location,action) => {
            if (action === 'POP') {
                let id = this.props.location.state.id;
                let actionType = this.flowManager.interpretBrowserNavigation(id);
                if (actionType === 'back') {
                    this.flowManager.loadPreviousQuestion();
                } 
                else if (actionType === 'next') {
                    this.flowManager.loadNextQuestion();
                }
            }
        })

        // start checking to see if browser navigation will take the user out of the check-in flow, and prompt them for confirmation.
        this.unblock = this.props.history.block((location, action) => {
            if(!this.props.isFlowSaved && !location.pathname.includes('take-a-flow')) 
            {
                let prompt = Object.keys(this.state.answeredQuestions).length > 0
                    ? `Sure you wanna leave without saving your responses?`
                    : "Sure you don't wanna flow?"
    
                return window.confirm(prompt)     
            }
        })
    }

    componentWillUnmount() {
        this.unblock();
        this.unlistenBrowserNavigation();
    }

    render() {
        return (
            <Media queries={{
                small: "(max-width: 500px)",
                default: "(min-width: 501px)"
            }}>
            {matches => (
                <>
                {matches.small && this.getComponentForMobileView()}
                {matches.default && this.getComponentForLaptopAndTabletView()}
                </>
            )}
            </Media>
        )
    }

    getComponentForLaptopAndTabletView = () => {
        const jsx = 
        <CSSTransition
        in={!this.isWrapUpClicked}
        timeout={250}
        classNames={'form-container'}
        >
        <div className="form-container">           
            <div className='form-subcontainer'>
                <Prev visible={this.isPrevButtonVisible()} onClick={this.handlePrevClick}/>
                <Route exact path={`/take-a-flow/:id`} render={(props) =>
                <div>
                    <Question question={this.state.visibleQuestion} 
                        selectedAnswers={this.state.answeredQuestions}
                        onAnswered={this.handleAnswerInput}
                    />
                    {this.state.visibleQuestion.FallbackOption && 
                    <FallbackAnswerOption fallbackOption={this.state.visibleQuestion.FallbackOption}
                        onAnswered={this.handleAnswerInput} onNext={this.handleNextClick}
                    />}
                </div>  
                }/>
                <Next visible={!this.flowManager.isCurrentStepLast()} disabled={this.isNextButtonDisabled()} onClick={this.handleNextClick} highlighted={this.isNextButtonHighlighted()}/>
            </div>
            <Finish disabled={this.isFinishButtonDisabled()} highlighted={this.isFinishButtonHighlighted()} onClick={this.handleFormSubmission}/>
        </div>
        </CSSTransition>
        
        return jsx;
    }

    getComponentForMobileView = () => {
        const jsx =
        <CSSTransition
        in={!this.isWrapUpClicked}
        timeout={250}
        classNames={'form-container'}
        >
        <div className="form-container">
            <Route exact path={`/take-a-flow/:id`} render={(props) => 
                <div>
                    <Question question={this.state.visibleQuestion}
                        selectedAnswers={this.state.answeredQuestions}
                        onAnswered={this.handleAnswerInput}
                    />
                    {this.state.visibleQuestion.FallbackOption && 
                    <FallbackAnswerOption fallbackOption={this.state.visibleQuestion.FallbackOption}
                        onAnswered={this.handleAnswerInput} onNext={this.handleNextClick}
                    />}
                </div>
            }/>        
            <div className='nav-container'>
                <PrevMobile visible={this.isPrevButtonVisible()} onClick={this.handlePrevClick}/>                    
                <FinishMobile disabled={this.isFinishButtonDisabled()} highlighted={this.isFinishButtonHighlighted()} onClick={this.handleFormSubmission}/>
                <NextMobile visible={!this.flowManager.isCurrentStepLast()} disabled={this.isNextButtonDisabled()}
                    onClick={this.handleNextClick} highlighted={this.isNextButtonHighlighted()}/>
            </div>
        </div>
        </CSSTransition>

        return jsx;
    }

    handleAnswerInput = (event) => {
        switch(event.target.id) {
            default: // where there is only one answer e.g. in case of radio and textbox input
                this.flowManager.updateAnswer(event.target.value);
                break;

            // run the same code for both kinds of checkbox
            case AnswerFormat.Checkbox:
            case AnswerFormat.CheckboxDetailed:
                if (event.target.checked) {
                    this.flowManager.appendToAnswer(event.target.value);
                }
                else {
                    this.flowManager.removeFromAnswer(event.target.value);
                }
                this.highlightNext = this.state.answeredQuestions[this.state.visibleQuestion.StepId].length > 0
                    ? true : false
                break;
            
            case AnswerFormat.CheckboxAndText:
                if (event.target.type === 'checkbox') {
                    if (event.target.checked) {
                        this.flowManager.appendToCheckboxSelections(event.target.value);
                    }
                    else {
                        this.flowManager.removeFromCheckboxSelections(event.target.value);
                    }
                    this.highlightNext = this.state.answeredQuestions[this.state.visibleQuestion.StepId].checkboxSelections.length > 0
                        ? true : false
                }
                else {
                    this.flowManager.updateCheckboxTextAnswer(event.target.value);
                }
                break;
        }

        // if the answer format for the question was radio, just zoom to the next question without having to click next.
        // the set timeout delay here is temporary, until we can add the proper css transitions.
        if(event.target.type === 'radio' && !this.isNextButtonDisabled()) {
            setTimeout(() => {
                this.handleNextClick()
              }, 500);
        }
    }

    isPrevButtonVisible = () => {
        if (this.flowManager.isGoingBackAllowed()) {
            return true;
        }
        return false;
    }

    isNextButtonDisabled = () => {
        if (!this.flowManager.isNextNavigationAllowed()) {
            return true;
        }
        return false;
    }

    isNextButtonHighlighted = () => {
        return this.flowManager.isCurrentStepAnswered();
    }

    isFinishButtonDisabled = () => {
        if (!this.flowManager.isFinishingFlowAllowed()) {
            return true;
        }
        return false;
    }

    isFinishButtonHighlighted = () => {
        // if next button is disabled, then it means there are no more questions left to ask 
        // And if finish button is enabled, then that's when it should be highlighted.
        if (this.isFinishButtonDisabled() !== true && this.flowManager.isCurrentStepLast()) {
            return true;
        }
        return false;
    }

    handlePrevClick = () => {
        //reset next button's highlight
        this.highlightNext = false;

        /* Note: this function triggers the POP state. 
        The browserNav event listening to that will load 
        the previous question, hence we don't call loadPreviousQuestion() explicitly.
        */
        this.props.history.goBack();
    }

    handleNextClick = () => {
        //reset next button's highlight
        this.highlightNext = false;

        const nextId = this.flowManager.loadNextQuestion();

        if (nextId) {
            this.props.history.push(`${this.props.match.url}/${nextId}`, {id : nextId});
        }
        else {
            console.error("Can't go next, id undefined. Check children.");
        }
    }

    handleFormSubmission = () => {
        const flowData = this.flowManager.getDataForSubmission();
        this.isWrapUpClicked = true;
        this.props.submitAnswers(flowData);
    }
}

export default withRouter(MultiStepForm)

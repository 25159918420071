import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const StripeProvider = ({ children }) => {
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

// We need to wrap the checkout form in the Elements component.
// And we need to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
// https://docs.stripe.com/stripe-js/react#elements-provider
import React, { Component } from 'react'
import './css/checkbox.css'

class Checkbox extends Component {
    constructor(props) {
        super(props)

        this.checkedStyle = {
            "backgroundColor": "rgb(73, 197, 210)",
            "paddingLeft" : "0.673rem",
            "paddingRight": "0.673rem",
        }
        this.defaultStyle = {
            "paddingLeft" : "1.3rem",
            "paddingRight": "1.3rem",
        }
        let initialStyle = props.checked ? this.checkedStyle : this.defaultStyle
        this.state = {
            "checked" : props.checked,
            "style" : initialStyle
        }
    }

    render() {
        return (
            <label>
                <div className='checkbox-tile default-size' style={this.state.style}>
                    <input type='checkbox'
                        id={this.props.id}
                        name={this.props.questionId}
                        value={this.props.value}
                        onChange={(e) => {this.props.onAnswered(e); this.handleChange(e)}}
                        checked={this.state.checked}
                    />
                    <span className='checkbox-content'>
                        {this.state.checked && <span className='checkmark'><b>&#10003;</b></span>}
                        {this.props.value}
                    </span>
                </div>
            </label>
        )
    }

    handleChange = (e) => {        
        let style = e.target.checked ? this.checkedStyle : this.defaultStyle        
        this.setState({
            "checked":e.target.checked,
            "style":style
        })
    }
}

export default Checkbox

import React from 'react';
import './css/formnavbuttons_mobile.css';
import useAnalyticsEventTracker from '../AnalyticsEventTracker';

function FinishMobile(props) {
    let highlightStyle = props.highlighted ? 
    {
        borderWidth: '0.2rem',
        fontSize: '1.2rem',
        width: "6.5rem",
        borderColor: "rgb(245, 207, 240, 1)",
        color: "white",
        fontWeight: "bold",
    } : {}
    
    const wrapUpClickEventTracker = useAnalyticsEventTracker('Checkins');
    const handleClick = () => {
        wrapUpClickEventTracker('Finish checkin');
        props.onClick();
    }

    return (
        <button style={highlightStyle} className="nav-btn-mobile finish-mobile" disabled={props.disabled} onClick={handleClick}>
            Wrap up
        </button>
    )
}

export default FinishMobile
import { FlowentryCardColors } from "../Colors";
import { EntriesListPageBGColors } from '../Colors';
import { AnswerToWorkCategoryMap, MonthShorthandToNumberMapping, PleasantFeelings, UnpleasantFeelings } from "../Constants";

export const getFlowentryCardSummary = (entry) => {
    const energy = entry.answeredQuestions.find(entry => entry.category === "energy");
    const feeling = entry.answeredQuestions.find(entry => entry.category === "feeling");
    const loc = entry.answeredQuestions.find(entry => entry.category === "location_of_cause");
    const workInquiry = entry.answeredQuestions.find(entry => entry.category === "work_inquiry")
    let emotionalState = {
        "energy": energy ? energy.answer : null,
        "feeling": feeling ? feeling.answer : null,
        "loc": loc ? loc.answer : null
    };

    if ((loc && loc.answer)) {
        if (loc.answer === 'Yes' || loc.answer === 'Things at work' || loc.answer.toLowerCase().includes('both')) {
            emotionalState["work"] = "Work related";
        }
    }
    if (workInquiry && workInquiry.answer) {
        // Because we don't want to label these where the user specifically indicated feeling pleasant/unpleasant due to reasons outside of work
        if (emotionalState.feeling === 'Alright') {
            if (workInquiry.answer === 'Not too shabby') {
                emotionalState["work"] = "Work not too shabby";     
            }
            else if (workInquiry.answer === 'Could be better') {
                emotionalState["work"] = "Work could be better";
            }
            else if (workInquiry.answer === `I'm loving it!`) {
                emotionalState["work"] = "Loving it at work";
            }
            else if (workInquiry.answer === 'It sucks') {
                emotionalState["work"] = "Work sucks";
            }
            else {
                console.warn(`Unhandled value: ${workInquiry.answer}, entry id ${entry._id}`);
            }
        }
    }

    const workCauses = entry.answeredQuestions.find(entry => entry.category === "work_cause_exploration");
    if (workCauses && !workCauses.answer.includes('something else')) {
        emotionalState["workCauses"] = workCauses.answer;
    }

    return emotionalState;
}

export const calculateEntryColor = (emotionalState) => {
    switch(emotionalState.energy) {
        case 'High':
            switch(emotionalState.feeling) {
                case 'Awesome!':
                case 'Good':
                    return FlowentryCardColors.greenColorScheme;

                case 'Meh':
                    return FlowentryCardColors.lightRedColorScheme;

                case 'Not Good':
                case 'Awful':
                    return FlowentryCardColors.redColorScheme;
                
                case 'Alright':
                    switch(emotionalState.work) {
                        case 'Work not too shabby':
                            return FlowentryCardColors.lightGreenColorScheme;

                        case 'Work could be better':
                            return FlowentryCardColors.lightRedColorScheme;

                        case `Loving it at work`:
                            return FlowentryCardColors.greenColorScheme;

                        case 'Work sucks':
                            return FlowentryCardColors.redColorScheme;

                        default:
                            return FlowentryCardColors.lightGreenColorScheme;
                    }
            }
        
        case 'Medium':
            switch(emotionalState.feeling) {
                case 'Awesome!':
                case 'Good':
                    return FlowentryCardColors.greenColorScheme;

                case 'Meh':
                    return FlowentryCardColors.lightRedColorScheme;

                case 'Not Good':
                case 'Awful':
                    return FlowentryCardColors.redColorScheme;
                
                case 'Alright':
                    switch(emotionalState.work) {
                        case 'Work not too shabby':
                            return FlowentryCardColors.lightGreenColorScheme;

                        case 'Work could be better':
                            return FlowentryCardColors.lightRedColorScheme;

                        case `Loving it at work`:
                            return FlowentryCardColors.greenColorScheme;

                        case 'Work sucks':
                            return FlowentryCardColors.redColorScheme;

                        default:
                            return FlowentryCardColors.lightGreenColorScheme;
                    }
            }

        case 'Low':
            switch(emotionalState.feeling) {
                case 'Awesome!':
                case 'Good':
                    return FlowentryCardColors.greenColorScheme;

                case 'Meh':
                case 'Alright':
                    if (emotionalState.work === "Work not too shabby" || emotionalState.work === "Loving it at work") {
                        return FlowentryCardColors.lightGreenColorScheme;
                    }
                    // In case a person said Low and I'm alright really but then chose to exit the flow without answering work question
                    if (!emotionalState.work && emotionalState.loc === "I'm alright really") {
                        return FlowentryCardColors.lightGreenColorScheme;
                    }
                    return FlowentryCardColors.lightRedColorScheme;

                case 'Not Good':
                case 'Awful':
                    return FlowentryCardColors.redColorScheme;
            }
        
        case 'Drained':
            return FlowentryCardColors.redColorScheme;

    }
}

export const getQuestionByCategory = (entry, category) => {
    const result = entry.answeredQuestions.find(entry => entry.category === category);
    if (!result) {
        console.warn(`Did not find an element matching ${category} in entry ${entry._id}`);
    }
    return result;
}

export const getBackgroundColorByTimeOfDay = (timeOfDay) => {
    switch(timeOfDay) {
        case 'morning':
            return EntriesListPageBGColors.morning
        case 'afternoon':
            return EntriesListPageBGColors.afternoon
        case 'evening':
            return EntriesListPageBGColors.evening
        default:
            return EntriesListPageBGColors.night
    }
}

export const getDateFromMongoId = (id) => {
    // got this conversion method from Stackoverflow, not sure how it works.
    const timestamp = id.toString().substring(0,8);
    const datetime = new Date( parseInt(timestamp, 16) * 1000 );
    return datetime;
}

export const getDateTimeFromId = (id) => {
    const datetime = getDateFromMongoId(id); 
    const date = datetime.toDateString().split(' ');
    // match format - May 12, Tues
    const formattedDate = `${date[1]} ${date[2]}, ${date[0]}`; 

    const standardTime = datetime.toLocaleTimeString();
    // match format - Hour:minutes am/pm
    const endIndex = standardTime.length < 11 ? 4 : 5; // because a ':' can be present at the end of the substring if hour is in single digits
    const formattedTime = standardTime.slice(0,endIndex) + standardTime.slice(standardTime.length-3);
    return `${formattedDate} - ${formattedTime}`;
}

export const getAbbreviatedDateFromId = (id) => {
    const datetime = getDateFromMongoId(id);
    const date = datetime.toDateString().split(' ');
    // match format - 5/12 Tues
    const monthShorthand = date[1];
    const formattedDate = `${MonthShorthandToNumberMapping[monthShorthand]}/${date[2]} ${date[0]}`;
    return formattedDate;
}

export const getDateAndColorForEntries = (entries) => {
    let dates = [];
    entries.forEach(entry => {
        let entryState = getFlowentryCardSummary(entry);
        let entryColors = calculateEntryColor(entryState);
        const isEntryWorkRelated = entryState["workCauses"] ? true : false;
        // i.e. even if their feeling wasn't directly stemming from work, they did answer a work inquiry
        const isFlowWorkFacing = () => {
            if (isEntryWorkRelated) {
                return true;
            }
            else {
                const workFacingEntry = entry.answeredQuestions.find(entry => entry.category === "work_inquiry")
                if (workFacingEntry?.answer) {
                    return true;
                }
                return false;
            }
        }
        dates.push({
            entry: entry,
            date: getDateFromMongoId(entry._id),
            color: entryColors,
            isDirectlyWorkRelated: isEntryWorkRelated,
            isWorkFacing: isFlowWorkFacing()
        });
    });
    return dates;
}

export const getFlowEntriesForCauseByImpact = (flows, cause, category, impactType) => {
    const mappedCategory = AnswerToWorkCategoryMap[category] ?? category; // useful when category doesn't need to be mapped
    // first filter by cause and category
    const filteredData = filterFlowsByCategory(flows, mappedCategory, cause);

    // then filter by impact
    const filterResultsByImpact = filteredData.filter(item => {
        const answeredQuestions = item.entry.answeredQuestions;
        if (impactType === 'Uplifting') {
            const upliftingResults = answeredQuestions.some(answeredQuestion => 
                (answeredQuestion.category === 'feeling' && PleasantFeelings.includes(answeredQuestion.answer))
                || (answeredQuestion.category === 'work_inquiry' && PleasantFeelings.includes(answeredQuestion.answer))
            )
            return upliftingResults;
        }
        else if (impactType === 'Depleting') {
            const depletingResults = answeredQuestions.some(answeredQuestion => 
                (answeredQuestion.category === 'feeling' && UnpleasantFeelings.includes(answeredQuestion.answer))
                || (answeredQuestion.category === 'work_inquiry' && UnpleasantFeelings.includes(answeredQuestion.answer))
                || (answeredQuestion.category === 'energy' && (answeredQuestion.answer === "Drained")
                || (answeredQuestion.category === 'location_of_cause' && answeredQuestion.answer === "Things at work"))
            )
            return depletingResults;
        }
    })

    return filterResultsByImpact;
}

export const filterFlowsByCategory = (flows, category, answer) => {

    const flowsForCategory = flows.filter(item => {
        const answeredQuestions = item.entry.answeredQuestions;
        return (item.isWorkFacing &&
            answeredQuestions.some(answeredQuestion => (answeredQuestion.category === category))
        )
    });

    if (answer) {
        const flowsContainingAnswer = flowsForCategory.filter(item => {
            const answeredQuestions = item.entry.answeredQuestions;
            return answeredQuestions.some(answeredQuestion => (answeredQuestion.category === category
                && (answer === 'Not specified' 
                ? answeredQuestion.answer === "" 
                : answeredQuestion.answer.includes(answer)))
            )
        })
        return flowsContainingAnswer;
    }
    return flowsForCategory;
}

// This will be true if a user elaborated upon work reasons affecting their emotional state. 
export const doesFlowCaptureWorkDetails = (flow) => {
    const workCauses = flow.answeredQuestions.find(answeredQuestion => answeredQuestion.category === "work_cause_exploration");
    if (workCauses && workCauses.answer) {
        return true;
    }
    return false;
}

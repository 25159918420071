import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FlowentryCard from './FlowentryCard';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import '../css/checkins_list.css';

// This component takes a list of entries and displays them.
// Update 9/21/24: It will now also handle what happens when an item in the list is clicked.
function CheckinsList(props) {
    let history = useHistory();
    let location = useLocation();

    const [sortedFlows, setSortedFlows] = useState();

    useEffect(() => {
        if (props.entries) {
            setSortedFlows(props.entries.sort((a, b) => a.date - b.date)); // ascending order
        }
    }, [props.entries])

    const getView = (searchString) => {
        const params = new URLSearchParams(searchString);
        return params.get('view') ?? 'M'; // Month as default
    }

    const onItemClick = (id, entry) => {
        history.push(`${location.pathname}/${id}`, 
        {
            id: id,
            entry: entry,
            flows: sortedFlows,
            view: getView(location.search)
        });
    }

    return (
        <TransitionGroup component={null}>
            <CSSTransition
                key={uuidv4()}
                exit={false} //Stops any exit animation classes from being applied
                timeout={500}
                classNames={props.clickState === 'next' ? 'slide-next' : 'slide-prev'}
            >
                <div className='checkins-list-container'>
                    {sortedFlows &&
                        sortedFlows.map((item) =>
                            <FlowentryCard key={item.entry._id} entry={item.entry} onClick={onItemClick}/>
                        )}
                </div>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default CheckinsList;
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../css/bubblechart.css';
import { bubbleColors, ImpactColors } from '../../Colors';
import InsightHeading from './InsightHeading';

function BubbleChart(props) {
    const svgRef = useRef();

    const trimText = (text) => {
        // if the text is "something else : abc", we will keep abc
        if (text.includes('else :')) {
            let index = text.indexOf(':');
            text = text.slice(index + 1);
        }
        return text;
    }

    const truncateTextIfOverflow = (textNode, data, i) => {
        let maxWidth = 2 * data[i].r - 10;
        while (textNode.getBBox().width > maxWidth) {
            textNode.textContent = textNode.textContent.slice(0, -4) + "...";
        }
    }

    useEffect(() => {
        var diameter = Math.min(window.innerWidth, window.innerHeight);
        var color = d3.scaleOrdinal(bubbleColors[props.impactType] ?? d3.schemeCategory10);
        var bubble = d3.pack(props.data)
            .size([diameter, diameter])
            .padding(1);
    
        var svg = d3.select(svgRef.current)
                .attr("width", `100%`)
                .attr("height", `100%`)
                .attr("class", "bubble")
                .attr('viewBox', `${0} ${0} ${diameter} ${diameter}`)
                .style('overflow', 'visible')
    
        var nodes = d3.hierarchy(props.data)
                .sum(d=> d.count)
                .sort((a,b) => d3.descending(a.value, b.value))

        var node = svg.selectAll(".node")
                .data(bubble(nodes).descendants())
                .enter()
                .filter(d => !d.children)
                .append("g")
                .attr("class", "node")
                .attr("transform", d => `translate(${d.x}, ${d.y})`);
    
        node.append("circle")
                .attr("r", d => d.r)
                .style("fill", (d,i) => color(i))
                .append('title')
                    .text(d => d.data._id);
    
        var text = node.append("text")
                .attr("dy", ".2em")
                .style("text-anchor", "middle")
                .text(d => trimText(d.data._id, d.r).substring(0, d.r / 2.5))
                .attr("font-size", d => d.r/5)
                .attr('font-weight', 500)
                .attr("fill", "white")
        
        const data = node.data();
        text.nodes().forEach((textElem, i) => {
            truncateTextIfOverflow(textElem, data, i);
        });
        
        node.append("text")
            .attr("dy", "1.3em")
            .style("text-anchor", "middle")
            .text(d => d.data.count)
            .attr("font-size", d => d.r/5)
            .attr('font-weight', 500)
            .attr("fill", "white")
            .append('title')
                .text(d => d.data._id);

        node.on('click', function() {
            const causeText = d3.select(this).select('circle').select('title').text();
            props.onClick(causeText, props.heading, props.impactType);
        });

    }, [props.data])

    return (
        <div id='chartDiv'>
            <div id='bubble-chart' style={{"boxShadow": `inset 0 0 70px ${ImpactColors[props.impactType]}`}}>
                <svg ref={svgRef}/>
            </div>
        </div>
    )
}

export default BubbleChart
import React, { useState, useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import "./App.css";
import { FirebaseConfig } from "./Constants";
import { getCustomClaims } from "./helpers/FirebaseCustomClaimsHelper";
import { CurrentMetadataForUser } from "./context/CurrentMetadataForUser";
import { RoutesForSignedOutUser } from "./routes/RoutesForSignedOutUser";

// Firebase
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { saveUserMetadataToFirebase } from "./auth/firebase-adapter";

// Components
import TimeContainer from "./TimeContainer";
import Header from "./components/Header";
import Onboarding from "./components/Onboarding";
import { AuthLandingPage } from "./components/authentication/AuthLandingPage";
import { PaymentsContainer } from "./components/payments/PaymentsContainer";
import { AppBackground } from "./components/AppBackground";
import { RoutesForOnboardedUser } from "./routes/RoutesForOnboardedUser";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getReminderSchedule } from "./adapters/reminders-adapter";

const TRACKING_ID = "G-EYM7V9RY0D"; // Tracking id for checkins property
ReactGA.initialize(TRACKING_ID);

function App() {
    firebase.initializeApp(FirebaseConfig);
    const auth = getAuth();
    const [user, loading] = useAuthState(auth);
    // hasVerifiedCustomClaims when true tells us that the app has done the necessary work to gather the source of truth for what a user's starting
    // state is upon login. Based on that fact, we then route the user to the right place from the get go. As a result, the user won't see a weird
    // flicker from seeing the sign-in or onboarding screen for 1 sec and then being navigated to the right place.
    const [hasVerifiedCustomClaims, setHasVerifiedCustomClaims] = useState(false);

    let timeOfDay = new TimeContainer().getTimeOfDay();

    const location = useLocation()

    const {
        isOnboardingCompleted,
        isUserPaidSubscriber,
        setCurrentMetadataForUser,
    } = useContext(CurrentMetadataForUser);

    const finishOnboarding = () => {
        try {
            if (user) {
                const onboardedDate = new Date();
                saveUserMetadataToFirebase({ onboarded: true, onboardedDateString: onboardedDate.toISOString() }, user.accessToken).then(
                    async (response) => {
                        if (response && response.ok) {
                            setCurrentMetadataForUser({ isOnboardingCompleted: true, onboardedDate: onboardedDate });
                        }
                        else {
                            // TODO: Show error message on UI?
                            const message = await response.text();
                            console.error(message);
                        }
                    }
                );
            }
        }
        catch (e) {
            console.error(e.message);
        }
    };

    useEffect(() => {
        if (loading) {
            console.log("app is loading");
        }

        if (!user) {
            return;
        }

        const customClaims = user.reloadUserInfo.customAttributes;
        if (customClaims) {
            const {
                onboarded = false,
                onboardedDateString = undefined,
                isUserPaidSubscriber = false,
                stripeCustomerId = undefined
            } = getCustomClaims(customClaims);

            setCurrentMetadataForUser(
                {
                    isOnboardingCompleted: onboarded,
                    onboardedDate: onboardedDateString ? new Date(onboardedDateString) : null,
                    isUserPaidSubscriber: isUserPaidSubscriber,
                    stripeCustomerId: stripeCustomerId,
                }
            );
        }

        // Check if a reminder schedule exists for user
        getReminderSchedule(user.uid).then(result => {
            if (result.error) {
                setCurrentMetadataForUser({ remindersOn: false })
                if (!result.error.includes('does not exist')) {
                    // This means the schedule exists but could not be fetched for some reason
                    setCurrentMetadataForUser({ remindersOn: undefined});
                }
            }
            else {
                setCurrentMetadataForUser({
                    remindersOn: result.State === 'ENABLED' ? true : false
                });
            }
        })

        setHasVerifiedCustomClaims(true);
    }, [user]);

    return (
        !loading &&
        (user && hasVerifiedCustomClaims ? (
            <>
                {/* Payment Screen: User is neither paid nor onboarded */}
                {(!isUserPaidSubscriber && !isOnboardingCompleted) && (
                    <Switch>
                        <Route>
                            <AuthLandingPage>
                                <PaymentsContainer />
                            </AuthLandingPage>
                        </Route>
                    </Switch>
                )}

                {/* Onboarding: User paid but not onboarded yet */}
                {(isUserPaidSubscriber && !isOnboardingCompleted) && (
                    <AppBackground timeOfDay={timeOfDay}>
                        <Header />
                        <Switch>
                            <Route>
                                <Onboarding
                                    userId={user.uid}
                                    timeOfDay={timeOfDay}
                                    finishOnboarding={finishOnboarding}
                                />
                            </Route>
                        </Switch>
                    </AppBackground>
                )}

                {/* User paying and onboarded, or Past paying user and onboarding completed */}
                {isOnboardingCompleted && (
                    <AppBackground timeOfDay={timeOfDay}>
                        {// Use Header except on /get-subscription route
                            location.pathname !== "/get-subscription" && <Header />
                        }
                        <RoutesForOnboardedUser timeOfDay={timeOfDay} />
                    </AppBackground>
                )}
            </>
        ) : (
            // Routes a signed out user can access
            <AuthLandingPage>
                <RoutesForSignedOutUser />
            </AuthLandingPage>
        ))
    );
}

export default App;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./css/header.css";
import { AccountCircle } from "./AccountCircle";
import { CurrentMetadataForUser } from "../context/CurrentMetadataForUser";
import { useLocation, useHistory } from "react-router-dom";

function Header() {
    const { isOnboardingCompleted } = useContext(CurrentMetadataForUser);
    const location = useLocation();
    const history = useHistory();

    const handleNewFlowClick = () => {
        history.push("/take-a-flow");
    }

    return (
        <header className="header">
            {isOnboardingCompleted && (
                <div style={{ display: "flex" }}>
                    {location.pathname !== '/flows' ?
                        <div className="header-item-container" style={{ marginRight: "1rem" }}>
                            <Link className="header-item" to="/">
                                Normili
                            </Link>
                        </div>
                        : <div style={{ height: "fit-content", marginRight: "1rem" }}>
                            <button className="header-btn" onClick={handleNewFlowClick}>
                                <span>➕ Flow</span>
                            </button>
                        </div>
                    }
                    <div className="header-item-container">
                        <Link className="header-item" to="/flows">
                            My Flows
                        </Link>
                    </div>
                </div>
            )}
            <div style={{ marginLeft: "auto" }}>
                <AccountCircle />
            </div>
        </header>
    );
}

export default Header;

import React from 'react'
import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { scaleBand, scaleLinear } from 'd3';
import { ImpactColors } from '../../Colors';
import '../css/groupedbarchart.css';

function GroupedBarChart(props) {
    const svgRef = useRef();
    const chartWidth = 300;
    const chartHeight = 375;
    const barHeight = 20;
    const barLabelFontSize = 16;

    const getNumBars = (d) => {
        if (d.count1 > 0 && d.count2 > 0) {
            return 2;
        }
        else {
            return 1;
        }
    }

    /* This will return an object containing N base positions on the Y axis where N is the number of elements.
    The base position is used to draw the first bar. The second bar position (if present) & text label positions for each element
    are calculated based off of these bases.
    */
    const calculateBaseYPositions = (data, yScale) => {
        let yPositions = {};
        const firstElementId = data[0]._id;
        let firstPosition = yScale(firstElementId);
        yPositions[firstElementId] = firstPosition;

        let totalExtraSpace = 30; //starting value
        data.forEach(element => {
            if (element.count1 === 0) {
                totalExtraSpace += barHeight;
            }
            if (element.count2 === 0) {
                totalExtraSpace += barHeight;
            }
        });
        const paddingSpace = totalExtraSpace/(props.data.length - 1); // since no space needs to be added after last element
        
        for(let i = 1; i < data.length; i++) {
            let id = data[i]._id;
            const previousElementId = data[i-1]._id
            let position = yPositions[previousElementId] + (getNumBars(data[i-1]) * barHeight) + paddingSpace + barLabelFontSize;
            yPositions[id] = position;
        }

        return yPositions;
    }

    useEffect(() => {
    
    // Step 1: Set up svg container
    const svg = d3.select(svgRef.current)
        .attr('width', '100%')
        .attr('height', '90%')
        .attr('viewBox', `${0} ${0} ${chartWidth} ${chartHeight*0.95}`)
        .attr('class', 'grouped-bar-chart')
        .style('overflow', 'visible')

    // How rectangles will be drawn along X axis
    const xOffset = 20; // left-right padding for elements
    const xValue1 = d => d.count1;
    const xValue2 = d => d.count2;
    // Find the maximum value between xValue1 and xValue2 across the entire dataset
    const maxValue = d3.max(props.data, d => Math.max(xValue1(d), xValue2(d)));
    const xScale = scaleLinear()
        .domain([0, maxValue])
        .range([0, chartWidth - 2*xOffset]);

    // How rectangles will be positioned on the Y axis
    const yValue = d => d._id;
    const yScale = scaleBand()
        .domain(props.data.map(yValue))
        .range([0, chartHeight])
        .padding(0.70)

    let yPositions = calculateBaseYPositions(props.data, yScale);

    let mainGroups = svg.selectAll('g')
        .data(props.data)
        .enter()
        .append('g')
        .attr('class', 'main-group')

    mainGroups.append('text')
        .text(d => d._id)
        .attr('class', 'grouped-rect-label')
        .attr('x', xOffset)
        .attr('y', d => yPositions[d._id])
        .attr('dy', "-0.5em")

    // Add a subgroup containing a green rectangle & it's count label, for each object where count1 is present
    let subGroups1 = mainGroups.selectAll('.rect-group-green')
        .data(d => {
            if (d.count1 && d.count1 > 0) {
                return [d]
            } else {
                return []
            }
        }).enter().append('g');

    subGroups1
        .append('rect')
        .attr('x', xOffset)
        .attr('y', d => yPositions[d._id])
        .attr('width', d => xScale(xValue1(d)))
        .attr('height', barHeight)
        .style('fill', ImpactColors.Uplifting)
        .style("cursor", "pointer")
        .append('title').text(d => `${d._id}:Uplifting`);

    subGroups1
        .append('text')
        .text(xValue1)
        .attr('class', 'grouped-rect-value')
        .attr('y', d => yPositions[d._id])
        .attr('dy', '1em')
        .attr('x', d => xScale(xValue1(d)) + xOffset)
        .attr('dx', d => {
            const dy = d.count1.toString().length > 1 ? '-1.4em' : '-0.75em'; 
            return dy
        });

    // Add sub groups containing red rectangles & their labels for all objects where count2 is present
    const offset = 20;
    let subGroups2 = mainGroups.selectAll('.rect-group-red')
        .data(d => {
            if (d.count2 && d.count2 > 0) {
                return [d]
            } else {
                return []
            }
        }).enter().append('g');

    subGroups2
        .append('rect')
        .attr('x', xOffset)
        .attr('y', d => {
            let y = (d.count1 && d.count1 > 0) 
                ? yPositions[d._id] + offset
                : yPositions[d._id]
            return y;
        })
        .attr('width', d => xScale(xValue2(d)))
        .attr('height', barHeight)
        .style('fill', ImpactColors.Depleting)
        .style("cursor", "pointer")
        .append('title').text(d => `${d._id}:Depleting`);

    subGroups2
        .append('text')
        .text(xValue2)
        .attr('class', 'grouped-rect-value')
        .attr('y', d => {
            let y = (d.count1 && d.count1 > 0) 
                ? yPositions[d._id] + offset
                : yPositions[d._id]
            return y;
        })
        .attr('dy', '1em')
        .attr('x', d => xScale(xValue2(d)) + xOffset)
        .attr('dx', d => {
            const dy = d.count2.toString().length > 1 ? '-1.4em' : '-0.75em'; 
            return dy
        })

        // Add on click event handler to all pie slices
        mainGroups.selectAll("rect").on("click", function(d) {
            const barTitle = d3.select(this).select('title').text();
            if (props.onClick) {
                props.onClick(barTitle);
            }
        });

    }, [props.data])

    return (
        <div id='chartDiv'>
            <div className='horizontal-legend-container'>
                <div className='chart-legend'> 
                    <div className='color-box'
                        style={{backgroundColor: ImpactColors.Uplifting}}/>
                    <span className='barchart-legend-text'>Uplifting</span>
                </div>
                <div className='chart-legend'>
                    <div className='color-box' 
                        style={{backgroundColor: ImpactColors.Depleting}}/>
                    <span className='barchart-legend-text'>Depleting</span>
                </div>
            </div>
            <svg ref={svgRef}/>
        </div>
    )
}

export default GroupedBarChart;
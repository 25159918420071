import React from 'react';
import { Link } from 'react-router-dom';
import './css/fallbacklink.css';

function FallbackAnswerOption(props) {
    const onClick = (e) => {
        props.onAnswered(e);
        props.onNext();
    }

    return (
        <div>
            <button className='link' value={props.fallbackOption} onClick={onClick}>
                {props.fallbackOption}
            </button>
        </div>
    )
}

export default FallbackAnswerOption

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import TimeContainer from '../TimeContainer';
import { GreetingColors } from '../Colors';
import { CheckinType } from '../Constants';
import { CSSTransition } from 'react-transition-group';
import useAnalyticsEventTracker from '../AnalyticsEventTracker';
import './css/greeting.css';

function TimeSpecificGreeting(props) {
    var greeting, message, greetingStyle, textStyle;
    let timeOfDay = props.timeOfDay ?? new TimeContainer().getTimeOfDay()

    switch(timeOfDay) {
        case 'morning':
            greeting = props.userName ? `Good morning ${props.userName}` : "Good morning";
            message = props.flowType === CheckinType.EntryFlow ? "Go with the flow" : "Breathe and flow";
            greetingStyle = GreetingColors.morning
            textStyle = {
                color: "white"
            }
            break;
        
        case 'afternoon':
            greeting = props.userName ? `Good afternoon ${props.userName}` : "Good afternoon";
            message = props.flowType === CheckinType.EntryFlow ? "How's it going?" : "Breathe and Flow" ;
            greetingStyle = GreetingColors.afternoon
            break;
        
        case 'evening':
            greeting = props.userName ? `Good evening ${props.userName}` : "Good evening"
            message = props.flowType === CheckinType.EntryFlow ? "How has your day been?" : "Feel and Flow" ;
            greetingStyle = GreetingColors.evening
            break;
        
        default:
            greeting = props.userName ? `Hey there, ${props.userName}` : "Hey there"
            message = "What's on your mind?";
            greetingStyle = GreetingColors.night
    }

    const submissionGreeting = 'Thanks for checking in';
    const submissionMessage = `You're welcome back anytime`;

    const location = useLocation();
    let initialDisplayValue = location.state ? location.state.checkin : false;
    const [showSubmissionGreeting, setSubmissionGreetingDisplay] = useState(initialDisplayValue);
    const [clicked, setClickState] = useState(false);

    const getEbbFlowGreetingStyle = () => {
        if (showSubmissionGreeting) {
            return { animation: "textFadeOut 4s linear 2s forwards" };
        }
        else {
            return { display : "none" };
        }
    }

    const getMainGreetingStyle = () => {
        if (showSubmissionGreeting || !props.userName) {
            return { display: "none" };
        }
        else {
            return { animation: "textFadeIn 2s linear" }
        }
    }

    const greetingClickEventTracker = useAnalyticsEventTracker('Checkins');
    const history = useHistory();
    const handleClick = () => {
        setClickState(false); // reset to default
        greetingClickEventTracker('Begin flow');
        history.push('/take-a-flow');
    }

    return (
        <div className="greeting-container">
            <CSSTransition
            in={!clicked}
            timeout={{exit: 1000}}
            classNames={'greeting'}
            onExited={handleClick}
            unmountOnExit={true}
            >
                <div className="greeting" style={greetingStyle} onClick={()=>setClickState(true)}>
                    <CSSTransition
                    in={!clicked}
                    timeout={800}
                    classNames={'text-container'}
                    >
                        <>
                        <div>
                            <div className='text-container' style={getEbbFlowGreetingStyle()} onAnimationEnd={() => setSubmissionGreetingDisplay(false)}>
                                <h3 id='main-greeting' className="greeting-text" style={textStyle}>{submissionGreeting}</h3>
                                <h4 id='sub-greeting' className="greeting-text" style={textStyle}>{submissionMessage}</h4>
                            </div>
                            <div className='textContainer' style={getMainGreetingStyle()}>
                                <h2 id='main-greeting' className="greeting-text" style={textStyle}>{greeting}</h2>
                                <h4 id='sub-greeting' className="greeting-text" style={textStyle}>{message}</h4>
                            </div>
                        </div>
                        </>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </div>
    )
}

export default TimeSpecificGreeting
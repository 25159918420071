import { PaymentsContainer } from "./PaymentsContainer"
import styles from "../css/payments/renew-subscription-screen.module.css";

export const RenewSubscriptionScreen = () => {
    return (
        <div className={styles.formContainer}>
            <div className={styles.paymentsContainer}>
                <PaymentsContainer />
            </div>
        </div>
    )
}
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import "../css/auth/signin-signup.css";
import { GoogleAuthButton } from "./GoogleAuthButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LineBreak } from "./Linebreak";
import { FloatingLabelInput } from "./FloatingLabelInput";

export const SignIn = ({
    email,
    setEmail,
    password,
    setPassword,
}) => {
    const auth = getAuth();

    const [signInWithEmailAndPassword, user, loading, error] =
        useSignInWithEmailAndPassword(auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(email, password);
    };

    return (
        <div className="auth-container">
            <h1 className="title">Sign In</h1>
            <GoogleAuthButton />
            <LineBreak />
            <form
                onSubmit={(e) => {
                    handleSubmit(e);
                }}
                className="firebase-auth-form-container"
            >
                <div className="email-password-container">
                    <FloatingLabelInput
                        id="signin-email"
                        type="email"
                        value={email}
                        required={true}
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                    />
                    <FloatingLabelInput
                        id="signin-password"
                        type="password"
                        value={password}
                        required={true}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                </div>
                {error && <p className="auth-error">Error: Invalid Credentials</p>}
                <Link className="forgot-password" to="/forgot-password">
                    Forgot password?
                </Link>
                <input
                    type="submit"
                    value="Sign In"
                    onSubmit={(e) => handleSubmit(e)}
                    className="signin-signup-button"
                />
                <div className="switch-button-container">
                    <span>New to Normili? </span>
                    <Link className="switch-button" to="/signup" >Sign Up!</Link>
                </div>
            </form>
        </div>
    );
};

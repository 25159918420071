import React, { Component } from 'react';
import { getAllFlowsteps } from '../adapters/flowsteps/flowsteps-adapter';
import { insertFlowentry } from '../adapters/flowentry/flowentry-adapter';
import MultiStepForm from './MultiStepForm';
import { CheckinType } from '../Constants';
import { doesFlowCaptureWorkDetails } from '../helpers/EntriesHelper';

class EbbAndFlow extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            flowsteps: null
        };
    }

    componentDidMount() {
        // TODO: In the future, this should make a different call to retrieve flowsteps specific for ebb and flow check in.
        getAllFlowsteps().then((result) => {
            this.setState({
                flowsteps : result
            });
        })
    }

    render() {
        return (
            this.state.flowsteps ?
            <MultiStepForm flowsteps={this.state.flowsteps}
                submitAnswers={this.handleEntrySubmission}
                isFlowSaved={this.props.isFlowCompleted}/>
            : <>...</>
        )
    }

    handleEntrySubmission = (entry) => {        
        // append user reference to the entry
        entry.userId = this.props.user.uid;
        entry.type = CheckinType.EbbFlow;
        const isFlowAboutWork = doesFlowCaptureWorkDetails(entry);

        //Make an API call to submit the entryflow object
        insertFlowentry(entry).then(async response => {
            if (response.ok) {
                const message = await response.text();
                // exit out of entry flow
                this.props.finishEbbFlow(isFlowAboutWork);
            } 
            else {
                // TODO: Show error message on UI
                const message = await response.text();
                console.error(message);
            }
        });
    }
}

export default EbbAndFlow;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { AccountDropdownContextProvider } from "./components/AccountCircle";
import { CurrentMetadataForUserProvider } from "./context/CurrentMetadataForUser";

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
    <BrowserRouter>
        <AccountDropdownContextProvider>
            <CurrentMetadataForUserProvider>
                <App />
            </CurrentMetadataForUserProvider>
        </AccountDropdownContextProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

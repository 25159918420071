const server = process.env.REACT_APP_SERVER;

export async function getAllFlowsteps() {
    try {
        var response = await fetch(`${server}/flowsteps`);
        const flowsteps = await response.json();
        return flowsteps;
    }
    catch (e) {
        console.error(e, 'getAllFlowsteps');
    }
}

export async function getFlowstepById(id) {
    const response = await fetch(`${server}/flowstep/${id}`);
    const flowstep = await response.json();
    return flowstep;
}
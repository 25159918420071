import React, { Component } from 'react'
import MultiStepForm from './MultiStepForm'
import { getAllFlowsteps } from '../adapters/flowsteps/flowsteps-adapter';
import { insertFlowentry } from '../adapters/flowentry/flowentry-adapter';
import { CheckinType } from '../Constants';
import { doesFlowCaptureWorkDetails } from '../helpers/EntriesHelper';

class EntryFlow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flowsteps: null
        };
    }

    componentDidMount() {
        getAllFlowsteps().then((result) => {
            this.setState({
                flowsteps : result
            });
        })
    }

    render() {
        return (
            this.state.flowsteps ?
            <MultiStepForm flowsteps={this.state.flowsteps}  
                submitAnswers={this.handleEntrySubmission}
                isFlowSaved={this.props.isFlowCompleted}/>
            : <></>
        )
    }

    handleEntrySubmission = (entry) => {
        console.log("submit entry flow")

        // append user reference to the entry
        entry.userId = this.props.user.uid;
        entry.type = CheckinType.EntryFlow;
        const isFlowAboutWork = doesFlowCaptureWorkDetails(entry);

        //Make an API call to submit the entryflow object
        insertFlowentry(entry).then(async response => {
            if (response.ok) {
                const message = await response.text();
                // exit out of entry flow
                this.props.finishEntryFlow(isFlowAboutWork);
            } 
            else {
                // TODO: Show error message on UI
                const message = await response.text();
                console.error(message);
            }
        });
    }
}

export default EntryFlow;

import "../css/auth/linebreak.css";

export const LineBreak = () => {
    return (
        <div className="linebreak-container">
            <hr className="linebreak"></hr>
            <p className="text">or</p>
        </div>
    );
};

import React from "react";
import defaultStyles from "./css/mailtoLink.module.css";

export const MailtoLink = ({
    to = "hello@normili.com",
    text,
    userEmail = "",
    emailSubject = "",
    emailBody = "",
    styles = undefined,
}) => {
    const gmail = `https://mail.google.com/mail/?view=cm&fs=1&su=${emailSubject}&body=${emailBody}&to=${to}`

    const mailto = `mailto:${to}?subject=${emailSubject}&body=${emailBody}`;

    const getEmailLink = (userEmail) => {
        if (userEmail.includes("gmail.com")) {
            return gmail;
        }
        else {
            return mailto;
        }
    };

    return (
        <a
            href={getEmailLink(userEmail)}
            target="_blank"
            rel='noreferrer'
            /**
             * Using target="_blank" attribute unaccompanied by rel='noreferrer' is considered a severe
             * security vulnerability. Including noopener indicates that no referrer information is to
             * be leaked when following the link.
             */
            className={styles ? styles : defaultStyles.mailtoLink}
        >
            {text}
        </a>
    );
};

import React, { Component } from 'react'
import './css/checkbox.css'
import schedule from '../images/schedule.svg'
import people from '../images/people.svg'
import process from '../images/process.svg'
import work from '../images/work.svg'

class CheckboxDetailed extends Component {
    constructor(props) {
        super(props)

        this.checkedStyle = {
            "backgroundColor": "rgb(73, 197, 210)",
        }
        let initialStyle = props.checked ? this.checkedStyle : {}
        this.state = {
            "checked" : props.checked,
            "style" : initialStyle
        }
        this.icons = {
            "people": people,
            "schedule": schedule,
            "work": work,
            "process": process
        }
    }

    render() {
        return (
            <label>
                <div className='checkbox-tile detailed-checkbox-size' style={this.state.style}>
                    <input type='checkbox'
                        id='checkbox_detailed'
                        name={this.props.questionId}
                        value={this.props.value}
                        onChange={(e) => {this.props.onAnswered(e); this.handleChange(e)}}
                        checked={this.state.checked}
                    />
                    <div>
                        <div>
                            <img src={this.icons[this.props.icon]} className="icon-size" aria-label="A calendar and clock"/>
                            {this.state.checked && <span className='checkmark-detailed'><b> &#10003;</b></span>}
                        </div>
                        <div>{this.props.value}</div>
                        <div className="secondary-text">{this.props.secondaryValue}</div>
                    </div>
                </div>
            </label>
        )
    }

    handleChange = (e) => {        
        let style = e.target.checked ? this.checkedStyle : {}       
        this.setState({
            "checked":e.target.checked,
            "style":style
        })
    }
}

export default CheckboxDetailed

import { CSSTransition } from "react-transition-group";
import styles from "../css/payments/renew-subscription-flow.module.css"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getFlowScreenBGColor } from "../../Colors";

const headingTypeToTextMap = {
    flow: {
        headingPrimary: "Flows alchemize your feelings into information for your present and future self ✨",
        headingSecondary: "Keep flowing by becoming a paid subscriber."
    },
    bigPicture: {
        headingPrimary: "Reflection turns knowledge into wisdom 💎",
        headingSecondary: "See your Work Big Picture by becoming a paid subscriber."
    }
}

export const RenewSubscriptionFlow = ({ timeOfDay, headingType = "flow" }) => {
    return (
        <CSSTransition
            in={true}
            timeout={250}
            classNames="checkin"
            appear={true}
        >
            <div className={`checkin ${styles.flowContainer}`} style={{ background: getFlowScreenBGColor(timeOfDay) }}>
                <h1 className={styles.headingPrimary}>
                    {headingTypeToTextMap[headingType]?.headingPrimary}
                </h1>
                <h2 className={styles.headingSecondary}>
                    {headingTypeToTextMap[headingType]?.headingSecondary}
                </h2>
                <div className={`answer-input-div ${styles.answerContainer}`}>
                    <Link to="/renew-subscription" className={`radio-tile default-radio-size ${styles.answerSpacing} ${styles.subscribeButton}`}>
                        Subscribe Now!
                    </Link>
                    <Link to="/flows" className={`radio-tile default-radio-size ${styles.answerSpacing}`}>
                        View past flows
                    </Link>
                </div>
            </div>
        </CSSTransition>
    )
}
import React, { useContext, useEffect, useState } from "react";
import { CurrentMetadataForUser } from "../context/CurrentMetadataForUser";
import {
    getProfile,
    createOrUpdateProfile,
} from "../adapters/profile/profile-adapter";
import { getReminderSchedule, updateReminderSchedule, createReminderSchedule } from "../adapters/reminders-adapter";
import { AppBGColors, AppColorTheme, GreetingColors } from "../Colors";
import { TimezoneMap } from "../Constants";
import { getScheduleExpForNextBizDay } from "../helpers/RemindersHelper";
import { PhoneNumberInput, isPhoneNumberValid } from "./PhoneNumberInput";
import TimezoneRadioButtons from "./TimeZoneInput";
import Toggle from "./Toggle";
import "./css/profile.css";

function Profile(props) {
    const backgroundColor = AppBGColors[props.timeOfDay];
    const profileColor = GreetingColors[props.timeOfDay].background;
    const buttonTextColor = AppColorTheme[props.timeOfDay].DARK;

    const { isUserPaidSubscriber, setCurrentMetadataForUser } = useContext(CurrentMetadataForUser);
    const [profile, setProfile] = useState(null);
    const [postSaveMessage, setPostSaveMessage] = useState(null);

    const [timezone, setTimezone] = useState(TimezoneMap[Intl.DateTimeFormat().resolvedOptions().timeZone]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberSaveError, setPhoneNumberSaveError] = useState('');

    const [doesScheduleExist, setScheduleExists] = useState(false);
    const [areRemindersOn, setReminderToggleState] = useState(false);

    useEffect(() => {
        const userId = props.user.uid;
        getProfile(userId).then((result) => {
            if (result) {
                //remove object id
                delete result["_id"];
                if (result.timezone) {
                    setTimezone(result.timezone);
                }
                setProfile(result);
                setPhoneNumber(result.phone || "");
            }
        });
    }, []);

    useEffect(() => {
        if (!phoneNumber) {
            setReminderToggleState(false);
        }
        if (isPhoneNumberValid(phoneNumber)) {
            setPhoneNumberSaveError('');
        }
    }, [phoneNumber])

    useEffect(() => {
        if (!isUserPaidSubscriber) {
            setReminderToggleState(false);
            return;
        }
        // If user is a paid subscriber check if a schedule exists for them
        const userId = props.user.uid;
        getReminderSchedule(userId).then(result => {
            if (result.error) {
                setReminderToggleState(false);
                if (!result.error.includes('does not exist')) {
                    // This means the schedule exists but could not be fetched for some reason
                    setScheduleExists(true);
                }
            }
            else {
                setScheduleExists(true);
                setReminderToggleState(result.State === 'ENABLED' ? true : false);
            }
        })

    }, [isUserPaidSubscriber])

    const updateName = (e) => {
        setProfile((prevProfile) => ({ ...prevProfile, name: e.target.value }));
    };

    const updateCompany = (e) => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            companyName: e.target.value,
        }));
    };

    const updateRole = (e) => {
        setProfile((prevProfile) => ({ ...prevProfile, role: e.target.value }));
    };

    const onSaveClick = () => {
        saveProfile();
        updateRemindersForUser();
    }

    const saveProfile = () => {
        if (isPhoneNumberValid(phoneNumber)) {
            profile['timezone'] = timezone;
            profile['phone'] = phoneNumber;
            setPhoneNumberSaveError(false)

            //Make API call to save profile
            createOrUpdateProfile(profile).then(async response => {
                if (response.ok) {
                    const message = await response.text();
                    setPostSaveMessage(true);
                } else {
                    const message = await response.text();
                    console.error(message);
                    setPostSaveMessage(false);
                }
            });
        }
        else {
            setPhoneNumberSaveError("Invalid phone number")
        }
    };

    const updateRemindersForUser = () => {
        if (areRemindersOn && (!phoneNumber || !isPhoneNumberValid(phoneNumber))) {
            setPhoneNumberSaveError("Please provide a valid cell to receive reminders!");
            setCurrentMetadataForUser({ remindersOn: false });
            return;
        }

        const userId = props.user.uid;
        const scheduleExpressionTimezone = TimezoneMap[timezone];
        const scheduleState = areRemindersOn ? 'ENABLED' : 'DISABLED';
        const scheduleMetadata = {
            "phone": `+1${phoneNumber}` // The scheduler needs country code to send messages.
        }
        if (doesScheduleExist) {
            updateReminderSchedule(userId, null, scheduleExpressionTimezone, scheduleState, scheduleMetadata)
                .then(response => {
                    if (response.ok) {
                        setCurrentMetadataForUser({ remindersOn: areRemindersOn })
                    }
                });
            return;
        }
        // Else create the schedule only if reminders are on
        if (areRemindersOn) {
            const scheduleExpression = getScheduleExpForNextBizDay(new Date());
            const schedule = {
                "Name": `rule${userId}`,
                "Description": `For ${profile.name}`,
                "ScheduleExpression": scheduleExpression,
                "ScheduleExpressionTimezone": scheduleExpressionTimezone,
                "State": 'ENABLED',
                "Input": {
                    "userId": userId,
                    "phone": `+1${phoneNumber}`,
                    "message": `Hi ${profile.name}, Feel to find balance 🌻`,
                    "reminderIndex": -1
                },
                "FlexibleTimeWindow": {
                    "Mode": "OFF"
                }
            }
            createReminderSchedule(schedule).then(async response => {
                if (response.ok) {
                    setScheduleExists(true);
                    setCurrentMetadataForUser({ remindersOn: true });
                }
            });
        }
    }

    return (
        <div
            className="main-content profile-container"
            style={{ background: backgroundColor }}
        >
            <div
                className="onboarding-window profile-div"
                style={{ background: profileColor }}
            >
                <h3 className="profile-heading">My Profile</h3>
                {profile && (
                    <>
                        <div className="profile-field">
                            <label className="profile-field-label">Name</label>
                            <input
                                type="text"
                                value={profile.name}
                                className="profile-text-input"
                                onChange={updateName}
                            />
                        </div>
                        <div className="profile-field">
                            <label className="profile-field-label">Current company</label>
                            <input
                                type="text"
                                value={profile.companyName}
                                className="profile-text-input"
                                onChange={updateCompany}
                            />
                        </div>
                        <div className="profile-field">
                            <label className="profile-field-label">Current role</label>
                            <input
                                type="text"
                                value={profile.role}
                                className="profile-text-input"
                                onChange={updateRole}
                            />
                        </div>
                        <div className="profile-field">
                            <label className="profile-field-label">Phone Number</label>
                            <PhoneNumberInput
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                            />
                            {phoneNumberSaveError &&
                                <p className="profile-field-label" style={{ color: "red" }}>
                                    {phoneNumberSaveError}
                                </p>
                            }
                        </div>
                        <div className="profile-field">
                            <label className="profile-field-label">{areRemindersOn ? "Reminders On" : "Get VIP Reminders"}</label>
                            <div style={{ marginLeft: "0.5rem" }}>
                                <Toggle name={"Reminders"} checked={areRemindersOn}
                                    onChange={setReminderToggleState}
                                    allowToggling={isUserPaidSubscriber && phoneNumber} />
                            </div>
                        </div>
                        <div className="profile-field">
                            <label className="profile-field-label">My timezone</label>
                            <TimezoneRadioButtons timezone={timezone} setTimezone={setTimezone} />
                        </div>
                        <button
                            className="onboarding-btn profile-save-btn"
                            onClick={onSaveClick}
                            style={{ color: buttonTextColor }}
                        >
                            Save
                        </button>
                        {postSaveMessage && (
                            <div>
                                {postSaveMessage
                                    ? "Your updates have been saved!"
                                    : "Your updates were not save."}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Profile;

import { useContext, useEffect, useState } from "react";
import { PaymentError } from "./PaymentError";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import Loading from "../Loading";
import { CurrentMetadataForUser } from "../../context/CurrentMetadataForUser";
import { getSessionStatus } from "../../adapters/payments-adapter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const errorMessageMap = {
    retryCheckout: {
        errorInfoMessage: "This checkout session expired.",
        errorActionMessage: "Let's give it another go.",
        buttonText: "Go to Checkout",
        buttonTriggersReload: false
    },
    reload: {
        errorInfoMessage: "There was an error getting session status.",
        errorActionMessage: "Let's try a reload.",
        buttonText: "Reload",
        buttonTriggersReload: true
    }
};

export const PaymentResult = ({ sessionId, setShowPaymentResult }) => {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const [error, setError] = useState(false)
    const [errorDetails, setErrorDetails] = useState({})
    const { setCurrentMetadataForUser } = useContext(CurrentMetadataForUser);
    const history = useHistory()

    useEffect(async () => {
        if (user && sessionId) {
            const sessionStatus = await getSessionStatus(sessionId);
            if (sessionStatus.errorUserAction) {
                const errorMessage = errorMessageMap[sessionStatus.errorUserAction]
                setErrorDetails({
                    errorInfoMessage: errorMessage.errorInfoMessage,
                    errorActionMessage: errorMessage.errorActionMessage,
                    buttonText: errorMessage.buttonText,
                    buttonTriggersReload: errorMessage.buttonTriggersReload
                })
                setError(true)
            }
            else {
                setCurrentMetadataForUser({
                    isUserPaidSubscriber: sessionStatus?.isUserPaidSubscriber,
                    stripeCustomerId: sessionStatus?.stripeCustomerId
                });
                history.replace('/') // clear query params from url
            }
        }
    }, [user]);

    return (
        error ?
            <PaymentError
                errorInfoMessage={errorDetails.errorInfoMessage}
                errorActionMessage={errorDetails.errorActionMessage}
                buttonText={errorDetails.buttonText}
                buttonTriggersReload={errorDetails.buttonTriggersReload}
                setShowPaymentResult={setShowPaymentResult}
            />
            :
            <>
                <Loading />
                <p style={{ margin: "1rem", textAlign: "center" }}>Your payment is processing...</p>
            </>
    );
};
import React from 'react';
import { useHistory } from 'react-router-dom';
import '../css/bigpicturebutton.css';

function BigPictureButton(props) {
    const history = useHistory();

    const onClick = () => {
        const fullUrl = `big-picture?${props.queryParams}`
        history.push(fullUrl, {flows: props.flows});
    }

    return (
        <button className='big-pic-btn'
            onClick={onClick}
            style={props.style ?? {color: 'black'}}>
                See Work Big Picture 🚀
        </button>
    )
}

export default BigPictureButton;